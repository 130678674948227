<template>
  <div class="h5-add-form-wrap">
    <sub-head-navs title="添加语言" :eventBack="true" @goBack="goBack"></sub-head-navs>
    <van-form class="add-form" ref="resumeFormRef" validate-trigger="onSubmit" scroll-to-error @submit="onConfirm">
      <template v-for="(item, key) in fieldsList">
        <template v-if="isFiledCode('language', item)">
          <van-field
            :label="getLabel(item)"
            :required="!!item.isRequired"
            :rules="getRules(item)"
            :value="Language[resumeForm.language]"
            readonly clickable placeholder="请选择" right-icon="arrow" @click="onPickeVal('language')" />
          <van-field 
            v-if="resumeForm.language === 3"
            label="说明"
            clickable 
            name="otherLanguage"
            type="textarea"
            rows="4"
            v-model.trim="resumeForm.otherLanguage"
            placeholder="请填写语言和熟练程度，以及证书等级"
            :maxLength="100"
          />
        </template>
        <van-field 
          v-else-if="isFiledCode('proficiency', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          :value="Proficiency[resumeForm.languageProficiency]" readonly clickabl placeholder="请选择" right-icon="arrow" @click="onPickeVal('languageProficiency')" />
          <!-- 其他 -->
        <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="getItemData(resumeForm, item.fieldId)" @update="updateField" />
        <!-- <van-field :value="LanguageGrade[resumeForm.languageLevel]" readonly clickabl label="语言等级" placeholder="请选择"  right-icon="arrow" @click="onPickeVal('languageLevel')" /> -->
      </template>
      <van-tabbar>
        <van-button class="btn" native-type="submit">确认添加</van-button>
      </van-tabbar>
    </van-form>
    <popup-picker v-if="currentPickerObj.isShow" :dialogData="currentPickerObj" v-model="resumeForm[currentField]" @success="onSuccessSelect" />
  </div>
</template>

<script>
  import '../workExp/index.scss'
  import mixin from '@/views/submodule/h5/resumeAdd/mixin'
  import { Field, Form, Tabbar, Button } from 'vant'
  import { Proficiency, Language, LanguageGrade } from '@/enums/EnumResume'
  import subHeadNavs from '@/components/h5/headNavs'
  import popupPicker from '@/components/h5/dialog/picker'

  export default {
    components: {
      [Field.name]: Field,
      [Form.name]: Form,
      [Button.name]: Button,
      [Tabbar.name]: Tabbar,
      subHeadNavs,
      popupPicker
    },
    mixins: [mixin],
    data() {
      return {
        Language,
        Proficiency,
        LanguageGrade,
        languageName: {},
        languageGrade: {},
        languages: this.$utils.deepClone(this.$local.getObject('languages')),
        resumeForm: {
          language: '',
          languageProficiency: '',
          languageLevel: '',
          otherLanguage: ''
        },
        currentPickerObj: { isShow: false },
        languageObj: { isShow: false },
        languageProficiencyObj: { isShow: false },
        languageLevelObj: { isShow: false },
        currentField: '',
      }
    },
    created() {
      if(this.$route.query.resumeForm) {
        const item = JSON.parse(this.$route.query.resumeForm);
        Object.assign(this.resumeForm, {
          ...item,
          customFields: item.customFields ? item.customFields : item.fieldMap || {}
        });
      }
      if(this.$route.query.moduleId) {
        this.queryConfigField();
      }
      let columns = [];
      Object.keys(this.Proficiency).forEach(item => columns.push({ name: this.Proficiency[item], value: +item }));
      Object.assign(this.languageProficiencyObj, { columns: columns, key: 'name' });
      this.initColumn();
    },
    methods: {
      // 初始化cloumn
      initColumn(grage) {
        let columns1 = [],
        columns2 = [];
        this.languages.forEach((item, index) => {
          if(!grage) {
            columns1.push({ name: item.name, value: item.code });
          }
          if(item.children && item.children.length) {
            if(item.code === +this.resumeForm.language) {
              item.children.forEach(ele => {
                columns2.push({ name: ele.name, value: ele.code })
              })
            } else if(!this.resumeForm.language && !index){
              item.children.forEach(ele => {
                columns2.push({ name: ele.name, value: ele.code })
              })
            }
          }
        });
        if(!grage) {
          Object.assign(this.languageObj, { columns: columns1, key: 'name' });
        }
        Object.assign(this.languageLevelObj, { columns: columns2, key: 'name' });
      },
      onPickeVal(field) {
        this.currentField = field;
        Object.assign(this.currentPickerObj, {...this[`${field}Obj`], isShow: true });
      },
      onSuccessSelect(data) {
        this.resumeForm[this.currentField] = data.value || 0;
      },
      goBack() {
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'languages',
            languages: null
          }
        })
      },
      onConfirm() {
        if(this.resumeForm.language === 3) {
          this.resumeForm.languageProficiency = '';
          this.resumeForm.languageLevel = '';
        }
        this.resumeForm = Object.keys(this.$utils.deleteKeys(this.resumeForm)).length ? JSON.stringify(this.resumeForm) : null;
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'languages',
            languages: this.resumeForm
          }
        })
      }
    },
    watch: {
      'resumeForm.language'(val, old) {
        if(val && old && val !== old) {
          this.resumeForm.languageLevel =  undefined;
          this.initColumn(true);
          return val;
        }
      }
    }
  }
</script>