<template>
  <div class="container main-page">
    <sub-head-navs v-if="websiteId>0" />
    <div class="resume-add-wrap">
      <div class="breadcrumb-box">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
          <template v-if="websiteId>0">
            <el-breadcrumb-item :to="{ name: 'Home', query: { id: uccnInfo.id }, params: {pathMatch}}">招聘首页</el-breadcrumb-item>
            <el-breadcrumb-item v-if="pathName !== 'myResume'" :to="{ path: `/job/list?websiteId=${websiteId}` }">{{ isRecommendWebsite ? '内推职位' : '职位列表' }}</el-breadcrumb-item>
          </template>
          <el-breadcrumb-item v-if="pathName !== 'myResume'" :to="jobId ? { path: `/job/detail/${jobId}?websiteId=${websiteId}&accountId=${accountId}` } : null">职位详情</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="apply-content">
        <div v-if="jobName || modifyTime" class="apply-job">
          <div class="apply-job-inner">
            <h3>{{ jobName }}</h3>
            <p v-if="modifyTime" class="create-time">发布时间：{{ modifyTime | DATE('YYYY-MM-DD') }}</p>
          </div>
        </div>
        <sub-recom-info v-if="pathName === 'ResumeAdd' && (uccnInfo.schoolAmbassadorFlag || isRecommendWebsite)" 
          :websiteId="websiteId"
          :personId="personId"  
          :schoolAmbassadorCode="schoolAmbassadorCode"
          :schoolAmbassadorName="schoolAmbassadorName"
          @onRecomInfo="onRecomInfo" />
        <sub-upload v-if="uccnInfo.resumeFileFlag" ref="refUpload" :subResData="resForm.resumeFileId"></sub-upload>
        <template v-for="(item, key) in configList">
          <sub-base v-if="isShowModule(item, 1)" :key="key" :moduleId="item.moduleId" :filedsConfig="item.fields" ref="resBase" :subResData="resForm.resumeInfo" :isDisabledPhone="isDisabledPhone" :isDisabledEmail="isDisabledEmail"></sub-base>
          <sub-intention v-if="isShowModule(item, 2)" :key="key" :moduleId="item.moduleId" :filedsConfig="item.fields" ref="resIntention" :subResData="resForm.jobIntention"></sub-intention>
          <sub-work v-if="isShowModule(item, 3)" :key="key" :moduleId="item.moduleId" :filedsConfig="item.fields" ref="refWork" :subResData="resForm.workExperience"></sub-work>
          <sub-education v-if="isShowModule(item, 4)" :key="key" :moduleId="item.moduleId" :filedsConfig="item.fields" ref="refEducation" :subResData="resForm.eduExperience"></sub-education>
          <sub-project v-if="isShowModule(item, 5)" :key="key" :moduleId="item.moduleId" :filedsConfig="item.fields" ref="refProject" :subResData="resForm.projectExperience"></sub-project>
          <sub-language v-if="isShowModule(item, 6)" :key="key" :moduleId="item.moduleId" :filedsConfig="item.fields" ref="refLanguage" :subResData="resForm.languages"></sub-language>
          <sub-attachment v-if="isShowModule(item, 7)" :key="key" :moduleId="item.moduleId" :filedsConfig="item.fields" ref="refAttachment" :subResData="resForm.products"></sub-attachment>
          <sub-train v-if="isShowModule(item, 8)" :key="key" :moduleId="item.moduleId" :filedsConfig="item.fields" ref="refTrain" :subResData="resForm.trainingExps"></sub-train>
          <sub-self v-if="isShowModule(item, 9)" :key="key" :moduleId="item.moduleId" :filedsConfig="item.fields" ref="refSelf" :subResData="resForm.selfIntroduce"></sub-self>
          <sub-custom-moudle v-if="isShowCustomModule(item)" :key="key" :ref="`customMoudle-${item.moduleId}`" :moudleData="item" :subResData="resForm.customModules ? resForm.customModules[item.moduleId] : []"></sub-custom-moudle>
        </template>
        <div class="form-item-btn">
          <el-button class="btn-href" @click="onPreview">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  // PC引入使用的组件
  import { Breadcrumb, BreadcrumbItem, Form, FormItem, Input, Upload, Progress, Button, Select, Option, DatePicker, Loading, Switch, Message, MessageBox } from 'element-ui'
  import { local } from '@/utils'
  import subHeadNavs from '@/components/pc/headNavs'
  import subRecomInfo from './submodule/recomInfo'
  import subUpload from './submodule/uploadResume'
  import subBase from './submodule/base'
  import subIntention from './submodule/intention'
  import subWork from './submodule/work'
  import subEducation from './submodule/education'
  import subProject from './submodule/project'
  import subLanguage from './submodule/language'
  import subAttachment from './submodule/attachment'
  import subTrain from './submodule/train'
  import subSelf from './submodule/self'
  import subCustomMoudle from './submodule/customMoudle'

  export default {
    components: {
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Upload.name]: Upload,
      [Progress.name]: Progress,
      [Button.name]: Button,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Switch.name]: Switch,
      [Message.name]: Message,
      [MessageBox.name]: MessageBox,
      subHeadNavs,
      subRecomInfo,
      subUpload,
      subBase,
      subIntention,
      subWork,
      subEducation,
      subProject,
      subLanguage,
      subAttachment,
      subTrain,
      subSelf,
      subCustomMoudle
    },
    data() {
      return {
        canPreview: false, //是否预览，默认添加
        websiteId: '',
        jobId: '',
        resLibraryId: '',
        jobCompanyId: '',
        accountId: '',
        jobName: '',
        modifyTime: '',
        optRecordId: '',
        schoolAmbassadorCode: '',
        schoolAmbassadorName: '无',
        schoolInterviewSiteId: '',
        resForm: {
          resumeInfo: {},
          jobIntention: {},
          selfIntroduce: {},
          resumeFileId: [], //简历附件
          workExperience: [],
          projectExperience: [],
          eduExperience: [],
          appraiseJson: {},
          languages: [],
          products: [],
          trainingExps: [],
          customModules: {}
        },
        externalRecomFlag: false, // 允许外部人员推荐
        parseLoading: false,
        resVersionId: '',
        resumeId: '',
        configList: [
          {
            fields: [
              {
                fieldDto: {
                  fieldCode: "name",
                  id: 1,
                  name: "姓名",
                  type: 2,
                },
                fieldId: 1,
                isCheck: 1,
                isRequired: 1
              },
              {
                fieldDto: {
                  fieldCode: "phone",
                  id: 6,
                  name: "手机号码",
                  type: 2
                },
                fieldId: 6,
                isCheck: 1,
                isRequired: 1,
                sort: 5,
              },
              {
                fieldDto: {
                  fieldCode: "email",
                  id: 6,
                  name: "邮箱",
                  type: 2
                },
                isCheck: 0,
                isRequired: 0,
                sort: 7,
              }
            ],
            isOpen: 1,
            moduleDto: {
              moduleCode: 1
            },
            moduleId: 1
          }
        ], // 邮件落地页过来的默认显示名字和手机号不走配置
        authStatus: 0
      }
    },
    computed: {
      ...mapGetters(['degrees', 'scales', 'uccnInfo']),
      user() {
        return JSON.parse(local.get('user')|| '{}');
      },
      pathName() {
        return this.$route.name;
      },
      mobile() {
        return (JSON.parse(local.get('user') || '{}') || {}).mobile || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}') || {}).email || '';
      },
      isRecommendWebsite() {
        return (this.$route.query.websiteId > 0 && (JSON.parse(local.get('uccnInfo') || '{}')).type) || '';
      },
      pathMatch() {
        return local.get('rootPath') || '';
      },
      isShowModule() {
        return (module, moduleCode) => {
          return module.isOpen && module.moduleDto.moduleCode === moduleCode;
        }
      },
      isShowCustomModule() {
        return (module) => {
          return module.isOpen && ![1,2,3,4,5,6,7,8,9].includes(module.moduleDto.moduleCode);
        }
      },
      isDisabledPhone() {
        // 内推官网已认证角色（企业员工、外部用户） & 校招官网-校园大使端，才可以修改手机号
        if(this.$route.query.websiteId == -1) {
          return false;
        } else {
          // 0: 企业员工, 1: 外部人员, 2: 校园大使 3: 候选人角色
          switch(this.user.externalFlag) {
            case 0:
            case 1:
              return !this.mobile;
            case 2:
              return !this.mobile;
            case 3:
              if(this.mobile && this.email) {
                return true;
              }
              if(this.mobile && !this.email) {
                return true;
              }
              if(!this.mobile && this.email) {
                return false;
              }
            default: //没有flag，按候选人算
              if(this.mobile && this.email) {
                return true;
              }
              if(this.mobile && !this.email) {
                return true;
              }
              if(!this.mobile && this.email) {
                return false;
              }
          }
        }
      },
      isDisabledEmail() {
        // 内推官网已认证角色（企业员工、外部用户） & 校招官网-校园大使端，才可以修改手机号
        if(this.$route.query.websiteId == -1) {
          return false;
        } else {
          // 0: 企业员工, 1: 外部人员, 2: 校园大使 3: 候选人角色
          switch(this.user.externalFlag) {
            case 0:
            case 1:
              return false;
            case 2:
              return false;
            case 3:
              if(this.mobile && this.email) {
                return true;
              } else if(this.mobile && !this.email) {
                return false;
              } else if(!this.mobile && this.email) {
                return true;
              } else {
                return false;
              }
            default: //没有flag，按候选人算
              if(this.mobile && this.email) {
                return true;
              } else if(this.mobile && !this.email) {
                return false;
              } else if(!this.mobile && this.email) {
                return true;
              } else {
                return false;
              }
          }
        }
      }
    },
    created() {
      this.websiteId = this.$route.query.websiteId || '';
      this.jobId = this.$route.query.jobId || '';
      this.resLibraryId = this.$route.query.resLibraryId || '';
      this.jobCompanyId = this.$route.query.jobCompanyId || '';
      this.accountId = this.$route.query.accountId || '';
      this.jobName = this.$route.query.jobName || '';
      this.optRecordId = this.$route.query.optRecordId || '';
      this.modifyTime = this.$route.query.modifyTime || '';
      this.personId = this.$route.query.personId || (JSON.parse(local.get('user')|| '{}')).personId || local.get('oldPersonId') || '';
      // 校园大使信息
      this.schoolAmbassadorCode = this.$route.query.schoolAmbassadorCode || '';
      this.schoolAmbassadorName = this.$route.query.schoolAmbassadorName || '无';
      // 校园站点
      this.schoolInterviewSiteId = this.$route.query.schoolInterviewSiteId || '';

      if(+this.websiteId !== -1) {
        this.queryConfigField();
      }
      this.init();
    },
    methods: {
      init() {
        if(this.isRecommendWebsite) {
          if(this.pathName === 'myResume') {
            this.queryResumeDetail();
          } else {
            this.queryUserInfo();
            // 非认证内推人 => 候选人 反显上次投递信息
            if(!this.personId) this.queryResumeDetail();
          }
        } else {
          if(this.websiteId != -1) {
            this.queryResumeDetail();
          }
        }
        Object.assign(this.resForm.resumeInfo, {
          phone: this.mobile,
          email: this.email
        });
      },
      queryUserInfo() {
        this.$http.get('/api/internal/recom/person/info.json', {params: { personId: this.personId }}).then(data => {
          if(data) {
            this.authStatus = data.authStatus;
          }
        });
      },
      formatDate(arr) {
        if(arr && arr.length) {
          arr = arr.map(item => {
            item.yearMonths = [[item.startYear, item.startMonth].join('.'), item.endYear === 9999 ? '' : [item.endYear, item.endMonth].join('.')];
            return item;
          });
        }
        return arr;
      },
      queryResumeDetail() {
        this.$http.get('/api/internal/recom/person/res/detail.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            if(data.files && data.files.length) {
              data.files[0].originName =  data.files[0].fileName;
              data.resumeFileId = data.files;
            }
            if(data.resumeInfo && data.resumeInfo.birthYear) {
              data.birthYear = String(data.resumeInfo.birthYear);
            }
            this.formatDate(data.workExps);
            this.formatDate(data.projectExperience);
            this.formatDate(data.trainingExps);
            this.formatDate(data.eduExperience);
            this.resVersionId = data.resVersionId;
            this.resumeId = data.resumeInfo ? data.resumeInfo.id : '';
            Object.assign(this.resForm, {
              resumeInfo: Object.assign({}, data.resumeInfo, {
                area: data.resumeInfo ? data.resumeInfo.area : 1,
                phone: data.resumeInfo ? data.resumeInfo.phone : this.mobile,
                email: data.resumeInfo ? data.resumeInfo.email : this.email,
              }),
              jobIntention: Object.assign({}, data.jobIntention, {
                expectCity: data.jobIntention && data.jobIntention.expectCity ? data.jobIntention.expectCity.split(',') : [],
                salaryType: data.jobIntention ? data.jobIntention.salaryType : 1,
                expectPosition: data.jobIntention && data.jobIntention.expectPosition ? data.jobIntention.expectPosition.split(',') : [],
                expectIndustry: data.jobIntention && data.jobIntention.expectIndustry ? data.jobIntention.expectIndustry.split(',') : [],
              }),
              selfIntroduce: Object.assign({}, data.selfIntroduce),
              workExperience: this.formatItem(data.workExps) || [],
              projectExperience:this.formatItem(data.projectExperience) || [],
              trainingExps: this.formatItem(data.trainingExps) || [],
              eduExperience: this.formatItem(data.eduExperience) || [],
              resumeFileId: data.files || [],
              products: this.formatItem(data.products, 'products') || [],
              languages: this.formatItem(data.languages, 'languages') || [],
              customModules: data.customModules || {}
            });
          }
        });
      },
      queryConfigField() {
        this.$http.get('/api/website/delivery/config.json', {params: {
          websiteId: this.websiteId
        }}).then(data => {
          if(data) {
            this.configList = data || [];
          }
        });
      },
      onRecomInfo(personId, recomForm) {
        this.personId = personId;
        this.schoolAmbassadorCode = recomForm.schoolAmbassadorCode;
      },
       /**
       * 子模块表单校验封装
       */
      validateSubItem(formName) {
        return new Promise(resolve => {
          if(this.$refs[formName] && this.$refs[formName][0]) {
            if(this.$refs[formName][0].validateCustom) {
              const validForm = new Promise(resolve => {
                this.$refs[formName][0].$refs.resForm.validate(valid => {
                  resolve(valid);
                });
              });
              Promise.all([validForm, this.$refs[formName][0].validateCustom()]).then(res => {
                if(res.includes(false)) {
                  resolve(false);
                } else {
                  resolve();
                }
              });
            } else {
              this.$refs[formName][0].$refs.resForm.validate(valid => {
                resolve(valid);
              });
            }
          } else {
            resolve();
          }
        });
      },
      validateCustomForm(moduleName) {
        return new Promise(resolve => {
          if(this.$refs[moduleName] && this.$refs[moduleName][0]) {
            this.$refs[moduleName][0].validateCustom().then(valid => {
              resolve(valid);
            });
          } else {
            resolve();
          }
        });
      },
      // 校验自定义模块
      validateCustomeMoudle() {
        const validateArr = Object.keys(this.$refs).filter(key => key.includes('customMoudle')).map(item => this.validateCustomForm(item));
        if(validateArr.length) {
          return Promise.all(validateArr).then(res => {
            if(res.includes(false)) {
              return false;
            }
            return true;
          });
        } else {
          return Promise.resolve();
        }
      },
      onPreview() {
        Promise.all([
          this.validateSubItem('refUpload'),
          this.validateSubItem('resBase'),
          this.validateSubItem('resIntention'),
          this.validateSubItem('refWork'),
          this.validateSubItem('refEducation'),
          this.validateSubItem('refProject'),
          this.validateSubItem('refLanguage'),
          this.validateSubItem('refAttachment'),
          this.validateSubItem('refTrain'),
          this.validateSubItem('refSelf'),
          this.validateCustomeMoudle()
        ]).then(async result => {
          if(result.includes(false)) {
            // 滚动到第一个错误位置
            this.scrollFirstError();
            return;
          }
          let resumeParams = {
            resumeInfo: {}
          };
          // resumeParams.resumeId = this.resumeObj.resumeId;
          const dataMap = {
            resBase: 'resumeInfo',
            resIntention: 'jobIntention',
            refWork: 'workExperience',
            refEducation: 'educationExperience',
            refTrain: 'trainingExperience',
            refProject: 'projectExperience',
            refLanguage: 'languageAbility',
            refAttachment: 'products',
            refSelf: 'selfIntroduce'
          }
          Object.keys(dataMap).forEach(key => {
            if(this.$refs[key] && this.$refs[key][0] && this.$refs[key][0].resForm) {
              if(['resBase', 'resIntention', 'refSelf'].includes(key)) {
                const copyData = this.$utils.deepClone(this.$refs[key][0].resForm);
                const customResult = this.getCustomData(copyData, this.$refs[key][0].moduleId);
                resumeParams[dataMap[key]] = this.$utils.formatDataArr(this.$refs[key][0].resForm);
                delete resumeParams[dataMap[key]].customFields;
                if(customResult) {
                  resumeParams[dataMap[key]].customResult = customResult;
                }
                resumeParams[dataMap[key]] = JSON.stringify(resumeParams[dataMap[key]]);
              } else if(key === 'refAttachment') {
                if(this.$refs[key][0].resForm.expArr && this.$refs[key][0].resForm.expArr.length && this.$refs[key][0].moduleId) {
                  let standardExpArr = [];
                  this.$refs[key][0].resForm.expArr.forEach((obj => {
                    let item = Object.assign({}, obj);
                    const customResult = this.getCustomData(this.$utils.deepClone(obj), this.$refs[key][0].moduleId);
                    if(customResult) {
                      item.customResult = customResult;
                    }
                    delete item.customFields;
                    item = this.$utils.deleteKeys(item);
                    if(Object.keys(item).length) {
                      standardExpArr.push(item);
                    }
                  }));
                  resumeParams[dataMap[key]] = standardExpArr;
                  resumeParams[dataMap[key]] = JSON.stringify(resumeParams[dataMap[key]]);
                } else {
                  resumeParams[dataMap[key]] = null;
                }
              } else {
                resumeParams[dataMap[key]] = this.formatItemDate(this.$refs[key][0].resForm, this.$refs[key][0].moduleId);
                resumeParams[dataMap[key]] = JSON.stringify(resumeParams[dataMap[key]]);
              }
            }
          });
          resumeParams.customContent = JSON.stringify(this.customMoudle());
          resumeParams.resumeFileId = this.$refs['refUpload'] ? this.$refs['refUpload'].resForm.resumeFileId : [];
          this.onSave(resumeParams);
        });
      },
      formatItemDate(data, moduleId) {
        let expArr = data.expArr,
            standardExpArr = [];
        expArr.forEach(obj => {
          let item = Object.assign({}, obj);
          if(moduleId) {
            const customResult = this.getCustomData(this.$utils.deepClone(obj), moduleId);
            if(customResult) {
              item.customResult = customResult;
            }
          }
          delete item.dates;
          delete item.customFields;
          delete item.fileList;
          item = this.$utils.deleteKeys(item);
          if(Object.keys(item).length) {
            standardExpArr.push(item);
          }
        });
        return standardExpArr.length ? standardExpArr: null;
      },
      getCustomData(data, moduleId) {
        let fields = [];
        if(data.customFields && Object.keys(data.customFields).length) {
          Object.keys(data.customFields).forEach(key => {
            fields.push(data.customFields[key])
          });
        }
        delete data.customFields;
        if(fields.length) {
          return {
            moduleId,
            fields
          }
        } else {
          return null;
        }
      },
      customMoudle() {
        let customContent = [];
        Object.keys(this.$refs).filter(key => key.indexOf('customMoudle') !== -1).forEach(key => {
          const moduleId = +key.replace('customMoudle-', '');
          if(this.$refs[key][0].resForm.expArr.length) {
            this.$refs[key][0].resForm.expArr.forEach(item => {
              if(Object.keys(item).length) {
                let fields = [];
                Object.keys(item).forEach(field => {
                  fields.push(item[field]);
                });
                customContent.push({
                  moduleId,
                  fields
                });
              }
            });
          }
        });
        return customContent;
      },
      scrollFirstError() {
        this.$nextTick(() => {
          if(document.querySelector('.is-error')) {
            document.querySelector('.is-error').scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            // 附件信息显示错误的地方
          } else if(document.querySelector(`#topic-form-appraise`).querySelector('.has-error')) {
            document.querySelector(`#topic-form-appraise`).querySelector('.has-error').scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
        });
      },
      formatItem(data, type) {
        if(data) {
          let _res = data.map(obj => this.$utils.deleteKeys(obj, [0])).filter(obj => Object.keys(obj).length);
          return _res.length ? _res : null;
        }
        return null;
      },
      // 手机号投递职位订单状态
      queryJobRecommendStatus(phone) {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/mobile/check/info.json', {params: {
            jobId: this.jobId,
            mobile: phone
          }}).then(data => {
            if(data) {
              MessageBox.confirm('您已经在流程中，请勿重复投递', '', {
                showCancelButton: false,
                center: true
              }).then(action => {
                resolve(false);
              }).catch(() => {
                resolve(false);
              });
            } else {
              resolve(true);
            }
          });
        })
      },
      queryRecommendStatus(phone) {
        return new Promise((resolve, reject) => {
           this.$http.get('/api/website/apply/check.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId,
            personId: this.personId,
            mobile: phone,
            email: this.email || '',
            schoolAmbassadorCode: this.schoolAmbassadorCode
          }}).then(data => {
            if(data === 1){
              MessageBox.confirm('仅能有一个职位在流程中，请谨慎选择', '', {
                confirmButtonText: '确定投递',
              }).then(action => {
                resolve(true);
              }).catch(() => {
                resolve(false);
              });
            } else if(data === 2){
              MessageBox.confirm('已投递其他职位，暂不可投递', '', {
                showCancelButton: false,
                center: true
              }).then(action => {
                resolve(false);
              }).catch(() => {
                resolve(false);
              });
            } else {
              resolve(true);
            }
          });
        });
      },
      async onSave(data) {
        if(this.pathName === 'ResumeAdd' && this.jobId) {
          let jobRecommendResult = await this.queryJobRecommendStatus(JSON.parse(data.resumeInfo).phone);
          if(!jobRecommendResult) {
            return;
          }
          let result = await this.queryRecommendStatus(JSON.parse(data.resumeInfo).phone);
          if(!result) {
            return;
          }
        }
        const newFrom = this.$utils.deepClone(data);
        let saveParams = this.$utils.deleteKeys(Object.assign({}, newFrom, {
          optRecordId: this.optRecordId,
          websiteId: this.websiteId,
          companyId: this.uccnInfo ? this.uccnInfo.companyId : '',
          jobId: this.jobId,
          accountId: this.accountId,
          resumeFileId: (newFrom.resumeFileId && newFrom.resumeFileId.length) ? newFrom.resumeFileId[0].id : '',
          careerTalkId: localStorage.getItem('careerTalkId'),
          schoolAmbassadorCode: this.schoolAmbassadorCode,
          schoolInterviewSiteId: this.schoolInterviewSiteId
        }));
        if(this.websiteId < 0) {
          saveParams.companyId = this.jobCompanyId;
        }
        let apiUrl = '';
        if(this.pathName === 'myResume') {
          saveParams.resumeId = this.resumeId;
          if(this.resVersionId) {
            saveParams.resVersionId = this.resVersionId;
            apiUrl = '/api/website/my/resume/update.json';
          } else {
            apiUrl = '/api/website/apply.json';
          }
        } else {
          if(this.personId) {
            saveParams.personId = this.personId;
          }
          apiUrl = '/api/website/apply.json';
        }
        this.isLoading = true;
        this.$http.post(apiUrl, saveParams).then(data => {
          Message.success('保存成功');
           
          if(this.jobId) {
            // 投递成功页面
            this.$router.push({
              name: 'deliverySuccess',
              query: {
                websiteId: this.websiteId,
                jobId: this.jobId
              }
            });
          } else {
            this.$router.push({
              name: 'Home',
              query: { id: this.uccnInfo.id },
              params: { pathMatch: local.get('rootPath') || '' }
            });
          }
        }).finally(() => {
          this.isLoading = false;
          localStorage.removeItem('careerTalkId');
        });
      }
    },
    watch: {
      pathName: {
        handler(val) {
          if(val){
            this.init();
          }
        },
        deep: true
      }
    }
  }
</script>