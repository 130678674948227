<template>
  <div class="industry-wrap">
    <sub-list v-if="paramsObj.type === 'notPopup'" :paramsObj="paramsObj" @ok="onOk" @cancel="onCancel" />
    <van-popup v-else v-model="paramsObj.isShow" round :position="position">
      <sub-list :paramsObj="paramsObj" @ok="onOk" @cancel="onCancel" />
    </van-popup>
  </div>
</template>

<script>
  import './index.scss'
  import { Popup } from 'vant'
  import subList from './submodule/list'

  export default {
    components: {
      [Popup.name]: Popup,
      subList
    },
    props: {
      paramsObj: Object
    },
    computed: {
      position() {
        return this.paramsObj.position ? this.paramsObj.position : 'bottom';
      }
    },
    methods: {
      onOk(data) {
        this.$emit('ok', data);
        if(this.paramsObj.isShow) {
          this.paramsObj.isShow = false;
        }
      },
      onCancel() {
        if(this.paramsObj.isShow) {
          this.paramsObj.isShow = false;
        }
        this.$emit('cancel');
      }
    }
  }
</script>