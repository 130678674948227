<template>
  <div class="h5-guide-add-form-wrap">
    <sub-head-navs :title="`添加${moduleData.moduleDto.name}`" :eventBack="true" @goBack="goBack"></sub-head-navs>
    <van-form class="add-form" ref="resumeFormRef" validate-trigger="onSubmit" scroll-to-error @submit="onConfirm">
      <template v-for="(item, key) in fieldsList">
        <sub-custom-field v-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="getItemData(resumeForm, item.fieldId)" @update="updateField" />
      </template>
      <van-tabbar>
        <van-button class="btn" native-type="submit">确认添加</van-button>
      </van-tabbar>
    </van-form>
  </div>
</template>

<script>
  import './index.scss'
  import mixin from '@/views/guide/customExp/mixin'
  import { Field, Form, Tabbar, Button} from 'vant'
  import subHeadNavs from '@/components/h5/headNavs'

  export default {
    components: {
      [Field.name]: Field,
      [Form.name]: Form,
      [Button.name]: Button,
      [Tabbar.name]: Tabbar,
      subHeadNavs,
    },
    mixins: [mixin],
    data() {
      return {
        resumeForm: {
          customFields: {}
        }
      }
    },
    created() {
      if(this.$route.query.resumeForm) {
        Object.assign(this.resumeForm, {
          customFields: JSON.parse(this.$route.query.resumeForm)
        });
      }
       if(this.$route.query.moduleId) {
        this.queryConfigField();
      }
    },
    methods: {
      goBack() {
        this.$router.push({
          name: 'guideRegister',
          params: {
            id: this.$route.query.pathName
          },
          // query: {
          //   ...this.$route.query,
          //   field: 'customExp',
          //   customExp: null
          // }
        })
      },
      onConfirm() {
        this.resumeForm = Object.keys(this.$utils.deleteKeys(this.resumeForm.customFields)).length ? JSON.stringify(this.resumeForm.customFields) : null;
        this.$router.push({
          name: 'guideRegister',
          params: {
            id: this.$route.query.pathName
          },
          query: {
            ...this.$route.query,
            field: 'customExp',
            customExp: this.resumeForm
          }
        })
      }
    }
  }
</script>