<template>
  <div>
    <h3>求职意向</h3>
    <template v-for="(item, key) in filedsConfig">
      <van-field
        v-if="isFiledCode('expectCity', item)"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item)"
        readonly 
        clickable 
        name="expectCity" 
        :value="resForm.expectCity | CITYARR('，')"
        placeholder="请选择"
        right-icon="arrow"
        @click="expectCityObj.isShow = true"
      />
      <template v-else-if="isFiledCode('currentAnnualSalary', item)">
        <van-field
          :required="!!item.isRequired"
          :rules="getRules(item, [{ validator: val => !val || /^(0(\.\d{1,2})+|([1-9]\d{0,3})(\.\d{1,2})?)$/.test(val), message: '请输入最多2位小数的4位正数'}])"
          v-model.trim="resForm.currentSalary" 
          label="目前年薪(万)"
          type="number" 
          :maxLength="7" 
          :label-width="110" 
          placeholder="请输入"
        />
        <van-field
          label="税前税后"
          readonly 
          clickable
          name="taxType"
          :value="resForm.taxType | TAXTYPE"
          right-icon="arrow"
          placeholder="请选择"
          @click="showTaxTypeObj.isShow = true">
        </van-field>
      </template>
      <van-field
        v-else-if="isFiledCode('salaryRemark', item)"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item)"
        :maxLength="50"
        name="salaryRemark" 
        v-model.trim="resForm.salaryRemark"
        placeholder="奖金、补贴、提成、股票、期权等"
      />
      <div v-else-if="isFiledCode('expectedAnnualSalary', item)" class="expect-salary-item">
        <van-field
          class="expect-salary"
          :required="!!item.isRequired"
          :rules="getRules(item, [{ validator: val => !val || /^(0(\.\d{1,2})+|([1-9]\d{0,3})(\.\d{1,2})?)$/.test(val), message: '请输入最多2位小数的4位正数'}])"
          v-model.trim="resForm.expectSalary" 
          label="期望年薪(万)" 
          type="number" 
          :disabled="expectSalaryDisabled"
          :maxLength="7" 
          :label-width="110" 
          placeholder="请输入"
        />
        <van-checkbox v-model="resForm.salaryType" @change="changeExpectSalaryType" shape="square">面议</van-checkbox>
      </div>
      <van-field
        v-else-if="isFiledCode('expectPosition', item)"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item)"
        readonly 
        clickable 
        name="expectPosition" 
        :value="resForm.expectPosition | FUNCTION"
        placeholder="请选择"
        right-icon="arrow"
        @click="expectPositionObj.isShow = true"
      />
      <van-field 
        v-else-if="isFiledCode('expectIndustry', item)"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item)"
        readonly 
        clickable 
        name="expectIndustry" 
        :value="resForm.expectIndustry | INDUSTRYARR"
        placeholder="请选择"
        right-icon="arrow"
        @click="expectIndustryObj.isShow = true"
      />
      <!-- 其他 -->
      <sub-custom-field v-else-if="item.isCheck && !['salaryType', 'taxType'].includes(item.fieldDto.fieldCode)" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="resForm.customFields[item.fieldId] || ''" @update="updateFieldSingle" />
    </template>
    <!-- 期望城市 -->
    <popup-area v-if="expectCityObj.isShow" :paramsObj="expectCityObj" @ok="onChangeExpectCity" />
    <!-- 期望行业 -->
    <popup-industry v-if="expectIndustryObj.isShow" :paramsObj="expectIndustryObj" @ok="onChangeExpectIndustry" />
    <!-- 期望职位 -->
    <popup-functions v-if="expectPositionObj.isShow" :dialogData="expectPositionObj" v-model="resForm.expectPosition" @success="onChangeExpectPosition" />
    <popup-picker v-if="showTaxTypeObj.isShow" v-model="resForm.taxType" :dialogData="showTaxTypeObj" @success="onConfirmTaxType" ></popup-picker>
  </div>
</template>

<script>
  import { RadioGroup, Radio, Field, Divider, Form, Checkbox, Picker, Popup } from 'vant'
  import mixin from '../../mixin'
  import { TaxType } from '@/enums/EnumBase'
  import popupIndustry from '@/components/h5/industry'
  import popupArea from '@/components/h5/area'
  import popupPicker from '@/components/h5/dialog/picker'
  import popupFunctions from '@/components/h5/functions'

  export default {
    props: {
      resForm: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    components: {
      [Field.name]: Field,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [Divider.name]: Divider,
      [Form.name]: Form,
      [Checkbox.name]: Checkbox,
      [Picker.name]: Picker,
      [Popup.name]: Popup,
      popupIndustry,
      popupArea,
      popupPicker,
      popupFunctions
    },
    mixins: [mixin],
    data() {
      return {
        TaxType,
        expectSalaryDisabled: false,
        salaryType: false,
        showTaxTypeObj: {
          isShow: false
        },
        expectIndustryObj: {
          isShow: false,
          max: 3
        },
        expectCityObj: {
          isShow: false,
          max: 3
        },
        salaryRemarkObj: {
          isShow: false,
          title: '年薪备注',
          maxLength: 50
        },
        expectPositionObj: { 
          isShow: false
        }
      }
    },
    created() {
      Object.assign(this.showTaxTypeObj, {
        columns: Object.values(TaxType),
      });
    },
    methods: {
       // 期望年薪面议选择
      changeExpectSalaryType(val) {
        if(val) {
          this.resForm.expectSalary = '';
          this.resForm.salaryType = true;
          this.expectSalaryDisabled = true;
        } else {
          this.resForm.salaryType = false;
          this.expectSalaryDisabled = false;
        }
      },
      onConfirmTaxType(val, index) {
        this.resForm.taxType = index + 1;
      },
      onChangeExpectCity(val) {
        this.resForm.expectCity = val;
        Object.assign(this.expectCityObj, {
          defaultVal: val
        });
      },
      onChangeExpectIndustry(val) {
        this.resForm.expectIndustry = val;
        Object.assign(this.expectIndustryObj, {
          defaultVal: val
        });
      },
      onChangeExpectPosition(data) {
        this.resForm.expectPosition = data[data.length - 1].value;
      }
    },
    watch: {
      resForm: {
        handler(val) {
          if(val.salaryType) {
            this.expectSalaryDisabled = true;
          }
          return val;
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>