<template>
  <div class="h5-container delivery-resume-box">
    <ul class="h5-container delivery-resume">
      <sub-head-navs v-if="websiteId>0" title="投递简历"></sub-head-navs>
      <li @click="() => pcResumeObj.isShow = true"><span>去电脑上填写简历</span><i class="h5pp h5pp-icon-arrows-right"></i></li>
      <li v-if="uccnInfo.resumeFileFlag" @click="onUploadReumse"><span>上传并解析附件简历</span><i class="h5pp h5pp-icon-arrows-right"></i></li>
      <li @click="onAddResume"><span>填写简历</span><i class="h5pp h5pp-icon-arrows-right"></i></li>
      <van-loading v-if="parseLoading" size="24px" vertical>简历解析中...</van-loading>
      <van-uploader
        class="upload-resume"
        v-model="resForm.resumeFileId"
        ref="uploadFile"
        :max-count="1"
        :max-size="10 * 1024 * 1024"
        @oversize="onOversize"
        :accept="acceptRes"
        :after-read="afterReadRes"
        :before-delete="beforeResumeFileDelete" />
      <popup-pc-resume v-if="pcResumeObj.isShow" :dialogData="pcResumeObj"></popup-pc-resume>
    </ul>
    <popup-share-menus />
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { local } from '@/utils'
  import { Uploader, Loading, Toast } from 'vant'
  import subHeadNavs from '@/components/h5/headNavs'
  import popupPcResume from '@/components/h5/dialog/addPcResume'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [Uploader.name]: Uploader,
      [Loading.name]: Loading,
      subHeadNavs,
      popupPcResume,
      popupShareMenus
    },
    data() {
      return {
        parseLoading: false,
        websiteId: '',
        jobId: '',
        jobCompanyId: '',
        personId: '',
        acceptRes: 'image/png,image/jpg,image/jpeg,.pdf,.doc,.xls,.ppt,.docx,.pptx,.wps,.html,.rtf,.txt',
        acceptAttach: 'image/png,image/jpg,image/jpeg,.pdf,.doc,.xls,.ppt,.docx,.pptx,.wps,.html,.rtf,.txt,.rar,.zip',
        pcResumeObj: { isShow: false },
        resForm: {
          resumeFileId: [], //简历附件
        }
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
    },
    created() {
      this.websiteId = this.$route.query.websiteId || '';
      this.jobId = this.$route.query.jobId || '';
      this.jobCompanyId = this.$route.query.jobCompanyId || '';
      this.personId = this.$route.query.personId || (JSON.parse(local.get('user')|| '{}')).personId || local.get('oldPersonId') || '';
      Object.assign(this.pcResumeObj, { 
        jobId: this.jobId, 
        personId: this.personId
      });
    },
    methods: {
      onUploadReumse() {
        this.$refs.uploadFile.chooseFile();
      },
      onAddResume() {
        this.$router.push({ name: 'ResumeAdd',
          query: {...this.$route.query}
        });
      },
      onOversize() {
        Toast('文件大小不能超过 10MB');
      },
      afterReadRes(data) {
        const uploadParams = new FormData();
        if(this.uccnInfo) {
          uploadParams.append('companyId', this.uccnInfo.companyId);
        } else if(this.jobCompanyId) {
          uploadParams.append('companyId', this.jobCompanyId);
        }
        uploadParams.append('source', 21);
        uploadParams.append('file', data.file);
        this.$http.post('/api/file/upload.json', uploadParams, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(data => {
          if(data) {
            this.resForm.resumeFileId = [{
              url: data.filePath,
              originName: data.fileName,
              id: data.id
            }];
            this.parseResumeFile(data.id);
            Toast.success({
              message: '上传成功',
              duration: 3000
            });
          }
        })
      },
      // 解析简历
      parseResumeFile(fileId) {
        if(!fileId) return;
        this.parseLoading = true;
        this.$http.get('/api/website/resume/parse.json', {params: {
          fileId: fileId
        }}).then(data => {
          if(data) {
            if(data.files && data.files.length) {
              data.files[0].originName =  data.files[0].fileName;
              data.resumeFileId = data.files;
            }
            if(data.resumeInfo && data.resumeInfo.birthYear) {
              data.birthYear = String(data.resumeInfo.birthYear);
            }
            if(data.workExps && data.workExps.length) {
              data.workExps = data.workExps.map(item => {
                item.dates = [[item.startYear, item.startMonth].join('.'), item.endYear === 9999 ? new Date().toISOString().slice(0,7).replace('-', '.') : [item.endYear, item.endMonth].join('.')].join('-');
                return item;
              });
            }
            if(data.projectExperience && data.projectExperience.length) {
              data.projectExperience = data.projectExperience.map(item => {
                item.dates = [[item.startYear, item.startMonth].join('.'), item.endYear === 9999 ? new Date().toISOString().slice(0,7).replace('-', '.') : [item.endYear, item.endMonth].join('.')];
                return item;
              });
            }
            if(data.trainingExps && data.trainingExps.length) {
              data.trainingExps = data.trainingExps.map(item => {
                item.dates = [[item.startYear, item.startMonth].join('.'), item.endYear === 9999 ? new Date().toISOString().slice(0,7).replace('-', '.') : [item.endYear, item.endMonth].join('.')].join('-');
                return item;
              });
            }
            if(data.eduExperience && data.eduExperience.length) {
              data.eduExperience = data.eduExperience.map(item => {
                item.dates = [[item.startYear, item.startMonth].join('.'), item.endYear === 9999 ? new Date().toISOString().slice(0,7).replace('-', '.') : [item.endYear, item.endMonth].join('.')].join('-');
                return item;
              });
            }
            delete data.fileId;//不删除会把this.resForm 的 fileId覆盖
            this.$router.push({ name: 'ResumeAdd',
              query: {...this.$route.query},
              params: {data}
            });
          }
        }).finally(()=>{
          this.parseLoading = false;
        });
      },
      beforeResumeFileDelete() {
        this.resForm.resumeFileId = [];
      }
    }
  }
</script>
