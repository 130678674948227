<template>
  <div class="h5-add-form-wrap">
    <sub-head-navs title="添加项目经历" :eventBack="true" @goBack="goBack"></sub-head-navs>
    <van-form class="add-form" ref="resumeFormRef" validate-trigger="onSubmit" scroll-to-error @submit="onConfirm">
      <template v-for="(item, key) in fieldsList">
        <van-field 
          v-if="isFiledCode('time', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model="resumeForm.dates" readonly clickable name="time" placeholder="请选择时间段" right-icon="arrow" @click="projectDateObj.isShow = true" />
        <van-field
          v-else-if="isFiledCode('name', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model="resumeForm.projectName" name="projectName" placeholder="项目名称" :maxlength="100" />
        <van-field
          v-else-if="isFiledCode('description', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model="resumeForm.projectDesc" autosize row="4" type="textarea" :maxlength="1000" show-word-limit placeholder="请输入项目描述" />
        <van-field
          v-else-if="isFiledCode('responsibilities', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          v-model="resumeForm.projectDuty" autosize type="textarea" :maxlength="1000" show-word-limit placeholder="请输入项目职责" />
        <!-- 其他 -->
        <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="getItemData(resumeForm, item.fieldId)" @update="updateField" />
      </template>
      <van-tabbar>
        <van-button class="btn" native-type="submit">确认添加</van-button>
      </van-tabbar>
    </van-form>
    <popup-picker v-if="projectDateObj.isShow" :dialogData="projectDateObj" v-model="resumeForm.dates" @success="onConfirmDate" />
  </div>
</template>
<script>
  import '../workExp/index.scss'
  import moment from 'moment'
  import mixin from '@/views/submodule/h5/resumeAdd/mixin'
  import { Form, RadioGroup, Radio, Field, Divider, Tabbar, Button  } from 'vant'
  import { Degree, DegreeOption } from '@/enums/EnumResume'
  import subHeadNavs from '@/components/h5/headNavs'
  import popupDatetimePicker from  '@/components/h5/dialog/datetimePicker'
  import popupPicker from '@/components/h5/dialog/picker'
  // import popupTextarea from '@/views/common/dialog/textarea'

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [Divider.name]: Divider,
      [Tabbar.name]: Tabbar,
      [Button.name]: Button,
      subHeadNavs,
      popupDatetimePicker,
      popupPicker,
      // popupTextarea
    },
    mixins: [mixin],
    data() {
      return {
        Degree,
        resumeForm: {
          dates: '',
          startYear: '',
          startMonth: '',
          endYear: '',
          endMonth: '',
          projectName: '',
          projectDesc: '',
          projectDuty: ''
        },
        projectDateObj: { isShow: false },
        startTimeObj: {
          isShow: false,
          type: 'year-month',
          title: '选择年月',
          minDate: new Date(moment().year(moment().year() - 10)),
          maxDate: new Date()
        },
        endTimeObj: {
          isShow: false,
          type: 'year-month',
          title: '选择年月',
          minDate: new Date(moment().year(moment().year() - 10)),
          showNow: true
        },
        professionalObj: {
          isShow: false,
          title: '专业',
          maxLength: 100
        },
        degreeObj: {
          isShow: false,
        }
      }
    },
    created() {
      if(this.$route.query.resumeForm) {
        const item = JSON.parse(this.$route.query.resumeForm);
        Object.assign(this.resumeForm, {
          ...item,
          customFields: item.customFields ? item.customFields : item.fieldMap || {}
        });
      }
      if(this.$route.query.moduleId) {
        this.queryConfigField();
      }
      Object.assign(this.degreeObj, { columns: DegreeOption, key: 'name' });
    },
    methods: {
      onConfirmDate(data, index) {
        this.resumeForm.dates = `${data[0]}/${data[1]}-${data[2]}/${data[3]}`;
        this.resumeForm.startYear = data[0];
        this.resumeForm.startMonth = data[1];
        this.resumeForm.endYear = data[2];
        this.resumeForm.endMonth = data[3];
        if(data[3] === '至今') {
          this.resumeForm.dates = `${data[0]}/${data[1]}-至今`;
          this.resumeForm.endYear = 9999;
          this.resumeForm.endMonth = 99;
        }
      },
      goBack() {
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'projectExperience',
            projectExperience: null
          }
        })
      },
      onConfirm() {
        Object.assign(this.resumeForm, {
          internshipFlag: +this.resumeForm.internshipFlag
        });
        this.resumeForm = Object.keys(this.$utils.deleteKeys(this.resumeForm)).length ? JSON.stringify(this.resumeForm) : null;
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'projectExperience',
            projectExperience: this.resumeForm
          }
        })
      }
    }
  }
</script>
