<template>
  <div class="item-resume-form-box">
    <el-form ref="resForm" :model="resForm" label-width="120px" class="item-resume-form resume-education-form" @submit.native.prevent>
      <div class="item-resume-form-title">
        <h4>教育经历</h4>
        <div class="btns"><a href="javascript:;" @click="onAddItem"><i class="h5pp h5pp-icon-append"></i>添加</a></div>
      </div>
      <div v-for="(obj, index) in resForm.expArr" :key="index" class="item-resume-form-content" :class="{'more-form-content': resForm.expArr.length > 1}">
        <template v-for="(item, key) in filedsConfig">
          <el-form-item v-if="isFiledCode('time', item)" :label="getLabel(item)" :prop="'expArr.' + index + '.yearMonths'" :rules="getRules(item, [{ validator: (rule, value, callback) => {
            if(!item.isRequired) {callback()} else if(!value[0]) {callback('请选择开始月份')} else if(!obj.checkedNow && !value[1]) { callback('请选择结束月份') } else { callback() }
          }, trigger: 'blur' }])" class="item-dates-range">
            <el-date-picker 
              type="month" 
              v-model="obj.yearMonths[0]" 
              placeholder="开始月份" 
              format="yyyy.MM"
              value-format="yyyy.MM" 
              align="center"
              prefix-icon="h5pp h5pp-icon-calendar"
              @change="onChangeDate($event, index, 'start')" />
            <p class="item-date-line">-</p>
            <el-date-picker 
              v-model="obj.yearMonths[1]" 
              type="month" 
              :placeholder="obj.checkedNow ? '至今' : '结束月份'" 
              format="yyyy.MM"
              value-format="yyyy.MM" 
              align="center"
              prefix-icon="h5pp h5pp-icon-calendar"
              :disabled="obj.checkedNow" 
              @change="onChangeDate($event, index, 'end')" />
            <el-checkbox class="item-checked-now" v-model="obj.checkedNow" @change="onCheckNow($event, index)">至今</el-checkbox>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('graduateSchool', item)" :label="getLabel(item)" :prop="'expArr.' + index + '.graduationSchool'" :rules="getRules(item)">
            <el-input v-model.trim="obj.graduationSchool" :maxLength="50" placeholder="请填写学校名称" />
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('type', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.unifiedExamFlag'" >
            <el-radio-group v-model="obj.unifiedExamFlag">
              <el-radio :label="1">统招</el-radio>
              <el-radio :label="0">非统招</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('degree', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.degree'">
            <el-select v-model="obj.degree" placeholder="请选择学历">
              <el-option v-for="(dItem, key) in item.fieldDto.itemDtos" :label="dItem.name" :value="+dItem.optionKey" :key="+dItem.optionKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('professional', item)" :label="getLabel(item)" :rules="getRules(item)"  :prop="'expArr.' + index + '.major'">
            <el-input v-model.trim="obj.major" :maxLength="100" placeholder="请填写专业" />
          </el-form-item>
          <!-- 其他 -->
          <sub-custom-field v-else-if="item.isCheck && !['type'].includes(item.fieldDto.fieldCode)" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="getItemData(obj, item.fieldId)" @update="updateField(index, $event)" />
        </template>
        <div v-if="resForm.expArr.length > 1" class="item-resume-trash">
          <a href="javascript:;" @click="onRemoveItem(index)"><i class="h5pp h5pp-icon-delete"></i>删除</a>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import './index.scss'
  import { Form, FormItem, Input, Button, Select, Option, DatePicker, Checkbox, Message, Radio, RadioGroup } from 'element-ui'
  import { Degree } from '@/enums/EnumResume'
  import mixin from '../../mixin'

  export default {
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Message.name]: Message,
      [Radio.name]: Radio,
      [RadioGroup.name]: RadioGroup
    },
    props: {
      subResData: {
        type: Array
      }
    },
    mixins: [mixin],
    data() {
      return {
        Degree,
        resForm: {
          expArr: []
        }
      }
    },
    methods: {
      onAddItem() {
        if(this.resForm.expArr.length >= 20) {
          this.$message.error('最多添加20条');
          return
        }
        const newItem = {
          yearMonths: [],
          startYear: '',
          startMonth: '',
          endYear: '',
          endMonth: '',
          checkedNow: false,
          graduationSchool: '',
          unifiedExamFlag: '',
          degree: '',
          major: ''
        };

        this.resForm.expArr.unshift(newItem);
      },
      onRemoveItem(index) {
        this.resForm.expArr.splice(index, 1);
      },
      onChangeDate(val, key, field) {
        if(!val) {
          this.resForm.expArr[key][`${field}Year`] = '';
          this.resForm.expArr[key][`${field}Month`] = '';
        } else {
          this.resForm.expArr[key][`${field}Year`] = val.split('.')[0];
          this.resForm.expArr[key][`${field}Month`] = val.split('.')[1];
        }
      },
      onCheckNow(val, key) {
        if(val) {
					this.resForm.expArr[key].endYear = 9999;
          this.resForm.expArr[key].endMonth = 99;
				} else {
          this.resForm.expArr[key].endYear = '';
          this.resForm.expArr[key].endMonth = '';
        }
        this.resForm.expArr[key].yearMonths = [this.resForm.expArr[key].yearMonths[0], ''];
        this.$refs.resForm.clearValidate('expArr.' + key + '.yearMonths');
      },
    },
    watch: {
      subResData: {
        handler(newVal, oldVal) {
          if(newVal && newVal.length) {
            this.resForm.expArr = [];
            newVal.forEach(item => {
              item.customFields = this.$utils.deepClone(item.fieldMap) || {};
              if(item.endYear === 9999) {
                item.checkedNow = true;
              }
              this.resForm.expArr.push(item);
            });
          } else {
            !(this.resForm.expArr && this.resForm.expArr.length) && this.onAddItem();
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>
