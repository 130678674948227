<template>
  <div class="item-resume-form-box">
    <el-form ref="resForm" :model="resForm" label-width="120px" class="item-resume-form resume-recom-form">
      <div v-if="uccnInfo.schoolAmbassadorFlag" class="item-resume-form-title">
        <h4>推荐人信息</h4>
        <el-form-item label="推荐码：" prop="schoolAmbassadorCode" :error="recomCodeErrorMsg">
          <el-input v-model.trim="resForm.schoolAmbassadorCode" placeholder="请填写推荐码" @focus="onFocusRecom"></el-input>
          <div v-if="editedRecomName" class="internal-btns">
            <a href="javascript:;" @click="onSaveAmbassadorName">确定</a>
            <a href="javascript:;" @click="onCancelAmbassadorName">取消</a>
          </div>
        </el-form-item>
        <el-form-item label="校园大使：" prop="schoolAmbassadorName">
          <el-input class="internal-name" v-model.trim="resForm.schoolAmbassadorName" disabled placeholder="输入推荐码后自动识别"></el-input>
        </el-form-item>
      </div>
      <div v-else class="item-resume-form-title">
        <h4>内推人信息</h4>
        <el-form-item label="内推码：" prop="internalCode" :error="recomCodeErrorMsg">
          <el-input v-model.trim="resForm.internalCode" placeholder="请填写内推码" @focus="onFocusRecom"></el-input>
          <div v-if="editedRecomName" class="internal-btns">
            <a href="javascript:;" @click="onSaveRecomName">确定</a>
            <a href="javascript:;" @click="onCancelRecomName">取消</a>
          </div>
        </el-form-item>
        <el-form-item label="内推人：" prop="internalName">
          <el-input class="internal-name" v-model.trim="resForm.internalName" disabled placeholder="输入内推码后自动识别"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Form, FormItem, Input, Button } from 'element-ui'
  import mixin from '../../mixin'
  import { local } from '@/utils'

  export default {
    props: {
      websiteId: [String, Number],
      personId: [String, Number],
      schoolAmbassadorCode: {
        type: String,
        default: ''
      },
      schoolAmbassadorName: {
        type: String,
        default: '无'
      }
    },
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Button.name]: Button
    },
    mixins: [mixin],
    computed: {
      ...mapGetters(['uccnInfo']),
      user() {
        return JSON.parse(local.get('user') || '{}');
      }
    },
    data() {
      return {
        editedRecomName: false,
        recomCodeErrorMsg: '',
        resForm: {
          internalCode: '',
          internalName: '',
          schoolAmbassadorCode: this.schoolAmbassadorCode,
          schoolAmbassadorName: this.schoolAmbassadorName
        },
        internalName: '',
        internalCode: '',
        newPersonId: ''
      }
    },
    created() {
      this.queryUserInfo();
      if(this.personId) {
        this.newPersonId = this.personId;
      }
    },
    methods: {
      queryUserInfo() {
        this.$http.get('/api/internal/recom/person/info.json', {params: {
          personId: this.personId
        }}).then(data => {
          if(data) {
            this.internalCode = data.internalCode;
            this.internalName = data.name || '无';
            this.resForm.internalCode = data.internalCode;
            this.resForm.internalName = data.name || '无';
          }
        });
      },
      onFocusRecom() {
        this.editedRecomName = true;
      },
      onSaveRecomName() {
        this.$http.get('/api/internal/recom/person/code.json', { params: {
          internalCode: this.resForm.internalCode,
          websiteId: this.websiteId
        }}).then(data => {
          if(data && Object.keys(data).length) {
            this.newPersonId = data.id || this.personId;
            this.resForm.internalName = data.name;
            this.editedRecomName = false;
            this.$refs.resForm.clearValidate('internalCode');
          } else {
            this.recomCodeErrorMsg = '找不到此内推人';
            this.resForm.internalName = '无';
          }
        });
      },
      onCancelRecomName() {
        this.resForm.internalCode = this.internalCode || this.user.internalCode;
        this.resForm.internalName = this.internalName || this.user.name || '无';
        this.editedRecomName = false;
        this.recomCodeErrorMsg = '';
        this.$refs.resForm.clearValidate('internalCode');
      },
      onSaveAmbassadorName() {
        this.recomCodeErrorMsg = '';
        this.$http.get('/api/school/ambassador/auth/name/by/code.json', { params: {
          websiteId: this.websiteId,
          code: this.resForm.schoolAmbassadorCode
        }}).then(data => {
          if(data && Object.keys(data).length) {
            this.newPersonId = data.id || this.personId;
            this.resForm.schoolAmbassadorName = data.name;
            this.editedRecomName = false;
          } else {
            this.recomCodeErrorMsg = '找不到此校园大使';
            this.resForm.schoolAmbassadorName = '无';
          }
        });
      },
      onCancelAmbassadorName() {
        this.resForm.schoolAmbassadorCode = this.schoolAmbassadorCode;
        this.resForm.schoolAmbassadorName = this.schoolAmbassadorName || '无';
        this.editedRecomName = false;
        this.recomCodeErrorMsg = '';
      },
    },
    watch: {
      resForm: {
        handler(val) {
          this.$emit('onRecomInfo', this.newPersonId, this.resForm);
          return val;
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>

 

