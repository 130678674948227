<template>
  <sub-h5-resume-add v-if="$utils.isPhone()"></sub-h5-resume-add>
  <sub-pc-resume-add v-else></sub-pc-resume-add>
</template>

<script>
  import subH5ResumeAdd from '@/views/submodule/h5/resumeAdd'
  import subPcResumeAdd from '@/views/submodule/pc/resumeAdd'
  export default {
    components: {
      subH5ResumeAdd,
      subPcResumeAdd
    }
  }
</script>