<template>
  <div class="h5-container" @scroll="(e) => onScroll(e)">
    <sub-head-navs v-if="websiteId>0" :title="pathName === 'ResumeAdd' ? '投递' : '我的简历'"></sub-head-navs>
    <div class="resume-add-wrap">
      <!-- 投递 -->
      <template v-if="pathName === 'ResumeAdd'">
        <!-- 开启校园大使，投递使用校园大使推荐码 -->
        <template v-if="uccnInfo.schoolAmbassadorFlag">
          <van-form class="res-form" ref="recomForm">
            <h3>推荐人信息</h3>
            <div class="form-filed-box form-filed-box-one">
              <van-field ref="recomCode" class="recom-code" v-model.trim="recomForm.schoolAmbassadorCode" name="schoolAmbassadorCode" label="推荐码" @click-input="onEditRecomName" placeholder="请输入推荐码" :error-message="recomCodeErrorMsg">
                <i v-if="!editedRecomName" @click="onEditRecomName" slot="right-icon" class="h5pp h5pp-icon-edit2"></i>
                <div v-else slot="right-icon">
                  <a href="javascript:;" @click="onSaveAmbassadorName">确定</a>
                  <a href="javascript:;" @click="onCancelAmbassadorName">取消</a>
                </div>
              </van-field>
              <van-field v-model="recomForm.schoolAmbassadorName" name="schoolAmbassadorName" label="校园大使" placeholder="输入推荐码后自动识别" />
            </div>
          </van-form>
        </template>
        <template v-else-if="isRecommendWebsite">
          <van-form class="res-form" ref="recomForm">
            <h3>内推人信息</h3>
            <div class="form-filed-box form-filed-box-one">
              <van-field ref="recomCode" class="recom-code" v-model.trim="recomForm.internalCode" name="internalCode" label="内推码" @click-input="onEditRecomName" placeholder="请输入内推码" :error-message="recomCodeErrorMsg">
                <i v-if="!editedRecomName" @click="onEditRecomName" slot="right-icon" class="h5pp h5pp-icon-edit2"></i>
                <div v-else slot="right-icon">
                  <a href="javascript:;" @click="onSaveRecomName">确定</a>
                  <a href="javascript:;" @click="onCancelRecomName">取消</a>
                </div>
              </van-field>
              <van-field v-model="recomForm.internalName" name="internalName" label="内推人" placeholder="输入内推码后自动识别" />
            </div>
          </van-form>
        </template>
      </template>
      <div v-if="true" class="resume-wrap">
       <van-form class="res-form" ref="resForm" validate-trigger="onSubmit" scroll-to-error @failed="onFailed" @submit="onSave">
        <div class="form-filed-box" :class="{'disabled-form': isDisabledForm}">
          <template v-if="uccnInfo.resumeFileFlag">
            <h3 v-if="pathName === 'ResumeAdd'">附件简历</h3>
            <div class="form-item-upload">
              <van-field label="附件简历" name="resumeFileId" class="not-cell">
                <template #input>
                  <van-uploader
                    v-if="!resForm.resumeFileId || !resForm.resumeFileId.length"
                    v-model="resForm.resumeFileId"
                    :max-count="1"
                    :max-size="10 * 1024 * 1024"
                    @oversize="onOversize"
                    :accept="acceptRes"
                    :after-read="afterReadRes">
                    <div class="upload-btn"><i class="h5pp h5pp-icon-append"></i></div>
                  </van-uploader>
                  <ul v-else class="file-list">
                    <li v-for="(item, key) in resForm.resumeFileId" :key="key">
                      <p class="file-name el-sl">{{ item.originName }}</p>
                      <i class="h5pp h5pp-icon-delete" @click.stop="onItemsFile(key, 'resumeFileId')"></i>
                    </li>
                  </ul>
                </template>
              </van-field>
              <p class="upload-tips">支持pdf、doc、xls、ppt、docx、pptx、wps、html、jpg、jpeg、png、rtf、txt等简历格式</p>
            </div>
          </template>
          <template v-for="(config, configIndex) in configList">
            <sub-base v-if="isShowModule(config, 1)" :key="configIndex" :moduleId="config.moduleId" :filedsConfig="config.fields" ref="resBase" :isDisabledPhone="isDisabledPhone" :isDisabledEmail="isDisabledEmail" :resForm="resForm.resumeInfo"/>
            <sub-intention v-if="isShowModule(config, 2)" :key="configIndex" :moduleId="config.moduleId" :filedsConfig="config.fields" :resForm="resForm.jobIntention" />
            <!-- 工作经历 -->
            <div v-if="isShowModule(config, 3)" :key="configIndex" class="form-item">
              <h3 :class="{'require-tip': isRequired(config.fields).length}">工作经历</h3>
              <van-field name="workExperience" :class="{'no-val-item': !resForm.workExperience.length }" :rules="[{ required: !resForm.workExperience.length && isRequired(config.fields).length, message: '工作经历必填'}]">
                <template v-if="resForm.workExperience.length" #input>
                  <div v-for="(item, key) in resForm.workExperience" :key="'work_'+key" class="exp-item" @click.stop="addExp('WorkExp', config.moduleId, item, key+1)">
                    <sub-list-item :fields="config.fields" :dataObj="item" :moduleCode="3" />
                    <a href="javascript:;" class="remove-btn" @click.stop="removeItems('workExperience', key)"><i class="h5pp h5pp-icon-subtract"></i>删除</a>
                  </div>
                </template>
              </van-field>
              <van-button native-type="button" icon="add-o" plain class="btn-add-item" @click="addExp('WorkExp', config.moduleId)">添加</van-button>
            </div>
            <!-- 教育经历 -->
            <div v-else-if="isShowModule(config, 4)" :key="configIndex" class="form-item">
              <h3 :class="{'require-tip': isRequired(config.fields).length}">教育经历</h3>
              <van-field name="eduExperience" :class="{'no-val-item': !resForm.eduExperience.length}" :rules="[{ required: !resForm.eduExperience.length && isRequired(config.fields).length, message: '教育经历必填'}]">
                <template v-if="resForm.eduExperience.length" #input>
                  <div v-for="(item, key) in resForm.eduExperience" :key="'edu_'+key" class="exp-item" @click.stop="addExp('EducationExp', config.moduleId, item, key+1)">
                    <sub-list-item :fields="config.fields" :dataObj="item" :moduleCode="4" />
                    <a href="javascript:;" class="remove-btn" @click.stop="removeItems('eduExperience', key)"><i class="h5pp h5pp-icon-subtract"></i>删除</a>
                  </div>
                </template>
              </van-field>
              <van-button native-type="button" icon="add-o" plain class="btn-add-item" @click="addExp('EducationExp', config.moduleId)">添加</van-button>
            </div> 
            <!-- 项目经历 -->
            <div v-else-if="isShowModule(config, 5)" :key="configIndex" class="form-item">
              <h3 :class="{'require-tip': isRequired(config.fields).length}">项目经历</h3>
              <van-field name="projectExperience" :class="{'no-val-item': !resForm.projectExperience.length}" :rules="[{ required: !resForm.projectExperience.length && isRequired(config.fields).length, message: '项目经历必填'}]">
                <template v-if="resForm.projectExperience.length" #input>
                  <div v-for="(item, key) in resForm.projectExperience" :key="'project_'+key" class="exp-item" @click.stop="addExp('ProjectExp', config.moduleId, item, key+1)">
                    <sub-list-item :fields="config.fields" :dataObj="item" :moduleCode="5" />
                    <a href="javascript:;" class="remove-btn" @click.stop="removeItems('projectExperience', key)"><i class="h5pp h5pp-icon-subtract"></i>删除</a>
                  </div>
                </template>
              </van-field>
              <van-button native-type="button" icon="add-o" plain class="btn-add-item" @click="addExp('ProjectExp', config.moduleId)">添加</van-button>
            </div>
            <!-- 语言能力 -->
            <div v-else-if="isShowModule(config, 6)" :key="configIndex" class="form-item">
              <h3 :class="{'require-tip': isRequired(config.fields).length}">语言能力</h3>
              <van-field name="languages" :class="{'no-val-item': !resForm.languages.length}" :rules="[{ required: !resForm.languages.length && isRequired(config.fields).length, message: '语言能力必填'}]">
                <template v-if="resForm.languages.length" #input>
                  <div v-for="(item, key) in resForm.languages" :key="'language_'+key" class="exp-item" @click.stop="addExp('LanguageExp', config.moduleId, item, key+1)">
                    <sub-list-item :fields="config.fields" :dataObj="item" :moduleCode="6" />
                    <a href="javascript:;" class="remove-btn" @click.stop="removeItems('languages', key)"><i class="h5pp h5pp-icon-subtract"></i>删除</a>
                  </div>
                </template>
              </van-field>
              <van-button native-type="button" icon="add-o" plain class="btn-add-item" @click="addExp('LanguageExp', config.moduleId)">添加</van-button>
            </div>
            <!-- 附件作品 -->
            <div v-else-if="isShowModule(config, 7)" :key="configIndex" class="form-item">
              <h3 :class="{'require-tip': isRequired(config.fields).length}">附件作品</h3>
              <van-field name="products" :class="{'no-val-item': !resForm.products.length}" :rules="[{ required: !resForm.products.length && isRequired(config.fields).length, message: '附件作品必填'}]">
                <template v-if="resForm.products.length" #input>
                  <div v-for="(item, key) in resForm.products" :key="'products_'+key" class="exp-item" @click.stop="addExp('Products', config.moduleId, item, key+1)">
                    <sub-list-item :fields="config.fields" :dataObj="item" :moduleCode="7" />
                    <a href="javascript:;" class="remove-btn" @click.stop="removeItems('products', key)"><i class="h5pp h5pp-icon-subtract"></i>删除</a>
                  </div>
                </template>
              </van-field>
              <van-button native-type="button" icon="add-o" plain class="btn-add-item" @click="addExp('Products', config.moduleId)">添加</van-button>
            </div>
            <!-- 培训经历 -->
            <div v-else-if="isShowModule(config, 8)" :key="configIndex" class="form-item">
              <h3 :class="{'require-tip': isRequired(config.fields).length }">培训经历</h3>
              <van-field name="trainingExps" :class="{'no-val-item': !resForm.trainingExps.length}" :rules="[{ required: !resForm.trainingExps.length && isRequired(config.fields).length, message: '培训经历必填'}]">
                <template v-if="resForm.trainingExps.length" #input>
                  <div v-for="(item, key) in resForm.trainingExps" :key="'train_'+key" class="exp-item" @click.stop="addExp('TrainExp', config.moduleId, item, key+1)">
                    <sub-list-item :fields="config.fields" :dataObj="item" :moduleCode="8" />
                    <a href="javascript:;" class="remove-btn" @click.stop="removeItems('trainingExps', key)"><i class="h5pp h5pp-icon-subtract"></i>删除</a>
                  </div>
                </template>
              </van-field>
              <van-button native-type="button" icon="add-o" plain class="btn-add-item" @click="addExp('TrainExp', config.moduleId)">添加</van-button>
            </div>
            <!-- 自我描述 -->
            <sub-self v-else-if="isShowModule(config, 9)" :key="configIndex" :moduleId="config.moduleId" :filedsConfig="config.fields" ref="resSelf" :resForm="resForm.selfIntroduce" />
            <template v-else-if="isShowCustomModule(config)">
              <sub-custom-moudle v-if="!config.moduleDto.multiFlag" :key="configIndex" :ref="`customMoudle-${config.moduleId}`" :moudleData="config" :subResData="resForm.customModules ? resForm.customModules[config.moduleId] : []"></sub-custom-moudle>
              <template v-else>
                <!-- 多段自定义内容 -->
                <div :key="configIndex" class="form-item">
                  <h3 :class="{'require-tip': isRequired(config.fields).length}">{{config.moduleDto.name}}</h3>
                    <van-field name="customExp" :class="{'no-val-item': !customDataList(resForm.customModules[config.moduleId], config.fields).length}" :rules="[{ required: !customDataList(resForm.customModules[config.moduleId], config.fields).length && isRequired(config.fields).length, message: `${config.moduleDto.name}必填`}]">
                      <template v-if="customDataList(resForm.customModules[config.moduleId], config.fields).length" #input>
                        <div v-for="(item, key) in customDataList(resForm.customModules[config.moduleId], config.fields)" :key="'custom_'+key" class="exp-item" @click.stop="addExp('CustomExp', config.moduleId, resForm.customModules[config.moduleId][key], key+1)">
                          <div v-if="item[0]" class="exp-title"><span class="el-sl">{{ item[0] }}</span><i class="h5pp h5pp-icon-arrows-right"></i></div>
                          <div v-if="item[1]" class="job">{{ item[1] }}</div>
                          <div v-if="item[1]"  class="date">{{ item[2] }}</div>
                          <a href="javascript:;" class="remove-btn" @click.stop="removeItems('CustomExp', key, config.moduleId)"><i class="h5pp h5pp-icon-subtract"></i>删除</a>
                        </div>
                      </template>
                    </van-field>
                  <van-button native-type="button" icon="add-o" plain class="btn-add-item" @click="addExp('CustomExp', config.moduleId)">添加</van-button>
                </div>
              </template>
            </template>
          </template>
        </div>
        <van-tabbar>
          <van-button v-if="isEdit" native-type="submit">确认提交</van-button>
          <span class="edit-btn" v-else @click="onEdit">编辑</span>
        </van-tabbar>
       </van-form>
      </div>
    </div>
    <popup-share-menus v-if="pathName === 'myResume'"></popup-share-menus>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Form, Field, Popup, Picker, Area, Uploader, Tabbar, Button, Toast, Loading, Switch, Dialog } from 'vant'
  import { PhoneArea, GenderType } from '@/enums/EnumBase'
  import { WorkField, EduField, ProjectField, LanguageField, TrainingExpsField } from '@/enums/EnumResume'
  import { Proficiency, Language, LanguageGrade } from '@/enums/EnumResume'
  import { local } from '@/utils'
  import { FULLCITY } from '@/utils/filters'
  import subBase from './submodule/base'
  import subIntention from './submodule/intention'
  import subCustomMoudle from './submodule/customMoudle'
  import subHeadNavs from '@/components/h5/headNavs'
  import subSelf from './submodule/self'
  import subListItem from './submodule/listItem'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [Popup.name]: Popup,
      [Picker.name]: Picker,
      [Area.name]: Area,
      [Uploader.name]: Uploader,
      [Tabbar.name]: Tabbar,
      [Button.name]: Button,
      [Loading.name]: Loading,
      [Switch.name]: Switch,
      subBase,
      subIntention,
      subHeadNavs,
      subCustomMoudle,
      subSelf,
      subListItem,
      popupShareMenus
    },
    data() {
      return {
        isLoading: false,
        PhoneArea,
        GenderType,
        WorkField,
        EduField,
        ProjectField,
        LanguageField,
        TrainingExpsField,
        Proficiency,
        Language,
        LanguageGrade,
        websiteId: '',
        jobId: '',
        jobCompanyId: '',
        accountId: '',
        optRecordId: '',
        configShowFields: [],
        configFields: {},
        minDate: new Date(1979, 0, 1),
        maxDate: new Date(),
        areaOptions: {},
        industryOptions: {},
        recomCodeErrorMsg: '',
        recomForm: {
          schoolAmbassadorCode: '',
          schoolAmbassadorName: '无',
          internalCode: '',
          internalName: '无'
        },
        resForm: {
          resumeInfo: {
            name: '',
            area: 1,
            phone: '',
            gender: '',
            birthYear: '',
            workCity: [],
            hometownCity: [],
            email: '',
            wechatCode: '',
            customFields: {}
          },
          jobIntention: {
            expectCity: [],
            currentSalary: '',
            taxType: '',
            salaryRemark: '',
            expectSalary: '',
            salaryType: false,
            expectPosition: '',
            expectIndustry: [],
            customFields: {}
          },
          selfIntroduce: {
            customFields: {}
          },
          resumeFileId: [], //简历附件
          workExperience: [],
          projectExperience: [],
          eduExperience: [],
          languages: [],
          products: [],
          trainingExps: [],
          customModules: {}
        },
        acceptRes: 'image/png,image/jpg,image/jpeg,.pdf,.doc,.xls,.ppt,.docx,.pptx,.wps,.html,.rtf,.txt',
        acceptAttach: 'image/png,image/jpg,image/jpeg,.pdf,.doc,.xls,.ppt,.docx,.pptx,.wps,.html,.rtf,.txt,.rar,.zip',
        externalRecomFlag: false, // 允许外部人员推荐
        isEdit: true,
        isDisabledForm: false,
        resVersionId: '',
        resumeId: '',
        editedRecomName: false,
        personId: '',
        schoolAmbassadorCode: '',
        schoolAmbassadorName: '无',
        schoolInterviewSiteId: '',
        internalCode: '',
        internalName: '',
        currentIndex: 0,
        scrollY: '',
        configList: [
          {
            fields: [
              {
                fieldDto: {
                  fieldCode: "name",
                  id: 1,
                  name: "姓名",
                  type: 2,
                },
                fieldId: 1,
                isCheck: 1,
                isRequired: 1
              },
              {
                fieldDto: {
                  fieldCode: "phone",
                  id: 6,
                  name: "手机号码",
                  type: 2
                },
                fieldId: 6,
                isCheck: 1,
                isRequired: 1,
                sort: 5,
              }
            ],
            isOpen: 1,
            moduleDto: {
              moduleCode: 1
            },
            moduleId: 1
          }
        ], // 邮件落地页过来的默认显示名字和手机号不走配置
      }
    },
    provide() {
      return {
        pageFrom: this.$route.name
      }
    },
    computed: {
      ...mapGetters(['degrees', 'locations', 'industrys', 'scales', 'uccnInfo']),
      yearList() {
        let nowYear = new Date().getFullYear(),
            yearArr = [];
        for(let index = 1950; index <= nowYear; index++) {
          yearArr.push(index);
        }
        return yearArr;
      },
      pathName() {
        return this.$route.name;
      },
      isRecommendWebsite() {
        return (this.$route.query.websiteId > 0 && (JSON.parse(local.get('uccnInfo') || '{}')).type) || '';
      },
      user() {
        return JSON.parse(local.get('user') || '{}');
      },
      mobile() {
        return (JSON.parse(local.get('user') || '{}') || {}).mobile || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}') || {}).email || '';
      },
      isShowModule() {
        return (module, moduleCode) => {
          return module.isOpen && module.moduleDto.moduleCode === moduleCode;
        }
      },
      isShowCustomModule() {
        return (module) => {
          return module.isOpen && ![1,2,3,4,5,6,7,8,9].includes(module.moduleDto.moduleCode);
        }
      },
      customDataList() {
        return (data = [], config) => {
          let list = [];
          if(!data || !data.length) return [];
          data.forEach(moduleData => {
            let item = [];
            config.forEach(field => {
              if(field.isCheck && moduleData[field.fieldId]) {
                if(moduleData[field.fieldId].value && moduleData[field.fieldId].fieldType !== 6 && this.getFieldData(moduleData[field.fieldId])) {
                  item.push(this.getFieldData(moduleData[field.fieldId]));
                }
              }
            });
            if(item.length) {
              list.push(item);
            }
          });
          return list;
        }
      },
      isDisabledPhone() {
        // 内推官网已认证角色（企业员工、外部用户） & 校招官网-校园大使端，才可以修改手机号
        if(this.$route.query.websiteId == -1) {
          return false;
        } else {
          // 0: 企业员工, 1: 外部人员, 2: 校园大使 3: 候选人角色
          switch(this.user.externalFlag) {
            case 0:
            case 1:
              return !this.mobile;
            case 2:
              return !this.mobile;
            case 3:
              if(this.mobile && this.email) {
                return true;
              }
              if(this.mobile && !this.email) {
                return true;
              }
              if(!this.mobile && this.email) {
                return false;
              }
            default:
              return true;
          }
        }
      },
      isDisabledEmail() {
        // 内推官网已认证角色（企业员工、外部用户） & 校招官网-校园大使端，才可以修改手机号
        if(this.$route.query.websiteId == -1) {
          return false;
        } else {
          
          // 0: 企业员工, 1: 外部人员, 2: 校园大使 3: 候选人角色
          switch(this.user.externalFlag) {
            case 0:
            case 1:
              return false;
            case 2:
              return false;
            case 3:
              if(this.mobile && this.email) {
                return true;
              } else if(this.mobile && !this.email) {
                return false;
              } else if(!this.mobile && this.email) {
                return true;
              } else {
                return false;
              }
            default: //没有flag，按候选人算
              if(this.mobile && this.email) {
                return true;
              } else if(this.mobile && !this.email) {
                return false;
              } else if(!this.mobile && this.email) {
                return true;
              } else {
                return false;
              }
          }
        }
      }
    },
    created() {
      this.optRecordId = this.$route.query.optRecordId || '';
      this.websiteId = this.$route.query.websiteId || '';
      this.jobId = this.$route.query.jobId || '';
      this.resLibraryId = this.$route.query.resLibraryId || '';
      this.jobCompanyId = this.$route.query.jobCompanyId || '';
      this.accountId = this.$route.query.accountId || '';
      this.personId = this.$route.query.personId || (JSON.parse(local.get('user')|| '{}')).personId || local.get('oldPersonId') || '';
      // 校园大使code码和名称
      this.recomForm.schoolAmbassadorCode = this.schoolAmbassadorCode = this.$route.query.schoolAmbassadorCode || '';
      this.recomForm.schoolAmbassadorName = this.schoolAmbassadorName = this.$route.query.schoolAmbassadorName || '';
      // 校园站点
      this.schoolInterviewSiteId = this.$route.query.schoolInterviewSiteId || '';
      if(+this.websiteId !== -1) {
        this.queryConfigField();
      }
      this.init();
    },
    activated() {
      if(this.$route.query.field) {
        this.resForm = local.getObject('resForm');
        this.scrollY = local.get('scrollY') || 0;
        if(this.$route.query.field === 'customExp') {
          if(+this.$route.query.currentIndex && this.$route.query[this.$route.query.field]) {
            if(this.resForm.customModules[this.$route.query.moduleId]) {
              this.resForm.customModules[this.$route.query.moduleId][+this.$route.query.currentIndex - 1] = JSON.parse(this.$route.query[this.$route.query.field]);
            } else {
              this.resForm.customModules[this.$route.query.moduleId] = [JSON.parse(this.$route.query[this.$route.query.field])];
            }
          } else if(this.$route.query[this.$route.query.field]) {
            if(this.resForm.customModules[this.$route.query.moduleId]) {
              this.resForm.customModules[this.$route.query.moduleId].push(JSON.parse(this.$route.query[this.$route.query.field]));
            } else {
              this.resForm.customModules[this.$route.query.moduleId] = [JSON.parse(this.$route.query[this.$route.query.field])];
            }
          }
        } else if(+this.$route.query.currentIndex && this.$route.query[this.$route.query.field]) {
          this.resForm[this.$route.query.field][+this.$route.query.currentIndex - 1] = JSON.parse(this.$route.query[this.$route.query.field]);
          this.$forceUpdate();
        } else if(this.$route.query[this.$route.query.field]){
          this.resForm[this.$route.query.field].push(JSON.parse(this.$route.query[this.$route.query.field]));
        }
      } else if(!this.$route.query.field && local.getObject('resForm') && Object.keys(local.getObject('resForm')).length){
        local.remove('resForm');
        local.remove('scrollY');
      }
      this.$nextTick(() => {
        document.querySelector('.h5-container').scrollTop = +this.scrollY;
      });
    },
    methods: {
      init() {
        if(this.isRecommendWebsite) {
          if(this.pathName === 'myResume' && !this.$route.query.field) {
            this.isEdit = false;
            this.queryResumeDetail(true);
          } else {
            this.queryUserInfo();
            if(this.$route.params.data) { 
              this.queryConfig();
            } else {
              // 非认证内推人 => 候选人 反显上次投递信息
              if(!this.personId && !this.$route.query.field) this.queryResumeDetail();
            }
          }
        } else {
          if(!this.$route.query.field) {
            if(this.pathName === 'myResume') {
              this.isEdit = false;
              this.queryResumeDetail(true);
            } else {
              if(this.websiteId != -1) {
                if(this.$route.params.data) { 
                  this.queryConfig();
                } else {
                  this.queryResumeDetail();
                }
              }
            }
          }
        }
        this.resForm.resumeInfo.phone = this.user.mobile;
        this.resForm.resumeInfo.email = this.user.email;
      },
      getFieldData(newVal) {
        let resultVal = '';
        if(newVal && newVal.value) {
          if([4, 5, 6, 7, 8, 9].includes(newVal.fieldType)) {
            let value = JSON.parse(newVal.value);
            if(newVal.fieldType === 4 && value.itemId) {
              resultVal = value.name;
              if(value.other) {
                resultVal = value.other;
              }
            }
            if(newVal.fieldType === 5 && value.length) {
              let names= [];
              value.forEach(item => {
                let name = item.name;
                if(item.other) {
                  name = item.other;
                }
                names.push(name);
              });
              resultVal = names.join(',');
            }
            if(newVal.fieldType === 7 && value.timeValue) {
              resultVal = value.timeValue;
            }
            if(newVal.fieldType === 8) {
              resultVal = FULLCITY(value.cityCode) + value.address;
            }
            if(newVal.fieldType === 9) {
              resultVal = [value.startTime, value.endTime].join('~');
            }
          } else {
            if(newVal.fieldType === 1) {
              resultVal = newVal.value;
            } else if(newVal.fieldType === 2) {
              resultVal = newVal.value;
            } else if(newVal.fieldType === 3) {
              resultVal = +newVal.value > 0 ? '是' : '否';
            }
          }
        }
        return resultVal;
      },
      isRequired(list) {
        return list.filter(item => item.isCheck && item.isRequired);
      },
      getRequireList(list) {
        return list.filter(item => item.isCheck && item.isRequired && item.fieldDto.fieldCode).map(item => item.fieldDto.fieldCode);
      },
      getCheckList(list) {
        return list.filter(item => item.isCheck && item.fieldDto.fieldCode).map(item => item.fieldDto.fieldCode);
      },
      // 手机号投递职位订单状态
      queryJobRecommendStatus() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/mobile/check/info.json', {params: {
            jobId: this.jobId,
            mobile: this.resForm.resumeInfo.phone
          }}).then(data => {
            if(data) {
              Dialog.alert({
                message: '您已经在流程中，请勿重复投递',
              }).then(() => {
                resolve(false);
              });
            } else {
              resolve(true);
            }
          });
        })
      },
      queryConfig() {
        this.$http.get('/api/internal/recom/auth/info.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            // 从解析简历的页面过来
            if(this.$route.params.data) {
              let data = this.$route.params.data;
              if(!this.isDisabledPhone) {
                data.resumeInfo.phone = data.resumeInfo.phone || this.resForm.resumeInfo.phone;
              } else {
                data.resumeInfo.phone = this.resForm.resumeInfo.phone;
              }
              this.formatFormData(data);
            }
          }
        })
      },
      queryUserInfo() {
        this.$http.get('/api/internal/recom/person/info.json', {params: {
          personId: this.personId
        }}).then(data => {
          if(data) {
            this.internalCode = data.internalCode;
            this.internalName = data.name || '无';
            this.recomForm.internalCode = data.internalCode;
            this.recomForm.internalName = data.name || '无';
          }
        });
      },
      formatDate(arr) {
        if(arr && arr.length) {
          arr = arr.map(item => {
            if(item.startYear && item.startMonth) {
              item.dates = [[item.startYear, item.startMonth].join('.'), item.endYear === 9999 ? '至今' : [item.endYear, item.endMonth].join('.')].join('-');
            }
            return item;
          });
        }
        return arr;
      },
      formatFormData(data) {
        this.resVersionId = data.resVersionId;
        this.resumeId = data.resumeInfo ? data.resumeInfo.id : '';
        if(data.files && data.files.length) {
          data.files[0].originName =  data.files[0].fileName;
          data.resumeFileId = data.files;
        }
        if(data.resumeInfo && data.resumeInfo.birthYear) {
          data.resumeInfo.birthYear = String(data.resumeInfo.birthYear);
        }
        this.formatDate(data.workExps);
        this.formatDate(data.projectExperience);
        this.formatDate(data.trainingExps);
        this.formatDate(data.eduExperience);
        Object.assign(this.resForm, {
          resumeInfo: Object.assign({}, this.$utils.deepClone(data.resumeInfo), {
            customFields: data.resumeInfo?.fieldMap || {},
            area: data.resumeInfo && data.resumeInfo.area ? data.resumeInfo.area : 1,
            workCity: data.resumeInfo && data.resumeInfo.workCity ? data.resumeInfo.workCity.split(',') : [],
            phone: data.resumeInfo && data.resumeInfo.phone ? data.resumeInfo.phone : (this.user?.mobile || ''),
            email: data.resumeInfo && data.resumeInfo.email ? data.resumeInfo.email : (this.user?.email || ''),
            hometownCity: data.resumeInfo && data.resumeInfo.hometownCity ? data.resumeInfo.hometownCity.split(',') : [],
            picture: data.resumeInfo && data.resumeInfo.headUrl || ''
          }),
          jobIntention: Object.assign({}, this.$utils.deepClone(data.jobIntention), {
            customFields: data.jobIntention?.fieldMap || {},
            expectCity: data.jobIntention && data.jobIntention.expectCity ? data.jobIntention.expectCity.split(',') : [] ,
            salaryType: data.jobIntention && data.jobIntention.salaryType === 2 ? true : false,
            expectIndustry: data.jobIntention && data.jobIntention.expectIndustry ? data.jobIntention.expectIndustry.split(',') : [],
          }),
          selfIntroduce: Object.assign({}, this.$utils.deepClone(data.selfIntroduce), {
            customFields: data.selfIntroduce?.fieldMap || {}
          }),
          customModules: Object.assign({}, this.$utils.deepClone(data.customModules)),
          workExperience: data.workExps || [],
          projectExperience: data.projectExperience || [],
          trainingExps: data.trainingExps || [],
          eduExperience: data.eduExperience || [],
          resumeFileId: data.files || [],
          products: data.products || [],
          languages: data.languages || [],
        });
      },
      queryResumeDetail(isInit) {
        this.$http.get('/api/internal/recom/person/res/detail.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.isDisabledForm = isInit ? true : false;
            this.formatFormData(data);
          }
        })
      },
      onEditRecomName() {
        this.editedRecomName = !this.editedRecomName;
        this.$refs.recomCode.focus();
      },
      onSaveRecomName() {
        this.recomCodeErrorMsg = '';
        this.$http.get('/api/internal/recom/person/code.json', { params: {
          internalCode: this.recomForm.internalCode,
          websiteId: this.websiteId
        }}).then(data => {
          if(data && Object.keys(data).length) {
            this.personId = data.id || this.personId;
            this.recomForm.internalName = data.name;
            this.editedRecomName = !this.editedRecomName;
          } else {
            this.recomCodeErrorMsg = '找不到此内推人';
            this.recomForm.internalName = '无';
          }
        });
      },
      onCancelRecomName() {
        this.recomForm.internalCode = this.internalCode || this.user.internalCode;
        this.recomForm.internalName = this.internalName || this.user.name || '无';
        this.editedRecomName = !this.editedRecomName;
        this.recomCodeErrorMsg = '';
      },
      onSaveAmbassadorName() {
        this.recomCodeErrorMsg = '';
        this.$http.get('/api/school/ambassador/auth/name/by/code.json', { params: {
          websiteId: this.websiteId,
          code: this.recomForm.schoolAmbassadorCode
        }}).then(data => {
          if(data && Object.keys(data).length) {
            this.personId = data.id || this.personId;
            this.recomForm.schoolAmbassadorName = data.name;
            this.editedRecomName = !this.editedRecomName;
          } else {
            this.recomCodeErrorMsg = '找不到此校园大使';
            this.recomForm.schoolAmbassadorName = '无';
          }
        });
      },
      onCancelAmbassadorName() {
        this.recomForm.schoolAmbassadorCode = this.schoolAmbassadorCode;
        this.recomForm.schoolAmbassadorName = this.schoolAmbassadorName || '无';
        this.editedRecomName = !this.editedRecomName;
        this.recomCodeErrorMsg = '';
      },
      onEdit() {
        this.isEdit = true;
        this.isDisabledForm = false;
      },
      onItemsFile(key, fileType) {
        this.resForm[fileType].splice(key, 1);
      },
      queryConfigField() {
        this.$http.get('/api/website/delivery/config.json', {params: {
          websiteId: this.websiteId
        }}).then(data => {
           if(data) {
            this.configList = data || [];
          }
        });
      },
      // 处理页面滚动
      onScroll(e) {
        this.scrollY = e.target.scrollTop;
      },
      addExp(field, moduleId, obj, index) {
        let resumeForm = null;
        if(obj) {
          resumeForm = JSON.stringify(obj);
        }
        this.currentIndex = index || 0;
        let newResForm = {...this.resForm};
        local.setObject('resForm', newResForm);
        local.set('scrollY', this.scrollY);
        this.$router.push({
          name: field,
          query: {
            ...this.$route.query,
            currentIndex: this.currentIndex,
            resumeForm,
            moduleId,
            pathName: this.pathName
          }
        })
      },
      removeItems(field, key, moduleId) {
        if(field === 'CustomExp') {
          this.resForm.customModules[moduleId].splice(key, 1);
          this.$forceUpdate();
        } else {
          this.resForm[field].splice(key, 1);
        }
        local.setObject('resForm', this.resForm);
      },
      afterReadRes(data) {
        const uploadParams = new FormData();
        if(this.uccnInfo) {
          uploadParams.append('companyId', this.uccnInfo.companyId);
        } else if(this.jobCompanyId) {
          uploadParams.append('companyId', this.jobCompanyId);
        }
        uploadParams.append('source', 21);
        uploadParams.append('file', data.file);
        this.$http.post('/api/file/upload.json', uploadParams, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(data => {
          if(data) {
            this.resForm.resumeFileId = [{
              url: data.filePath,
              originName: data.fileName,
              id: data.id
            }];
            this.$refs.resForm.resetValidation('resumeFileId');
            Toast.success({
              message: '上传成功',
              duration: 3000
            });
          }
        })
      },
      onOversize() {
        Toast('文件大小不能超过 10MB');
      },
      queryRecommendStatus() {
        return new Promise((resolve, reject) => {
           this.$http.get('/api/website/apply/check.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId,
            personId: this.personId,
            mobile: this.resForm.resumeInfo.phone,
            email: this.resForm.resumeInfo.email,
            schoolAmbassadorCode: this.recomForm.schoolAmbassadorCode
          }}).then(data => {
            if(data === 1){
              Dialog.confirm({
                message: '仅能有一个职位在流程中，请谨慎选择',
                confirmButtonText: '确定投递'
              }).then(() => {
                resolve(true);
              }).catch(() => {
                resolve(false);
              });
            } else if(data === 2){
              Dialog.alert({
                message: '已投递其他职位，暂不可投递',
              }).then(() => {
                resolve(false);
              });
            } else {
              resolve(true);
            }
          });
        });
      },
      delField(arr, field) {
        if(arr && arr.length) {
          arr = JSON.stringify(arr.map(item => {
            delete item[field];
            return item;
          }));
          return arr;
        }
        return null
      },
      getCustomData(data, moduleId) {
        let fields = [];
        if(data.customFields && Object.keys(data.customFields).length) {
          Object.keys(data.customFields).forEach(key => {
            fields.push(data.customFields[key])
          });
        }
        delete data.customFields;
        if(fields.length) {
          return {
            moduleId,
            fields
          }
        } else {
          return null;
        }
      },
      queryModuleIdByCode(code) {
        const module = this.configList.find(item => item.moduleDto.moduleCode === code);
        return module && module.moduleId ? module.moduleId : '';
      },
      formatItemDate(data, moduleId) {
        if(!data || !data.length) return [];
        let standardExpArr = [];
        data.forEach(obj => {
          let item = Object.assign({}, obj);
          if(moduleId) {
            const customResult = this.getCustomData(this.$utils.deepClone(obj), moduleId);
            if(customResult) {
              item.customResult = customResult;
            }
          }
          delete item.dates;
          delete item.customFields;
          delete item.fileList;
          item = this.$utils.deleteKeys(item);
          if(Object.keys(item).length) {
            standardExpArr.push(item);
          }
        });
        return standardExpArr.length ? standardExpArr: [];
      },
      customMoudle() {
        let customContent = [], moduleIds = [];
        Object.keys(this.$refs).filter(key => key.indexOf('customMoudle') !== -1).forEach(key => {
          const moduleId = +key.replace('customMoudle-', '');
          if(this.$refs[key][0].resForm.expArr.length) {
            moduleIds.push(moduleId);
            this.$refs[key][0].resForm.expArr.slice(0, 1).forEach(item => {
              if(Object.keys(item).length) {
                let fields = [];
                Object.keys(item).forEach(field => {
                  fields.push(item[field]);
                });
                customContent.push({
                  moduleId,
                  fields
                });
              }
            });
          }
        });
        if(this.resForm.customModules && Object.keys(this.resForm.customModules).length) {
          Object.keys(this.resForm.customModules).forEach(moduleId => {
            const moduleList = this.resForm.customModules[moduleId];
            if(!moduleIds.includes(+moduleId) && moduleList.length) {
              moduleList.forEach(item => {
                if(Object.keys(item).length) {
                  let fields = [];
                  Object.keys(item).forEach(field => {
                    fields.push(item[field]);
                  });
                  customContent.push({
                    moduleId,
                    fields
                  });
                }
              });
            }
          });
        }
        return customContent;
      },
      onSave() {
        this.$refs.resForm.validate().then(async (valid) => {
          const newForm = this.$utils.deepClone(this.resForm);
          const dataMap = {
            'resumeInfo': {
              code: 1,
              name: 'resumeInfo'
            },
            'jobIntention': {
              code: 2,
              name: 'jobIntention'
            },
            'workExperience': {
              code: 3,
              name: 'workExperience'
            },
            'eduExperience': {
              code: 4,
              name: 'educationExperience'
            },
            'trainingExps': {
              code: 8,
              name: 'trainingExperience'
            },
            'projectExperience': {
              code: 5,
              name: 'projectExperience'
            },
            'languages': {
              code: 6,
              name: 'languageAbility'
            },
            'products': {
              code: 7,
              name: 'products'
            },
            'selfIntroduce': {
              code: 9,
              name: 'selfIntroduce'
            }
          };
          Object.keys(dataMap).forEach(key => {
            if(['resumeInfo', 'jobIntention', 'selfIntroduce'].includes(key)) {
              const customResult = this.getCustomData(newForm[key], this.queryModuleIdByCode(dataMap[key].code));
              newForm[dataMap[key].name] = this.$utils.formatDataArr(newForm[key]);
              if(key === 'jobIntention') {
                newForm[dataMap[key].name].salaryType = newForm[key].salaryType ? 2 : 1;
              }
              if(customResult) {
                newForm[dataMap[key].name].customResult = customResult;
              }
              newForm[dataMap[key].name] = JSON.stringify(newForm[dataMap[key].name]);
            } else {
              newForm[dataMap[key].name] = this.formatItemDate(newForm[key], this.queryModuleIdByCode(dataMap[key].code));
              newForm[dataMap[key].name] = JSON.stringify(newForm[dataMap[key].name]);
            }
            if(key !== dataMap[key].name) {
              delete newForm[key];
            }
          });
          newForm.customContent = JSON.stringify(this.customMoudle());
          delete newForm.customModules;
          local.setObject('resForm', newForm);
          let saveParams = this.$utils.deleteKeys(Object.assign({}, newForm, {
            optRecordId: this.optRecordId,
            websiteId: this.websiteId,
            companyId: this.uccnInfo ? this.uccnInfo.companyId : '',
            jobId: this.jobId,
            resLibraryId: this.resLibraryId,
            accountId: this.accountId,
            resumeFileId: (newForm.resumeFileId && newForm.resumeFileId.length) ? newForm.resumeFileId[0].id : '',
            careerTalkId: localStorage.getItem('careerTalkId'),
            schoolAmbassadorCode: this.recomForm.schoolAmbassadorCode,
            schoolInterviewSiteId: this.schoolInterviewSiteId,
          }));          
          if(this.websiteId < 0) {
            saveParams.companyId = this.jobCompanyId;
          }
          this.isLoading = true;
          if(this.pathName === 'ResumeAdd' && this.jobId) {
            // 招聘官网判断手机号简历与投递职位是否有过订单
            let recomResult = await this.queryJobRecommendStatus();
            if(!recomResult) {
              return;
            }
            let result = await this.queryRecommendStatus();
            if(!result) {
              return;
            }
          }
          let apiUrl = '';
          if(this.pathName === 'myResume') {
            saveParams.resumeId = this.resumeId;
            if(this.resVersionId) {
              saveParams.resVersionId = this.resVersionId;
              apiUrl = '/api/website/my/resume/update.json';
            } else {
              apiUrl = '/api/website/apply.json';
            }
          } else {
            if(this.personId) {
              saveParams.personId = this.personId;
            }
            apiUrl = '/api/website/apply.json';
          }
          this.$http.post(apiUrl, saveParams).then(data => {
            if(this.pathName === 'ResumeAdd') {
              Toast('投递成功');
              if(this.jobId) {
                // 投递成功页面
                this.$router.push({
                  name: 'deliverySuccess',
                  query: {
                    websiteId: this.websiteId,
                    jobId: this.jobId
                  }
                });
              } else {
                this.$router.push({
                  name: 'Home',
                  query: { id: this.uccnInfo.id },
                  params: { pathMatch: local.get('rootPath') || '' }
                });
              }
            } else {
              Toast('保存成功');
              this.isEdit = false;
              this.isDisabledForm = true;
            }
          }).finally(() => {
            this.isLoading = false;
            if(this.pathName === 'ResumeAdd') {
              localStorage.removeItem('careerTalkId');
            }
          });
        });
      },
      onFailed(obj) {
        if(obj.errors && obj.errors.length) {
          obj.errors.forEach(item => {
            if(!this.resForm.recomPhone && item.name === 'recomPhone') {
              this.$refs['resForm'].resetValidation('recomPhone');
            }
          });
        }
      }
    },
    watch: {
      pathName: {
        handler(val) {
          if(val){
            this.init();
          }
        },
        deep: true
      }
    }
  }
</script>