<template>
  <div class="item-resume-form-box">
    <el-form ref="resForm" :model="resForm" label-width="120px" class="item-resume-form item-upload resume-attachment-form">
      <div class="item-resume-form-title">
        <h4>附件/作品</h4>
        <div class="btns"><a href="javascript:;" @click="onAddItem"><i class="h5pp h5pp-icon-append"></i>添加</a></div>
      </div>
      <div v-for="(obj, index) in resForm.expArr" :key="index" class="item-resume-form-content" :class="{'more-form-content': resForm.expArr.length > 1}">
        <template v-for="(item, key) in filedsConfig">
          <el-form-item v-if="isFiledCode('name', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.name'">
            <el-input v-model.trim="obj.name" :maxLength="100" placeholder="请填写作品名称" />
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('explain', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.explain'">
            <el-input v-model.trim="obj.explain" :maxLength="200" placeholder="请填写作品说明" />
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('productId', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.fileList'" class="item-upload-product">
            <sub-upload
              :maxFileSize="50"
              :accept="acceptRes"
              :source="1"
              :limit="1"
              v-model="obj.fileList"
              @change="(file) => changeFileList(file, index)"
            >
            </sub-upload>
            <span v-if="!obj.fileList || !obj.fileList.length" class="size-tip">大小：≤50M</span>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('link', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.link'">
            <el-input v-model.trim="obj.link" placeholder="http://" />
          </el-form-item>
          <!-- 其他 -->
          <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="getItemData(obj, item.fieldId)" @update="updateField(index, $event)" />
        </template>
        <div v-if="resForm.expArr.length > 1" class="item-resume-trash">
          <a href="javascript:;" @click="onRemoveItem(index)"><i class="h5pp h5pp-icon-delete"></i>删除</a>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import './index.scss'
  import { Form, FormItem, Input, Upload, Button, Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
  import mixin from '../../mixin'
  import subUpload from '@/components/pc/upload'

  export default {
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Dropdown.name]: Dropdown,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      [Input.name]: Input,
      [Upload.name]: Upload,
      [Button.name]: Button,
      [Option.name]: Option,
      subUpload
    },
    props: {
      subResData: Array
    },
    mixins: [mixin],
    data() {
      return {
        acceptRes: '.pdf,.doc,.xls,.ppt,.docx,.pptx,.wps,.html,.jpg,.jpeg,.png,.rtf,.txt',
        isLoading: false,
        fileLength: 0,
        fileList: [],
        fileOverSize: false,
        isItemShow: {
          file: false,
          link: false
        },
        resForm: {
          expArr: []
        }
      }
    },
    methods: {
      onAddItem() {
        if(this.resForm.expArr.length >= 20) {
          this.$message.error('最多添加20条');
          return
        }
        const newItem = {
          productId: '',
          link: '',
          name: '',
          explain: '',
          // productId: '',
          // productLink: '',
          // productName: '',
          // productExplain: '',
          customFields: {}
        };
        this.resForm.expArr.unshift(newItem);
      },
      onRemoveItem(index) {
        this.resForm.expArr.splice(index, 1);
      },
      changeFileList(file, index) {
        if(file && file[0] && file[0].id) {
          if(this.resForm.expArr && this.resForm.expArr[index]) {
            this.resForm.expArr[index].productId = file[0].id;
            if(!this.resForm.expArr[index].name) {
              this.resForm.expArr[index].name = file[0].fileName;
            }
            this.$refs.resForm.clearValidate('expArr.' + index + '.fileList');
          }
        } else {
          if(this.resForm.expArr && this.resForm.expArr[index]) {
            this.resForm.expArr[index].fileList = [];
            this.resForm.expArr[index].productId = '';
          }
        }
        
      },
      handleChangeFiles(info) {
        if(this.fileOverSize) {
          return;
        }
        let fileList = [...info.fileList];
        this.fileList = [fileList[0]];
      },
      beforeUpload(file) { //标签上IE9不支持
        if(this.fileList.length > 0) {
          this.$message.error('每次只允许上传一个附件作品！');
          return false;
        }
        const isLt50M = file.size / 1024 / 1024 < 50;
        if(!isLt50M) {
          this.$message.error('上传失败，文件大小不能超过50M，请检查后重试');
          this.fileOverSize = true;
          return false;
        }
        return true;
      },
      removeFile(file) {
        this.fileList = [];
        // 设置参数为空
        this.resForm.expArr.forEach(obj => {
          if(obj.type === 1) {
            obj.productId = '';
            obj.link = '';
            obj.name = '';
          }
        });
      }
    },
    watch: {
      subResData: {
        handler(newVal, oldVal) {
          if(newVal && newVal.length) {
            this.resForm.expArr = [];
            newVal.forEach(item => {
              item.customFields = this.$utils.deepClone(item.fieldMap) || {};
              if(item.productId) {
                item.fileList = [{
                  fileName: item.name,
                  filePath: item.productId,
                  id: item.productId
                }];
              }
              this.resForm.expArr.push(item);
            });
          } else {
            !(this.resForm.expArr && this.resForm.expArr.length) && this.onAddItem();
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>