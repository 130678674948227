<template>
  <div class="h5-add-form-wrap">
    <sub-head-navs :hasRightMenus="pathName !== 'queueAddResume'" title="添加教育经历" :eventBack="true" @goBack="goBack"></sub-head-navs>
    <van-form class="add-form" ref="resumeFormRef" validate-trigger="onSubmit" scroll-to-error @submit="onConfirm">
      <template v-for="(item, key) in fieldsList">
        <van-field 
          v-if="isFiledCode('time', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model="resumeForm.dates" readonly clickable name="time" placeholder="请选择时间段" right-icon="arrow" @click="eduDateObj.isShow = true" />
        <van-field
          v-else-if="isFiledCode('graduateSchool', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model.trim="resumeForm.graduationSchool" :maxLength="50" placeholder="请输入" />
        <van-field
          v-else-if="isFiledCode('type', item)"
          readonly
          clickable
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          name="unifiedExamFlag"
          :value="resumeForm.unifiedExamFlag | NATIONALUNIFIED"
          right-icon="arrow"
          placeholder="请选择"
          @click="nationalUnifiedObj.isShow = true" />
        <van-field 
          v-else-if="isFiledCode('degree', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          readonly 
          clickable 
          name="degree" 
          :value="Degree[resumeForm.degree]"
          placeholder="请选择"
          right-icon="arrow"
          @click="degreeObj.isShow = true"
        />
        <van-field 
          v-else-if="isFiledCode('professional', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model.trim="resumeForm.major"
          placeholder="请输入"
        />
        <!-- 其他 -->
        <sub-custom-field v-else-if="item.isCheck && !['type'].includes(item.fieldDto.fieldCode)" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="getItemData(resumeForm, item.fieldId)" @update="updateField" />
      </template>
      <van-tabbar>
        <van-button class="btn" native-type="submit">确认添加</van-button>
      </van-tabbar>
    </van-form>   
    <popup-picker v-if="eduDateObj.isShow" :dialogData="eduDateObj" v-model="resumeForm.time" @success="onConfirmDate" />
    <!-- 学历 -->
    <popup-picker v-if="degreeObj.isShow" :dialogData="degreeObj" v-model="resumeForm.degree" />
    <popup-picker v-if="nationalUnifiedObj.isShow" v-model="resumeForm.unifiedExamFlag" :dialogData="nationalUnifiedObj" @success="onConfirmUnified"></popup-picker>
  </div>
</template>

<script>
  import '../workExp/index.scss'
  import mixin from '@/views/submodule/h5/resumeAdd/mixin'
  import { Form, RadioGroup, Radio, Field, Divider, Tabbar, Button } from 'vant'
  import { NationalUnified } from '@/enums/EnumBase'
  import { Degree, DegreeOption } from '@/enums/EnumResume'
  import subHeadNavs from '@/components/h5/headNavs'
  import popupPicker from '@/components/h5/dialog/picker'

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [Divider.name]: Divider,
      [Tabbar.name]: Tabbar,
      [Button.name]: Button,
      subHeadNavs,
      popupPicker,
    },
    mixins: [mixin],
    data() {
      return {
        NationalUnified,
        Degree,
        resumeForm: {
          startTimes: '',
          endTimes: '',
          graduationSchool: '',
          unifiedExamFlag: '',
          degree: '',
          professional: '',
          major: '',
        },
        nationalUnifiedObj: {
          isShow: false
        },
        eduDateObj: { isShow: false },
        degreeObj: {
          isShow: false,
        }
      }
    },
    created() {
      if(this.$route.query.resumeForm) {
        const item = JSON.parse(this.$route.query.resumeForm);
        Object.assign(this.resumeForm, {
          ...item,
          customFields: item.customFields ? item.customFields : item.fieldMap || {}
        });
      }
      if(this.$route.query.moduleId) {
        this.queryConfigField();
      }
      Object.assign(this.nationalUnifiedObj, {
        columns: Object.values(NationalUnified),
      });
      Object.assign(this.degreeObj, { columns: DegreeOption, key: 'name' });
      Object.assign(this.eduDateObj, { columns: this.initDate() });
    },
    computed: {
      pathName() {
        return this.$route.query.pathName;
      }
    },
    methods: {
      initDate() {
        let nowYear = new Date().getFullYear() + 2,
            yearArr = [];
        for(let index = nowYear; index >= 1950; index--) {
          yearArr.push(index);
        }
        return [
          {
            values: yearArr,
            defaultIndex: 0
          },
          {
            values: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            defaultIndex: 0
          },
          {
            values: yearArr,
            defaultIndex: 0
          },
          {
            values: ['至今', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            defaultIndex: 0
          }
        ]
      },
      onConfirmUnified(val, index) {
        this.resumeForm.unifiedExamFlag = index;
      },
      onConfirmDate(data, index) {
        this.resumeForm.dates = `${data[0]}/${data[1]}-${data[2]}/${data[3]}`;
        this.resumeForm.startYear = data[0];
        this.resumeForm.startMonth = data[1];
        this.resumeForm.endYear = data[2];
        this.resumeForm.endMonth = data[3];
        if(data[3] === '至今') {
          this.resumeForm.dates = `${data[0]}/${data[1]}-至今`;
          this.resumeForm.endYear = 9999;
          this.resumeForm.endMonth = 99;
        }
      },
      goBack() {
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'eduExperience',
            eduExperience: null
          }
        })
      },
      onConfirm() {
        this.resumeForm = Object.keys(this.$utils.deleteKeys(this.resumeForm)).length ? JSON.stringify(this.resumeForm) : null;
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'eduExperience',
            eduExperience: this.resumeForm
          }
        })
      }
    }
  }
</script>