<template>
  <van-popup v-model="dialogData.isShow" round position="bottom">
    <van-picker
      class="picker-reset-bg"
      :title="dialogData.title"
      show-toolbar
      :default-index="defaulIndex"
      :value-key="defaultKey"
      :columns="dialogData.columns || yearMonthList"
      @confirm="onConfirm"
      @cancel="dialogData.isShow = false"
    />
  </van-popup>
</template>

<script>
  import './index.scss'
  import { Picker, Popup } from 'vant'

  export default {
    components: {
      [Picker.name]: Picker,
      [Popup.name]: Popup
    },
    model: {
      prop: 'defaultValue',
      event: 'change'
    },
    props: {
      dialogData: Object,
      defaultValue: [Number, String]
    },
    computed: {
      defaulIndex() {
        let index = 0;
        if(this.dialogData.index) {
          index = this.dialogData.index;
        } else if(this.defaultValue) {
          index = (this.dialogData.columns || []).findIndex(item => (item.value || item.code || item.id) === this.defaultValue);
        }
        return index > 0 ? index : 0;
      },
      defaultKey() {
        return this.dialogData.key ? this.dialogData.key : 'text';
      },
      yearMonthList() {
        let nowYear = new Date().getFullYear(),
            yearArr = [];
        for(let index = nowYear; index >= 1950; index--) {
          yearArr.push(index);
        }
        return [
          {
            values: yearArr,
            defaultIndex: 0
          },
          {
            values: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            defaultIndex: 0
          },
          {
            values: yearArr,
            defaultIndex: 0
          },
          {
            values: ['至今', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            defaultIndex: 0
          }
        ]
      },
    },
    methods: {
      onConfirm(data, index) {
        this.$emit('change', data.value);
        this.$emit('success', data, index);
        this.dialogData.isShow = false;
      }
    }
  }
</script>