<template>
  <div class="item-resume-form-box">
    <el-form ref="resForm" :model="resForm" label-width="120px" class="item-resume-form resume-intention-form" @submit.native.prevent>
      <div class="item-resume-form-title">
        <h4>求职意向</h4>
      </div>
      <template v-for="(item, key) in filedsConfig">
        <!-- <el-form-item v-if="isFiledCode('name', item)" :label="getLabel(item)" prop="name" :rules="getRules(item)"> -->
        <el-form-item v-if="isFiledCode('expectCity', item)" :label="getLabel(item)" :rules="getRules(item)" prop="expectCity" class="item-select">
          <div class="select-data el-input__inner" @click="onSelectCity()">
            <i class="h5pp h5pp-icon-calendar"></i>
            <p class="select-placeholder" v-if="!resForm.expectCity || !resForm.expectCity.length">请选择期望城市</p>
            <ul class="selected-items el-sl" v-else>
              <li v-for="(item, key) in resForm.expectCity" :key="key" @click.stop="removeCity(key)" class="el-tag el-tag-checked">
                <span>{{ item | CITY }}</span>
                <i class="h5pp h5pp-icon-remove"></i>
              </li>
            </ul>
            <i class="h5pp h5pp-icon-briefcase"></i>
          </div>
        </el-form-item>
        <div v-else-if="isFiledCode('currentAnnualSalary', item)" class="current-salary">
          <el-form-item label="目前年薪(万)：" prop="currentSalary" class="half-item" :rules="getRules(item, [{ pattern: /^(0(\.\d{1,2})+|([1-9]\d{0,3})(\.\d{1,2})?)$/, message: '请输入最多2位小数的4位正数' }])">
            <el-input placeholder="请填写目前年薪" v-model.trim="resForm.currentSalary" :maxLength="7" suffix="万" />
          </el-form-item>
          <el-form-item prop="taxType" class="item-no-label half-item">
            <el-radio-group v-model="resForm.taxType">
              <el-radio :label="1">税前</el-radio>
              <el-radio :label="2">税后</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item v-else-if="isFiledCode('salaryRemark', item)" :label="getLabel(item)" prop="salaryRemark" :rules="getRules(item)">
          <el-input v-model.trim="resForm.salaryRemark" :maxLength="50" placeholder="奖金，补贴，提成，股票，期权等" />
        </el-form-item>
        <div v-else-if="isFiledCode('expectedAnnualSalary', item)" class="expect-salary">
          <el-form-item label="期望年薪(万)：" prop="expectSalary" class="half-item" :rules="[{ pattern: /^(0(\.\d{1,2})+|([1-9]\d{0,3})(\.\d{1,2})?)$/, message: '请输入最多2位小数的4位正数' }]">
            <el-input v-model.trim="resForm.expectSalary" :maxLength="7" :disabled="resForm.salaryType === 2" class="expect-salary-input" placeholder="请填写期望年薪" suffix="万" />
          </el-form-item>
          <el-form-item class="item-no-label half-item">
            <el-checkbox :value="resForm.salaryType === 2 ? true : false" @change="changeExpectSalaryType">面议</el-checkbox>
          </el-form-item>
        </div>
        <el-form-item v-else-if="isFiledCode('expectPosition', item)" :rules="getRules(item)" :label="getLabel(item)" prop="expectPosition" class="item-select">
          <div class="select-data el-input__inner" @click="onSelectFunction()">
            <i class="h5pp h5pp-icon-calendar"></i>
            <p class="select-placeholder" v-if="!resForm.expectPosition || !resForm.expectPosition.length">请选择期望职位</p>
            <ul class="selected-items el-sl" v-else>
              <li v-for="(item, key) in resForm.expectPosition" :key="key" @click.stop="removeFunction(key)" class="el-tag el-tag-checked">
                <span>{{ item | FUNCTION }}</span>
                <i class="h5pp h5pp-icon-remove"></i>
              </li>
            </ul>
            <i class="pinpin pp-icon-briefcase"></i>
          </div>
        </el-form-item>
        <el-form-item v-else-if="isFiledCode('expectIndustry', item)" :rules="getRules(item)" :label="getLabel(item)" prop="expectIndustry" class="item-select">
          <div class="select-data el-input__inner" @click="onSelectIndustry()">
            <i class="h5pp h5pp-icon-calendar"></i>
            <p class="select-placeholder" v-if="!resForm.expectIndustry || !resForm.expectIndustry.length">请选择期望城市</p>
            <ul class="selected-items el-sl" v-else>
              <li v-for="(item, key) in resForm.expectIndustry" :key="key" @click.stop="removeIndustry(key)" class="el-tag el-tag-checked">
                <span>{{ item | INDUSTRY }}</span>
                <i class="h5pp h5pp-icon-remove"></i>
              </li>
            </ul>
            <i class="pinpin pp-icon-briefcase"></i>
          </div>
        </el-form-item>
        <!-- 其他 -->
        <sub-custom-field v-else-if="item.isCheck && !['salaryType', 'taxType'].includes(item.fieldDto.fieldCode)" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="resForm.customFields[item.fieldId] || ''" @update="updateField" />
      </template>
    </el-form>
  </div>
</template>

<script>
  import './index.scss'
  import { Form, FormItem, Input, Button, Select, Option, DatePicker, Checkbox, Message, Radio, RadioGroup } from 'element-ui'
  import mixin from '../../mixin'

  export default {
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Message.name]: Message,
      [Radio.name]: Radio,
      [RadioGroup.name]: RadioGroup
    },
    props: {
      subResData: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    mixins: [mixin],
    data() {
      return {
        resForm: {
          expectCity: [],
          currentSalary: '',
          taxType: '',
          salaryRemark: '',
          expectSalary: '',
          salaryType: 1,
          expectPosition: [],
          expectIndustry: [],
          customFields: {}
        }
      }
    },
    methods: {
      initData(data) {
        Object.assign(this.resForm, {
          expectCity: data.expectCity || [],
          currentSalary: data.currentSalary || '',
          taxType: data.taxType || '',
          salaryRemark: data.salaryRemark || '',
          expectSalary: data.expectSalary || '',
          salaryType: data.salaryType || 1,
          expectPosition: data.expectPosition || [],
          expectIndustry: data.expectIndustry || [],
          customFields: this.$utils.deepClone(data.fieldMap) || {}
        })
        // 转化成数组
        if(typeof(data.expectCity) === String) {
          this.resForm.expectCity = typeof(data.expectCity) === String ? data.expectCity.split(',') : [];
          this.resForm.expectPosition = typeof(data.expectPosition) === String ? data.expectPosition.split(',') : [];
          this.resForm.expectIndustry = typeof(data.expectIndustry) === String ? data.expectIndustry.split(',') : [];
        }
      },
      updateField(data) {
        const dto = data.data.fieldDto;
        this.resForm.customFields[dto.id] = {
          fieldId: dto.id,
          fieldType: dto.type,
          value: dto.type > 3 ? JSON.stringify(data.value) : data.value
        }
      },
      // 期望年薪面议选择
      changeExpectSalaryType(val) {
        if(val) {
          this.resForm.expectSalary = '';
          this.resForm.salaryType = 2;
          this.$refs.resForm.clearValidate('expectSalary');
        } else {
          this.resForm.salaryType = 1;
        }
      },
      onSelectCity() {
        this.$area({
          max: 3,
          showLevel: 2,
          isShowHotCity: false,
          defaultVal: this.resForm.expectCity,
          callback: data => {
            this.resForm.expectCity = data;
            this.$refs.resForm.clearValidate('expectCity');
          }
        });
      },
      removeCity(key) {
        this.resForm.expectCity.splice(key, 1);
      },
      onSelectFunction() {
        this.$functions({
          max: 3,
          defaultVal: this.resForm.expectPosition,
          callback: data => {
            this.resForm.expectPosition = data;
            this.$refs.resForm.clearValidate('expectPosition');
          }
        });
      },
      removeFunction(key) {
        this.resForm.expectPosition.splice(key, 1);
      },
      onSelectIndustry() {
        this.$industry({
          max: 3,
          defaultVal: this.resForm.expectIndustry,
          callback: data => {
            this.resForm.expectIndustry = data;
            this.$refs.resForm.clearValidate('expectIndustry');
          }
        });
      },
      removeIndustry(key) {
        this.resForm.expectIndustry.splice(key, 1);
      }
    },
    watch: {
      subResData: {
        handler(newVal, oldVal) {
          if(newVal && Object.keys(newVal).length) {
            this.initData(newVal);
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>
