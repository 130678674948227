<template>
  <div class="h5-add-form-wrap">
    <sub-head-navs title="添加培训经历" :eventBack="true" @goBack="goBack"></sub-head-navs>
    <van-form class="add-form" ref="resumeFormRef" validate-trigger="onSubmit" scroll-to-error @submit="onConfirm">
      <template v-for="(item, key) in fieldsList">
        <van-field
          v-if="isFiledCode('time', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          name="dates"
          v-model="resumeForm.dates" readonly clickable placeholder="请选择" right-icon="arrow" @click="trainDateObj.isShow = true" />
        <van-field
          v-else-if="isFiledCode('trainingOrganization', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          name="trainingOrganization"
          v-model.trim="resumeForm.trainingOrganization" :maxLength="500" placeholder="请输入" />
        <van-field
          v-else-if="isFiledCode('trainingName', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          name="trainingName"
          v-model.trim="resumeForm.trainingName" :maxLength="500" placeholder="请输入" />
        <van-field
          v-else-if="isFiledCode('trainingPlace', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          clickable 
          name="trainingPlace" 
          v-model.trim="resumeForm.trainingPlace"
          placeholder="请输入"
        />
        <van-field
          v-else-if="isFiledCode('trainingCertificate', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          clickable 
          name="trainingCertificate" 
          v-model.trim="resumeForm.trainingCertificate"
          placeholder="请输入"
        />
        <van-field 
          v-else-if="isFiledCode('trainingDesc', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          clickable 
          name="trainingDesc"
          type="textarea"
          rows="4"
          autosize
          v-model.trim="resumeForm.trainingDesc"
          placeholder="请输入"
          :maxLength="500"
        />
        <!-- 其他 -->
        <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="getItemData(resumeForm, item.fieldId)" @update="updateField" />
      </template>
      <van-tabbar>
        <van-button class="btn" native-type="submit">确认添加</van-button>
      </van-tabbar>
    </van-form>
    <popup-picker v-if="trainDateObj.isShow" :dialogData="trainDateObj" v-model="resumeForm.dates" @success="onConfirmDate" />
  </div>
</template>

<script>
  import '../workExp/index.scss'
  import mixin from '@/views/submodule/h5/resumeAdd/mixin'
  import { Field, Form, Tabbar, Button } from 'vant'
  import subHeadNavs from '@/components/h5/headNavs'
  import popupPicker from '@/components/h5/dialog/picker'

  export default {
    components: {
      [Field.name]: Field,
      [Form.name]: Form,
      [Button.name]: Button,
      [Tabbar.name]: Tabbar,
      subHeadNavs,
      popupPicker,
    },
    mixins: [mixin],
    data() {
      return {
        resumeForm: {
          dates: '',
          trainingOrganization: '',
          trainingName: '',
          trainingPlace: '',
          trainingCertificate: '',
          trainingDesc: ''
        },
        trainDateObj: { isShow: false }
      }
    },
    created() {
      if(this.$route.query.resumeForm) {
        const item = JSON.parse(this.$route.query.resumeForm);
        Object.assign(this.resumeForm, {
          ...item,
          customFields: item.customFields ? item.customFields : item.fieldMap || {}
        });
      }
       if(this.$route.query.moduleId) {
        this.queryConfigField();
      }
    },
    methods: {
      onConfirmDate(data) {
        this.resumeForm.dates = `${data[0]}/${data[1]}-${data[2]}/${data[3]}`;
        this.resumeForm.startYear = data[0];
        this.resumeForm.startMonth = data[1];
        this.resumeForm.endYear = data[2];
        this.resumeForm.endMonth = data[3];
        if(data[3] === '至今') {
          this.resumeForm.dates = `${data[0]}/${data[1]}-至今`;
          this.resumeForm.endYear = 9999;
          this.resumeForm.endMonth = 99;
        }
      },
      goBack() {
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'trainingExps',
            trainingExps: null
          }
        })
      },
      onConfirm() {
        this.resumeForm = Object.keys(this.$utils.deleteKeys(this.resumeForm)).length ? JSON.stringify(this.resumeForm) : null;
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'trainingExps',
            trainingExps: this.resumeForm
          }
        })
      }
    }
  }
</script>