<template>
  <div>
    <h3>自我描述</h3>
    <template v-for="(item, key) in filedsConfig">
      <van-field 
        v-if="isFiledCode('selfIntroduce', item)"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item)"
        class="self-introduce"
        clickable 
        name="selfIntroduce"
        type="textarea"
        rows="4"
        autosize
        v-model.trim="resForm.selfIntroduce"
        placeholder="请输入自我介绍"
        :maxLength="2000"
      />
      <!-- 其他 -->
      <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="resForm.customFields[item.fieldId] || ''" @update="updateFieldSingle" />
    </template>
  </div>
</template>

<script>
  import { Field } from 'vant'
  import mixin from '../../mixin'

  export default {
    props: {
      resForm: {
        type: Object,
        default: () => {
          return {
            customFields: {}
          }
        }
      }
    },
    components: {
      [Field.name]: Field,
    },
    mixins: [mixin],
    watch: {
      resForm: {
        handler(val) {
          return val;
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>