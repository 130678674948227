<template>
  <div class="area-container">
    <van-tree-select
      :class="{'top-tree': !position}"
      :items="data"
      :active-id.sync="activeIds"
      :main-active-index.sync="activeIndex"
      :max="max"
      @click-nav="onClickNav"
      @click-item="onClickItem"
    />
    <div :class="{'popup-wrap': !position}">
      <div class="popup-btns-box van-hairline--top">
        <van-button type="default" block round @click="onCancel">取消</van-button>
        <van-button type="primary" block round @click="onSave">确定</van-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Button, TreeSelect, Toast } from 'vant'

  export default {
    components: {
      [Button.name]: Button,
      [TreeSelect.name]: TreeSelect
    },
    props: {
      paramsObj: Object,
      position: String,
    },
    data() {
      return {
        activeIds: [],
        activeIndex: 0,
        data: [],
        dataRepeat: {},
        showAll: false,
        chooseScope: 1, // 0: 都不显示 1:显示省 
        max: 1
      }
    },
    created() {
      if(this.paramsObj) {
        this.showAll = this.paramsObj.showAll ? this.paramsObj.showAll : this.showAll;
        this.max = this.paramsObj.max || this.max;
        this.activeIds = this.paramsObj.defaultVal || [];
      }
      this.init();
    },
    methods: {
      init() {
        this.data = this.$utils.deepClone(this.$local.getObject('locations'));
        this.dataRepeat = this.$utils.deepClone(this.$local.getObject('locationsObj'));
        
        this.activeIndex = 0;
        if(this.showAll) {
          this.data.unshift({ id: '', text: '不限', children: [] });
        }
        this.data.forEach((item, index) => {
          item.text = item.name || item.text;
          item.id = item.code || item.id;
          let flag = false;
          item.children.forEach(child => {
            child.text = child.name;
            child.id = child.code;
            if(this.activeIds.includes(child.code)) {
              flag = true;
              this.selectCode(child.code, false);
            }
          });
          if(this.chooseScope === 1 && item.id) {
            item.children.unshift(Object.assign({}, item, { 'text' : `全${item.name}`, level: 1 }));
            if(this.activeIds.includes(item.code)) {
              flag = true;
              this.selectCode(item.code, false);
            }
          }
          if(flag) {
            item.dot = true;
            if(!this.activeIndex) this.activeIndex = index;
          }
        });
      },
      onClickItem(data) {
        // 已选择 max
        if(!this.activeIds.includes(data.id) && this.activeIds.length >= this.max) {
          if(this.max === 1) {
            this.unSelectCode(this.activeIds[0]);
            this.activeIds = [data.id];
            this.selectCode(data.id);
            return;
          } else {
            this.clearParentOrChild(data.id);
            if(this.activeIds.length >= this.max) {
              return Toast(`最多选择${this.max}个`);
            } else {
              this.activeIds.push(data.id);
              this.selectCode(data.id);
              return;
            }
          }
        }
        this.clearParentOrChild(data.id);
        if(this.activeIds.includes(data.id)) {
          this.selectCode(data.id);
        } else {
          this.unSelectCode(data.id);
        }
      },
      onClickNav(index) {
        if(index === 0 && this.data[0].text === '不限') {
          this.activeIds.forEach(code => {
            this.unSelectCode(code);
          });
          this.activeIds = [];
        }
      },
      // 父子不共存
      clearParentOrChild(id) {
        const current = this.dataRepeat[id];
        let remain;
        // 清除 省级code
        if(current.parentCode) {
          remain = this.activeIds.filter(code => {
            if(code !== current.parentCode) {
              return true;
            } else {
              this.unSelectCode(code);
            }
          }) || [];
        // 清除 市级code
        } else {
          remain = this.activeIds.filter(code => {
            if(this.dataRepeat[code].parentCode !== id) {
              return true;
            } else {
              this.unSelectCode(code);
            }
          }) || [];
        }
        this.activeIds = remain;
      },
      selectCode(code, changeDot = true) {
        let current = this.dataRepeat[code];
        let parent = this.dataRepeat[current.parentCode];
        current.selected = true;
        if(parent) {
          if(!parent.total) {
            parent.total = 1;
          } else {
            parent.total += 1;
          }
        } else {
          current.total = 1;
        }
        if(changeDot) this.data[this.activeIndex].dot = true;
      },
      unSelectCode(code, changeDot = true) {
        let current = this.dataRepeat[code];
        let parent = this.dataRepeat[current.parentCode];
        current.selected = false;
        if(parent) {
          if(!parent.total) {
            parent.total = 0;
          } else {
            parent.total -= 1;
          }
        } else {
          current.total = 0;
        }
        if(changeDot) {
          if(parent) {
            const objItem = this.data.find(item => item.id === parent.code);
            objItem && (objItem.dot = parent.total > 0 ? true : false)
          } else {
            const objItem = this.data.find(item => item.id === current.code);
            objItem && (objItem.dot = false)
          }
        }
      },
      onSave() {
        this.$emit('ok', this.activeIds);
      },
      onCancel() {
        this.$emit('cancel');
      }
    }
  }
</script>