<template>
  <div class="item-resume-form-box">
    <el-form ref="resForm" :model="resForm" label-width="120px" class="item-resume-form resume-base-form">
      <div class="item-resume-form-title">
        <h4>基本信息</h4>
      </div>
      <template v-for="(item, key) in filedsConfig">
        <el-form-item v-if="isFiledCode('name', item)" :label="getLabel(item)" prop="name" :rules="getRules(item)" @submit.native.prevent>
          <el-input v-model="resForm.name" maxLength="8" placeholder="请填写真实姓名"></el-input>
        </el-form-item>
        <el-form-item v-else-if="isFiledCode('picture', item)" :label="getLabel(item)" prop="picture" :rules="getRules(item)" class="avatar-item">
          <el-upload
            v-if="!resForm.picture"
            ref="uploadRef"
            class="upload-resume"
            action
            :show-file-list="false"
            :http-request="onUploadRequest"
            :before-upload="beforeUpload">
            <a href="javascript:;" class="upload-btn">上传照片</a>
            <span class="file-size">格式：png、jpg、jpeg；大小：≤2M</span>
          </el-upload>
          <div v-else class="upload-resume-file">
            <img :src="resForm.picture" />
            <i class="h5pp h5pp-icon-close" @click.stop.prevent="removeFile"></i>
          </div>
        </el-form-item>
        <el-form-item v-else-if="isFiledCode('gender', item)" :label="getLabel(item)" prop="gender" :rules="getRules(item)" class="full-width">
          <el-radio-group v-model="resForm.gender" placeholder="请选择">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-else-if="isFiledCode('birthYear', item)" :label="getLabel(item)" prop="birthYear" :rules="getRules(item)" class="birth-year-item">
          <el-date-picker
            type="year" 
            v-model="resForm.birthYear"
            :clearable="true"
            placeholder="请选择出生年份" 
            format="yyyy" 
            value-format="yyyy"
            style="width: 100%"
            align="center"
            prefix-icon="_"
            :picker-options="pickerOptions">
          </el-date-picker>
          <i class="h5pp h5pp-icon-calendar"></i>
        </el-form-item>
        <el-form-item v-else-if="isFiledCode('workCity', item)" :label="getLabel(item)" prop="workCity" :rules="getRules(item)" class="item-select">
          <div class="select-data el-input__inner" @click="onSelectCity('workCity')">
            <i class="h5pp h5pp-icon-calendar"></i>
            <p class="select-placeholder" v-if="!resForm.workCity">请选择所在城市</p>
            <ul v-else class="selected-items">
              <li @click.stop="removeCity('workCity')" class="el-tag el-tag-checked">
                <span>{{ resForm.workCity | FULLCITY('pcd', '-', false) }}</span>
                <i class="h5pp h5pp-icon-remove" />
              </li>
            </ul>
          </div>
        </el-form-item>
        <el-form-item v-else-if="isFiledCode('phone', item)" :label="getLabel(item)" prop="phone" :rules="getRules(item)" class="item-check">
          <el-input v-model="resForm.phone" :maxLength="resForm.area === 2 ? 20 : 11" :disabled="isDisabledPhone" placeholder="请输入手机号"></el-input>
          <el-checkbox :value="resForm.area === 2 ? true : false" :disabled="isDisabledPhone" @change="onChangePhone">国外手机号</el-checkbox>
        </el-form-item>
        <!-- TODO -->
        <el-form-item v-else-if="isFiledCode('email', item)" :label="getLabel(item)" prop="email" :rules="getRules(item, [{ type: 'email', message: '邮箱格式不正确', trigger: 'blur' }])">
          <el-input v-model="resForm.email" :disabled="isDisabledEmail" placeholder="请填写邮箱"></el-input>
        </el-form-item>
        <el-form-item v-else-if="isFiledCode('wechatCode', item)" :label="getLabel(item)" prop="wechatCode" :rules="getRules(item, [{ min: 6, message: '微信号格式错误', trigger: 'blur' }])" >
          <el-input v-model.trim="resForm.wechatCode" :maxLength="20" placeholder="请输入微信号" />
        </el-form-item>
        <el-form-item v-else-if="isFiledCode('hometownCity', item)" :label="getLabel(item)" prop="hometownCity" :rules="getRules(item)" class="item-select">
          <div class="select-data el-input__inner" @click="onSelectCity('hometownCity')">
            <i class="h5pp h5pp-icon-calendar"></i>
            <p class="select-placeholder" v-if="!resForm.hometownCity">请选择户籍地址</p>
            <ul v-else class="selected-items el-sl">
              <li @click.stop="removeCity('hometownCity')" class="el-tag el-tag-checked">
                <span>{{ resForm.hometownCity | FULLCITY('pcd', '-', false) }}</span>
                <i class="h5pp h5pp-icon-remove" />
              </li>
            </ul>
          </div>
        </el-form-item>
        <el-form-item v-else-if="isFiledCode('workYears', item)" :label="getLabel(item)" prop="workYears" :rules="getRules(item)" class="item-select">
          <el-select v-model="resForm.workYears" placeholder="请选择工作年限" :getPopupContainer="triggerNode => {return triggerNode.parentNode || document.body}">
            <el-option v-for="(item, key) in WorkYears" :label="item" :value="+key" :key="key"></el-option>
          </el-select>
        </el-form-item>
        <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="resForm.customFields[item.fieldId] || ''" @update="updateField" :key="key" />
      </template>
      
    </el-form>
  </div>
</template>

<script>
  import './index.scss'
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import { WorkYears } from '@/enums/EnumBase'
  import { Form, FormItem, Input, Button, Select, Option, DatePicker, Radio, RadioGroup, Checkbox, Upload, Message } from 'element-ui'
  import mixin from '../../mixin'

  export default {
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Button.name]: Button,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Radio.name]: Radio,
      [RadioGroup.name]: RadioGroup,
      [Checkbox.name]: Checkbox,
      [Upload.name]: Upload,
      [Message.name]: Message
    },
    props: {
      subResData: {
        type: Object,
        default() {
          return {}
        }
      },
      isDisabledPhone: {
        type: Boolean,
        default: false
      },
      isDisabledEmail: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters({
        roleType: 'user/roleType',
        uccnInfo: 'uccnInfo'
      }),
      resumeListName() {
        return this.roleType === 3 ? 'lite-resume-list' : 'resume-list';
      }
    },
    mixins: [mixin],
    data() {
      return {
        isShowYear: false,
        WorkYears,
        resForm: {
          name: '',
          gender: 1,
          birthYear: '',
          workCity: '',
          phone: '',
          area: 1,
          email: '',
          wechatCode: '',
          hometownCity: '',
          picture: '',
          workYears: '',
          customFields: {}
        },
        fileList: [],
        // repeatResumeId: '',
        pickerOptions: {
          disabledDate: date => {
            return date.getTime() > new Date().getTime();
          }
        }
      }
    },
    created() {
      console.log('isDisabledPhone:', this.isDisabledPhone);
      console.log('isDisabledEmail:', this.isDisabledEmail);
    },
    methods: {
      initData(data) {
        Object.assign(this.resForm, {
          name: data.name,
          gender: data.gender,
          birthYear: data.birthYear ? String(data.birthYear) : '',
          workCity: data.workCity,
          phone: data.phone,
          area: data.area || 1,
          email: data.email,
          wechatCode: data.wechatCode,
          hometownCity: data.hometownCity,
          workYears: data.workYears > 12 ? 13 : data.workYears,
          picture: data.headUrl || '',
          customFields: this.$utils.deepClone(data.fieldMap) || {}
        });
      },
      updateField(data) {
        const dto = data.data.fieldDto;
        if(!this.resForm.customFields) {
          this.resForm.customFields = {};
        }
        this.resForm.customFields[dto.id] = {
          fieldId: dto.id,
          fieldType: dto.type,
          value: dto.type > 3 ? JSON.stringify(data.value) : data.value
        }
      },
      removeFile(file) {
        this.fileList = [];
        this.resForm.picture = '';
      },
      beforeUpload(file) {
        const isLt50M = file.size / 1024 / 1024 < 2;
        if(!isLt50M) {
          Message.error('上传失败，文件大小不能超过2M，请检查后重试');
          return false;
        }
        return true;
      },
      onUploadRequest(data) {
        this.isLoading = true;
        const formData = new FormData();
        if(this.uccnInfo) {
          formData.append('companyId', this.uccnInfo.companyId);
        }
        formData.append('source', 21);
        formData.append('file', data.file);
        this.$http.post('/api/file/upload.json', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          isShowLoading: true
        }).then(res => {
          if(res) {
            this.fileList = [res];
            this.resForm.picture = res.filePath;
            Message.success(`${data.file.name} 上传成功`);
          }
          this.isLoading = false;
        }).catch(res => {
          this.resForm.picture = '';
          this.fileList = [];
          this.isLoading = false;
        });
      },
      disabledDate(current) {
        return current && moment(current).year() > moment().endOf('year').year();
      },
      openChangeYear(status) {
        this.isShowYear = status;
      },
      panelChangeYear(value) {
        this.resForm.birthYear = moment(value, 'YYYY');
        this.isShowYear = false;
      },
      onSelectCity(field) {
        this.$area({
          max: 1,
          showLevel: 2,
          isShowHotCity: false,
          defaultVal: this.resForm[field] ? [this.resForm[field]] : '',
          callback: data => {
            this.resForm[field] = data[0];
            this.$refs.resForm.clearValidate(field);
          }
        });
      },
      removeCity(field) {
        this.resForm[field] = '';
      },
      onChangePhone(val) {
        this.resForm.area = val ? 2 : 1;
      },
      changePhoneArea(e) {
        this.resForm.area = e.target.checked ? 2 : 1;
      }
    },
    watch: {
      subResData: {
        handler(newVal, oldVal) {
          if(newVal && Object.keys(newVal).length) {
            this.initData(newVal);
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>