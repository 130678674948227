<template>
  <div class="h5-add-form-wrap">
    <sub-head-navs title="添加工作经历" :eventBack="true" @goBack="goBack"></sub-head-navs>
    <van-form class="add-form" ref="resumeFormRef" validate-trigger="onSubmit" scroll-to-error @submit="onConfirm">
      <template v-for="(item, key) in fieldsList">
        <van-field 
          v-if="isFiledCode('time', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model="resumeForm.dates" readonly clickable name="time" placeholder="请选择时间段" right-icon="arrow" @click="workDateObj.isShow = true" />
        <van-field 
          v-else-if="isFiledCode('companyName', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model.trim="resumeForm.company" :maxLength="50" placeholder="请输入" />
        <van-field 
          v-else-if="isFiledCode('positionName', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model.trim="resumeForm.jobName" :maxLength="50" placeholder="请输入" />
        <van-field 
          v-else-if="isFiledCode('companyIndustry', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          readonly 
          clickable 
          name="companyIndustry" 
          :value="resumeForm.companyIndustry | INDUSTRY"
          placeholder="请选择"
          right-icon="arrow"
          @click="companyIndustryObj.isShow = true"
        />
        <van-field
          v-else-if="isFiledCode('companySize', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          readonly 
          clickable 
          name="scale" 
          :value="Scale[resumeForm.scale]"
          placeholder="请选择"
          right-icon="arrow"
          @click="scaleObj.isShow = true"
        />
        <van-field
          v-else-if="isFiledCode('department', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model.trim="resumeForm.department" :maxLength="30" placeholder="请输入" />
        <van-field
          v-else-if="isFiledCode('demand', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model.trim="resumeForm.reportPerson" :maxLength="30" placeholder="请输入" />
        <van-field 
          v-else-if="isFiledCode('underNumber', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item, [{ validator: val => !val || /^(0|([1-9]\d{0,8}))$/.test(val), message: '请输入正数' }])"
          v-model.trim="resumeForm.underPerson" 
          type="number" 
          :maxLength="9" 
          placeholder="请输入"
        />
        <van-field 
          v-else-if="isFiledCode('companyIntroduce', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          clickable 
          name="companyIntroduce" 
          type="textarea"
          rows="4"
          autosize
          v-model.trim="resumeForm.companyIntroduce"
          placeholder="请输入"
          :maxLength="1000"
          @click="companyIntroduceObj.isShow = true"
        />
        <van-field 
          v-else-if="isFiledCode('workContent', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          clickable 
          name="workContent" 
          type="textarea"
          rows="4"
          autosize
          v-model.trim="resumeForm.workContent"
          placeholder="请输入"
          :maxLength="2000"
        />
        <van-field 
          v-else-if="isFiledCode('performance', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          clickable 
          name="workAchievement"
          type="textarea"
          rows="4"
          autosize
          v-model.trim="resumeForm.workAchievement"
          placeholder="请输入"
          :maxLength="2000"
        />
        <van-field 
          v-else-if="isFiledCode('leaveReason', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          clickable 
          name="dimissionReason"
          type="textarea"
          rows="4"
          autosize
          v-model.trim="resumeForm.dimissionReason"
          placeholder="请输入"
          :maxLength="200"
        />
        <van-field name="internshipFlag"
          v-else-if="isFiledCode('internshipFlag', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          class="not-cell intership-flag" label-class="left-text">
          <template #input>
            <van-switch v-model="resumeForm.internshipFlag" size="30" />
          </template>
        </van-field>
        <!-- 其他 -->
        <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="getItemData(resumeForm, item.fieldId)" @update="updateField" />
      </template>
      <van-tabbar>
        <van-button class="btn" native-type="submit">确认添加</van-button>
      </van-tabbar>
    </van-form>
    <!-- <popup-datetime-picker v-if="startTimeObj.isShow" :dialogData="startTimeObj" v-model="resumeForm.startTimes"/>
    <popup-datetime-picker v-if="endTimeObj.isShow" :dialogData="endTimeObj" v-model="resumeForm.endTimes"/> -->
    <!-- 公司行业  -->
    <popup-industry v-if="companyIndustryObj.isShow" :paramsObj="companyIndustryObj" @ok="onChangeCompanyIndustry" />
    <!-- 公司规模 -->
    <popup-picker v-if="scaleObj.isShow" :dialogData="scaleObj" v-model="resumeForm.scale" />
    <popup-picker v-if="workDateObj.isShow" :dialogData="workDateObj" v-model="resumeForm.time" @success="onConfirmDate" />
  </div>
</template>

<script>
  import './index.scss'
  import moment from 'moment'
  import mixin from '@/views/submodule/h5/resumeAdd/mixin'
  import { Field, Form, Switch, Tabbar, Button } from 'vant'
  import { Scale } from '@/enums/EnumResume'
  import subHeadNavs from '@/components/h5/headNavs'
  import popupDatetimePicker from  '@/components/h5/dialog/datetimePicker'
  import popupIndustry from '@/components/h5/industry'
  import popupPicker from '@/components/h5/dialog/picker'

  export default {
    components: {
      [Field.name]: Field,
      [Form.name]: Form,
      [Switch.name]: Switch,
      [Tabbar.name]: Tabbar,
      [Button.name]: Button,
      subHeadNavs,
      popupDatetimePicker,
      popupIndustry,
      popupPicker
    },
    mixins: [mixin],
    data() {
      return {
        Scale,
        resumeForm: {
          dates: '',
          checkedNow: '',
          companyName: '',
          jobName: '',
          companyIndustry: '',
          scale: '',
          department: '',
          reportPerson: '',
          underPerson: '',
          companyIntroduce: '',
          internshipFlag: false,
          workContent: '',
          workAchievement: '',
          dimissionReason: '',
        },
        workDateObj: { isShow: false },
        startTimeObj: {
          isShow: false,
          type: 'year-month',
          title: '选择年月',
          minDate: new Date(moment().year(moment().year() - 10)),
          maxDate: new Date()
        },
        endTimeObj: {
          isShow: false,
          type: 'year-month',
          title: '选择年月',
          minDate: new Date(moment().year(moment().year() - 10)),
          showNow: true
        },
        companyIndustryObj: {
          isShow: false,
          isPopup: true
        },
        scaleObj: {
          isShow: false,
        },
        companyIntroduceObj: {
          isShow: false,
          title: '公司介绍',
          maxLength: 1000,
          rows: 10
        },
        workContentObj: {
          isShow: false,
          title: '工作内容',
          maxLength: 2000,
          rows: 10
        },
        workAchievementObj: {
          isShow: false,
          title: '工作业绩',
          maxLength: 2000,
          rows: 10
        }
      }
    },
    created() {
      if(this.$route.query.resumeForm) {
        const item = JSON.parse(this.$route.query.resumeForm);
        Object.assign(this.resumeForm, {
          ...item,
          internshipFlag: JSON.parse(this.$route.query.resumeForm).internshipFlag ? true : false,
          customFields: item.customFields ? item.customFields : item.fieldMap || {}
        });
      }
      if(this.$route.query.moduleId) {
        this.queryConfigField();
      }
      let columns = [];
      Object.keys(Scale).forEach(item => columns.push({ name: Scale[item], value: +item }));
      Object.assign(this.scaleObj, { columns: columns, key: 'name' });
    },
    methods: {
      onChangeCompanyIndustry(val) {
        this.resumeForm.companyIndustry = val[0];
        Object.assign(this.companyIndustryObj, {
          defaultVal: val
        });
      },
      onConfirmDate(data) {
        this.resumeForm.dates = `${data[0]}/${data[1]}-${data[2]}/${data[3]}`;
        this.resumeForm.startYear = data[0];
        this.resumeForm.startMonth = data[1];
        this.resumeForm.endYear = data[2];
        this.resumeForm.endMonth = data[3];
        if(data[3] === '至今') {
          this.resumeForm.dates = `${data[0]}/${data[1]}-至今`;
          this.resumeForm.endYear = 9999;
          this.resumeForm.endMonth = 99;
        }
      },
      goBack() {
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'workExperience',
            workExperience: null
          }
        })
      },
      onConfirm() {
        Object.assign(this.resumeForm, {
          internshipFlag: +this.resumeForm.internshipFlag
        });
        this.resumeForm = Object.keys(this.$utils.deleteKeys(this.resumeForm)).length ? JSON.stringify(this.resumeForm) : null;
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'workExperience',
            workExperience: this.resumeForm
          }
        })
      }
    }
  }
</script>