<template>
  <div class="item-resume-form-box">
    <el-form ref="resForm" :model="resForm"  label-width="120px" class="item-resume-form item-upload" @submit.native.prevent>
      <div class="item-resume-form-title">
        <h4>附件简历</h4>
        <el-form-item label="简历附件：" prop="resumeFileId" :rules="[{ required: false, message: '请上传简历附件', trigger: 'change' }]">
          <el-upload
            v-if="!resForm.resumeFileId || !resForm.resumeFileId.length"
            ref="uploadRef"
            class="upload-resume"
            action
            :accept="acceptRes"
            :show-file-list="false"
            :http-request="onUploadRes"
            :before-upload="beforeFileUpload">
            <a href="javascript:;" class="upload-btn">上传简历</a>
          </el-upload>
          <ul v-else class="file-list">
            <li v-for="(item, key) in resForm.resumeFileId" :key="key">
              <p class="file-name el-sl">{{ item.originName }}</p>
              <i class="h5pp h5pp-icon-close" @click.stop="onItemsFile(key, 'resumeFileId')"></i>
            </li>
          </ul>
          <div v-if="!resForm.resumeFileId || !resForm.resumeFileId.length" class="upload-tips">支持pdf、doc、xls、ppt、docx、pptx、wps、html、jpg、jpeg、png、rtf、txt等简历格式</div>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Form, FormItem, Upload, Button, Message } from 'element-ui'
  import mixin from '../../mixin'

  export default {
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Upload.name]: Upload,
      [Button.name]: Button,
      [Message.name]: Message
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    props: {
      subResData: Array
    },
    mixins: [mixin],
    data() {
      return {
        acceptRes: '.pdf,.doc,.xls,.ppt,.docx,.pptx,.wps,.html,.jpg,.jpeg,.png,.rtf,.txt',
        acceptAttach: '.pdf,.doc,.xls,.ppt,.docx,.pptx,.wps,.html,.jpg,.jpeg,.png,.rtf,.txt,.rar,.zip',
        currentFileObj: null,
        resForm: {
          resumeFileId: [], //简历附件
        }
      }
    },
    created() {
      this.jobCompanyId = this.$route.query.jobCompanyId || '';
    },
    methods: {
      onUploadRes() {
        const uploadParams = new FormData();
        if(this.uccnInfo) {
          uploadParams.append('companyId', this.uccnInfo.companyId);
        } else if(this.jobCompanyId) {
          uploadParams.append('companyId', this.jobCompanyId);
        }
        uploadParams.append('source', 21);
        uploadParams.append('file', this.currentFileObj);
        this.$http.post('/api/file/upload.json', uploadParams, {
          headers: {'Content-Type': 'multipart/form-data'},
          isShowLoading: true
        }).then(data => {
          this.currentFileObj = null;
          if(data) {
            this.resForm.resumeFileId = [{
              url: data.filePath,
              originName: data.fileName,
              id: data.id
            }];
            this.$refs.resForm.clearValidate('resumeFileId');
          }
        }).catch(err => {
          Message.error(err || '上传失败，请重试');
        });
      },
       //上传图片前
      beforeFileUpload(file, index) {
        // 判断上传格式
        const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
        if(!this.acceptRes.includes(fileExtension)) {
          Message.error('上传附件不符合要求，请重试！');
          return false;
        }
        // 判断上传文件大小
        const fileSize = file.size / 1024 / 1024;
        if(fileSize > 10) {
          Message.error(`附件不能超过10M！`);
          return false;
        }
        this.currentFileObj = file;
        return true;
      },
      onItemsFile(key, fileType) {
        this.resForm[fileType].splice(key, 1);
      }
    },
    watch: {
      subResData: {
        handler(newVal, oldVal) {
          if(newVal && newVal.length) {
            this.resForm.resumeFileId = newVal;
            return newVal;
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>
