<template>
  <div class="upload-wrap">
    <ul slot="tip" class="el-upload-list">
      <li v-if="!onlyShow" :class="{'picture-btn': listType === 'picture'}">
        <el-upload
          v-if="((uploadFiles && uploadFiles.length < uploadLimit) || !uploadFiles)"
          ref="uploadRef"
          class="upload-form"
          action
          :accept="accept"
          :show-file-list="false"
          :http-request="onUpload"
          v-loading="loading"
          :before-upload="beforeFileUpload">
          <slot><el-button class="upload-btn">{{ btnText }}</el-button></slot>
        </el-upload>
      </li>
      <slot name="fileList">
        <template v-if="uploadFiles && uploadFiles.length">
          <li v-for="(item, key) in uploadFiles" :key="key">
            <div v-if="item.filePath" class="item-file">
              <a :href="item.filePath" target="_blank" class="file-name el-sl">
                <span>{{item.fileName}}</span>
              </a>
              <a v-if="!onlyShow" class="h5pp h5pp-icon-close" href="javascript:;" @click.stop="onRemoveFile(key)"></a>
            </div>
          </li>
        </template>
      </slot>
    </ul>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Toast } from 'vant'
  import { Upload, Button } from 'element-ui'

  export default {
    model: {
      prop: 'uploadFiles',
      event: 'change'
    },
    components: {
      [Upload.name]: Upload,
      [Button.name]: Button
    },
    props: {
      uploadFiles: Array,
      source: [Number, String],
      action: {
        type: String,
        default: '/api/file/upload.json'
      },
      accept: {
        type: String,
        default: '.pdf,.png,.jpg,.jpeg'
      },
      limit: {
        type: Number,
        default: 1
      },
      maxFileSize: {
        type: Number,
        default: 5
      },
      listType: {
        type: String,
        default: 'list' //list列表；picture-图片
      },
      onlyShow: {
        type: Boolean,
        default: false
      },
      reupload: { // uploadLimit === 1 是否替换上传
        type: Boolean,
        default: false
      },
      btnText: {
        type: String,
        default: '上传附件'
      }
    },
    data() {
      return {
        loading: false,
        currentFileObj: null
      }
    },
    computed: {
      uploadLimit() {
        return this.reupload && this.limit === 1 ? this.limit + 1 : this.limit;
      },
      ...mapGetters(['company', 'uccnInfo'])
    },
    methods: {
      onRemoveFile(key) {
        this.uploadFiles.splice(key, 1);
        this.$emit('change', this.uploadFiles);
      },
      //上传图片前
      beforeFileUpload(file, index) {
        this.loading = true;
        // 判断文件名
        if(file.name.length > 200) {
          Toast.fail('文件名称过长！');
          this.loading = false;
          return false;
        }
        if(/[?:&="';#/\\]/.test(file.name)) {
          Toast.fail('文件名称不能包含特殊字符');
          this.loading = false;
          return false;
        }
        // 判断上传数量
        if((this.uploadFiles || []).length >= this.uploadLimit) {
          Toast.fail(`最多可上传${this.uploadLimit}个！`);
          this.loading = false;
          return false;
        }
        // 判断上传格式
        const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
        //如果this.accept === 'all', 不判断格式
        if(!this.accept.includes(fileExtension) && this.accept !== 'all') {
          Toast.fail('上传附件不符合要求，请重试！');
          this.loading = false;
          return false;
        }
        // 判断上传文件大小
        const fileSize = file.size / 1024 / 1024;
        if(fileSize > this.maxFileSize) {
          Toast.fail(`附件不能超过${this.maxFileSize}M！`);
          this.loading = false;
          return false;
        }
        this.currentFileObj = file;
        return true;
      },
      onUpload() {
        let uploadParams = new FormData();
        uploadParams.append('source', this.source);
        uploadParams.append('file', this.currentFileObj);
        if(this.uccnInfo) {
          uploadParams.append('companyId', this.uccnInfo.companyId);
        }
        //  else if(this.jobCompanyId) {
        //   uploadParams.append('companyId', this.jobCompanyId);
        // }
        this.$http.post(this.action, uploadParams, {
          headers: {'Content-Type': 'multipart/form-data'},
          isShowLoading: true
        }).then(data => {
          this.loading = false;
          this.currentFileObj = null;
          if(data) {
            let _files = [...(this.uploadFiles || [])];
            if(this.reupload) {
              _files = [{
                filePath: data.filePath,
                fileName: data.fileName,
                id: data.id
              }]
            } else {
              _files.push({
                filePath: data.filePath,
                fileName: data.fileName,
                id: data.id
              });
            }
            this.$emit('change', _files);
          }
        }).catch(err => this.loading = false);
      },
      clearFiles() {
        this.$refs.uploadRef.clearFiles();
      }
    }
  }
</script>
