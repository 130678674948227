<template>
  <div class="item-resume-form-box">
    <el-form ref="resForm" :model="resForm" label-width="120px" class="item-resume-form resume-language-form" @submit.native.prevent>
      <div class="item-resume-form-title">
        <h4>语言能力</h4>
        <div class="btns"><a href="javascript:;" @click="onAddItem"><i class="h5pp h5pp-icon-append"></i>添加</a></div>
      </div>
      <div v-for="(obj, index) in resForm.expArr" :key="index" class="item-resume-form-content" :class="{'more-form-content': resForm.expArr.length > 1}">
        <template v-for="(item, key) in filedsConfig">
          <el-form-item v-if="isFiledCode('language', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.language'" >
            <el-select v-model="obj.language" placeholder="请选择语言类型">
              <el-option v-for="(item, code) in Language" :label="item" :value="+code" :key="code"></el-option>
            </el-select>
            <el-input v-if="obj.language === 3" class="lang-other-input" v-model.trim="obj.otherLanguage" :maxLength="100" placeholder="请填写"></el-input>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('proficiency', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.languageProficiency'" >
            <el-select v-model="obj.languageProficiency" placeholder="请选择熟练程度">
              <el-option v-for="(item, code) in Proficiency" :label="item" :value="+code" :key="code"></el-option>
            </el-select>
          </el-form-item>
          <!-- 其他 -->
          <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="getItemData(obj, item.fieldId)" @update="updateField(index, $event)" />
        </template>
        <div v-if="resForm.expArr.length > 1" class="item-resume-trash">
          <a href="javascript:;" @click="onRemoveItem(index)"><i class="h5pp h5pp-icon-delete"></i>删除</a>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import './index.scss'
  import { Proficiency, Language } from '@/enums/EnumResume'
  import { Form, FormItem, Input, Button, Select, Option, DatePicker, Checkbox, Message, Radio, RadioGroup, RadioButton } from 'element-ui'
  import mixin from '../../mixin'

  export default {
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Message.name]: Message,
      [Radio.name]: Radio,
      [RadioGroup.name]: RadioGroup,
      [RadioButton.name]: RadioButton
    },
    props: {
      subResData: {
        type: Array
      }
    },
    mixins: [mixin],
    data() {
      return {
        Proficiency,
        Language,
        resForm: {
          expArr: []
        }
      }
    },
    methods: {
      onAddItem() {
        if(this.resForm.expArr.length >= 20) {
          this.$message.error('最多添加20条');
          return
        }
        const newItem = {
          language: undefined,
          languageProficiency: undefined,
          customFields: {}
        };
        this.resForm.expArr.unshift(newItem);
      },
      onRemoveItem(index) {
        this.resForm.expArr.splice(index, 1);
      },
    },
    watch: {
      subResData: {
        handler(newVal, oldVal) {
          if(newVal && newVal.length) {
            if(!this.resForm.expArr.length) {
              this.resForm.expArr = [];
              newVal.forEach(item => {
                item.customFields = this.$utils.deepClone(item.fieldMap) || {};
                this.resForm.expArr.push(item);
              });
            }
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>