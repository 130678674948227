<template>
  <div class="h5-add-form-wrap">
    <sub-head-navs title="添加附件作品" :eventBack="true" @goBack="goBack"></sub-head-navs>
    <van-form class="add-form" ref="resumeFormRef" validate-trigger="onSubmit" scroll-to-error @submit="onConfirm">
      <template v-for="(item, key) in fieldsList">
        <van-field
          v-if="isFiledCode('name', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model.trim="resumeForm.name"
          name="name"
          :maxLength="100"
          placeholder="请输入"
        />
        <van-field
          v-else-if="isFiledCode('explain', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          v-model.trim="resumeForm.explain"
          name="explain"
          :maxlength="200"
          placeholder="请输入"  
        />
        <div v-else-if="isFiledCode('productId', item)" class="form-item-upload">
          <van-field
            :label="getLabel(item)"
            :required="!!item.isRequired"
            :rules="getRules(item)"
            class="not-cell upload"
            name="attachment"
            >
            <template #input>
              <van-uploader
                v-if="!resumeForm.fileList || !resumeForm.fileList.length"
                v-model="resumeForm.fileList"
                :max-count="1"
                :max-size="50 * 1024 * 1024"
                @oversize="onOversize"
                :accept="acceptRes"
                :after-read="afterReadRes">
                <div class="upload-btn"><i class="h5pp h5pp-icon-append"></i></div>
              </van-uploader>
              <ul v-else  class="file-list">
                <li v-for="(item, key) in resumeForm.fileList" :key="key">
                  <p class="file-name el-sl">{{ item.name || item.file.name }}</p>
                  <i class="h5pp h5pp-icon-delete" @click="onDelFile"></i>
                </li>
              </ul>
            </template>
          </van-field>
        </div>
        <van-field 
          v-else-if="isFiledCode('link', item)"
          :label="getLabel(item)"
          :required="!!item.isRequired"
          :rules="getRules(item)"
          name="linkUrl"
          v-model.trim="resumeForm.link"
          placeholder="添加链接"
        />
        <!-- 其他 -->
        <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="getItemData(resumeForm, item.fieldId)" @update="updateField" />
      </template>
      <van-tabbar>
        <van-button class="btn" native-type="submit">确认添加</van-button>
      </van-tabbar>
    </van-form>
  </div>
</template>

<script>
  import '../workExp/index.scss'
  import mixin from '@/views/submodule/h5/resumeAdd/mixin'
  import { Field, Form, Tabbar, Button, Uploader, Toast } from 'vant'
  import { mapGetters } from 'vuex'
  import subHeadNavs from '@/components/h5/headNavs'

  export default {
    components: {
      [Field.name]: Field,
      [Form.name]: Form,
      [Button.name]: Button,
      [Tabbar.name]: Tabbar,
      [Uploader.name]: Uploader,
      subHeadNavs,
    },
    mixins: [mixin],
    computed: {
      ...mapGetters(['uccnInfo']),
    },
    data() {
      return {
        resumeForm: {
          name: '',
          explain: '',
          productId: '',
          link: '',
        },
        acceptRes: 'image/png,image/jpg,image/jpeg,.pdf,.doc,.xls,.ppt,.docx,.pptx,.wps,.html,.rtf,.txt',
        fileOverSize: false
      }
    },
    created() {
      if(this.$route.query.resumeForm) {
        const item = JSON.parse(this.$route.query.resumeForm);
        Object.assign(this.resumeForm, {
          ...item,
          customFields: item.customFields ? item.customFields : item.fieldMap || {}
        });
        if(this.resumeForm.productId) {
          this.resumeForm.fileList = [{
            status: 'done',
            uid: this.resumeForm.productId,
            name: this.resumeForm.name,
            url: this.resumeForm.link
          }];
        }
      }
       if(this.$route.query.moduleId) {
        this.queryConfigField();
      }
    },
    methods: {
      goBack() {
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'products',
            products: null
          }
        })
      },
      onOversize() {
        Toast('文件大小不能超过 10MB');
      },
      onDelFile() {
        this.resumeForm.fileList = [];
        this.resumeForm.productId = '';
      },
      afterReadRes(data) {
        const uploadParams = new FormData();
        if(this.uccnInfo) {
          uploadParams.append('companyId', this.uccnInfo.companyId);
        } else if(this.jobCompanyId) {
          uploadParams.append('companyId', this.jobCompanyId);
        }
        uploadParams.append('source', 1);
        uploadParams.append('file', data.file);
        this.$http.post('/api/file/upload.json', uploadParams, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(data => {
          if(data) {
            this.resumeForm.productId = data.id;
            if(!this.resumeForm.productName) {
              this.resumeForm.productName = data.fileName;
            }
            // this.$refs.resForm.resetValidation('attachment');
            Toast.success({
              message: '上传成功',
              duration: 3000
            });
          }
        })
      },
      onConfirm() {
        this.resumeForm = Object.keys(this.$utils.deleteKeys(this.resumeForm)).length ? JSON.stringify(this.resumeForm) : null;
        this.$router.push({
          name: this.$route.query.pathName,
          query: {
            ...this.$route.query,
            field: 'products',
            products: this.resumeForm
          }
        })
      }
    }
  }
</script>