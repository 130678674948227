<template>
  <div class="item-resume-form-box">
    <el-form ref="resForm" :model="resForm" label-width="120px" class="item-resume-form resume-work-form" @submit.native.prevent>
      <div class="item-resume-form-title">
        <h4>工作经历</h4>
        <div class="btns"><a href="javascript:;" @click="onAddItem"><i class="h5pp h5pp-icon-append"></i>添加</a></div>
      </div>
      <div v-for="(obj, index) in resForm.expArr" :key="index" class="item-resume-form-content" :class="{'more-form-content': resForm.expArr.length > 1}">
        <template v-for="(item, key) in filedsConfig">
          <!-- 选择日期范围&至今 -->
          <el-form-item v-if="isFiledCode('time', item)" :label="getLabel(item)" :prop="'expArr.' + index + '.yearMonths'" :rules="getRules(item, [{ validator: (rule, value, callback) => {
            if(!item.isRequired) {callback()} else if(!value[0]) {callback('请选择开始月份')} else if(!obj.checkedNow && !value[1]) { callback('请选择结束月份') } else { callback() }
          }, trigger: 'blur' }])" class="item-dates-range">
            <el-date-picker 
              type="month" 
              v-model="obj.yearMonths[0]" 
              placeholder="开始月份" 
              format="yyyy.MM"
              value-format="yyyy.MM" 
              align="center"
              prefix-icon="h5pp h5pp-icon-calendar"
              @change="onChangeDate($event, index, 'start')" />
            <p class="item-date-line">-</p>
            <el-date-picker 
              v-model="obj.yearMonths[1]" 
              type="month" 
              :placeholder="obj.checkedNow ? '至今' : '结束月份'" 
              format="yyyy.MM"
              value-format="yyyy.MM" 
              align="center"
              prefix-icon="h5pp h5pp-icon-calendar"
              :disabled="obj.checkedNow" 
              @change="onChangeDate($event, index, 'end')" />
            <el-checkbox class="item-checked-now" v-model="obj.checkedNow" @change="onCheckNow($event, index)">至今</el-checkbox>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('companyName', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.company'">
            <el-input v-model.trim="obj.company" :maxLength="50" placeholder="请填写公司名称" />
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('positionName', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.jobName'">
            <el-input v-model.trim="obj.jobName" :maxLength="50" placeholder="请填写岗位名称" />
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('companyIndustry', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.companyIndustry'">
            <div class="select-data el-input__inner" @click="onSelectIndustry(index)">
              <i class="h5pp h5pp-icon-calendar"></i>
              <p v-if="!obj.companyIndustry" class="select-placeholder">请选公司行业</p>
              <ul v-else class="selected-items el-sl">
                <li @click.stop="removeIndustry(index)" class="el-tag el-tag-checked">
                  <span>{{ obj.companyIndustry | INDUSTRY }}</span>
                  <i class="h5pp h5pp-icon-remove"></i>
                </li>
              </ul>
              <i class="pinpin pp-icon-briefcase"></i>
            </div>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('companySize', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.scale'">
            <el-select v-model="obj.scale" placeholder="请选择公司规模" :getPopupContainer="triggerNode => {return triggerNode.parentNode || document.body}" @change="$forceUpdate()">
              <el-option v-for="(item, key) in Scale" :label="item" :value="+key" :key="key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('department', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.department'">
            <el-input v-model.trim="obj.department" :maxLength="30" placeholder="请填写所属部门" />
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('demand', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.reportPerson'">
            <el-input v-model.trim="obj.reportPerson" :maxLength="30" placeholder="请填写汇报对象" />
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('underNumber', item)" :label="getLabel(item)" :rules="getRules(item, [{ pattern: /^(0|([1-9]\d{0,8}))$/, message: '请输入正数' }])" :prop="'expArr.' + index + '.underPerson'">
            <el-input v-model.trim="obj.underPerson" :maxLength="9" placeholder="请填写下属人数" />
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('companyIntroduce', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.companyIntroduce'" class="item-resume-textarea">
            <el-input type="textarea" :maxLength="1000" v-model="obj.companyIntroduce" placeholder="请填写公司介绍" />
            <div class="textarea-word-limit">{{ (obj.companyIntroduce || '').length }}/1000</div>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('workContent', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.workContent'" class="item-resume-textarea">
            <el-input type="textarea" :maxLength="2000" v-model="obj.workContent" placeholder="请填写工作内容" />
            <div class="textarea-word-limit">{{ (obj.workContent || '').length }}/2000</div>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('performance', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.workAchievement'" class="item-resume-textarea">
            <el-input type="textarea" :maxLength="2000" v-model="obj.workAchievement" placeholder="请填写工作业绩" />
            <div class="textarea-word-limit">{{ (obj.workAchievement || '').length }}/2000</div>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('leaveReason', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.dimissionReason'" class="item-resume-textarea">
            <el-input type="textarea" :maxLength="200" v-model="obj.dimissionReason" placeholder="请填写离职原因" />
            <div class="textarea-word-limit">{{ (obj.dimissionReason || '').length }}/200</div>
          </el-form-item>
          <el-form-item v-else-if="isFiledCode('internshipFlag', item)" :label="getLabel(item)" :rules="getRules(item)" :prop="'expArr.' + index + '.internshipFlag'" class="has-margin-top">
            <el-switch :active-value="1" :inactive-value="0" v-model="obj.internshipFlag" />
          </el-form-item>
          <!-- 其他 -->
          <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="getItemData(obj, item.fieldId)" @update="updateField(index, $event)" />
        </template>
        <div v-if="resForm.expArr.length > 1" class="item-resume-trash">
          <a href="javascript:;" @click="onRemoveItem(index)"><i class="h5pp h5pp-icon-delete"></i>删除</a>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { Scale } from '@/enums/EnumResume'
  import { Form, FormItem, Input, Button, Select, Option, DatePicker, Checkbox, Switch, Message } from 'element-ui'
  import mixin from '../../mixin'

  export default {
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Checkbox.name]: Checkbox,
      [Switch.name]: Switch,
      [Button.name]: Button,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Message.name]: Message,
    },
    props: {
      subResData: {
        type: Array
      },
      resumeId: {
        type: [String, Number]
      }
    },
    mixins: [mixin],
    data() {
      return {
        Scale,
        resForm: {
          expArr: []
        }
      }
    },
    methods: {
      onAddItem() {
        if(this.resForm.expArr.length >= 20) {
          this.$message.error('最多添加20条');
          return
        }
        const newItem = {
          yearMonths: [],
          startYear: '',
          startMonth: '',
          endYear: '',
          endMonth: '',
          checkedNow: false,
          companyName: '',
          jobName: '',
          companyIndustry: '',
          scale: undefined,
          department: '',
          reportPerson: '',
          underPerson: '',
          companyIntroduce: '',
          internshipFlag: false,
          workContent: '',
          workAchievement: '',
          dimissionReason: '',
        };
        this.resForm.expArr.unshift(newItem);
      },
      onRemoveItem(index) {
        this.resForm.expArr.splice(index, 1);
      },
      onChangeDate(val, key, field) {
        if(!val) {
          this.resForm.expArr[key][`${field}Year`] = '';
          this.resForm.expArr[key][`${field}Month`] = '';
        } else {
          this.resForm.expArr[key][`${field}Year`] = val.split('.')[0];
          this.resForm.expArr[key][`${field}Month`] = val.split('.')[1];
        }
      },
      onCheckNow(val, key) {
        if(val) {
					this.resForm.expArr[key].endYear = 9999;
          this.resForm.expArr[key].endMonth = 99;
				} else {
          this.resForm.expArr[key].endYear = '';
          this.resForm.expArr[key].endMonth = '';
        }
        this.resForm.expArr[key].yearMonths = [this.resForm.expArr[key].yearMonths[0], ''];
        this.$refs.resForm.clearValidate('expArr.' + key + '.yearMonths');
      },
      onSelectIndustry(index) {
        this.$industry({
          max: 1,
          defaultVal: this.resForm.expArr[index].companyIndustry ? [this.resForm.expArr[index].companyIndustry] : [],
          callback: data => {
            this.resForm.expArr[index].companyIndustry = data[0];
            this.$forceUpdate();
            this.$refs.resForm.clearValidate('expArr.' + index + '.companyIndustry');
          }
        });
      },
      removeIndustry(index) {
        this.resForm.expArr[index].companyIndustry = '';
        this.$forceUpdate();
      }
    },
    watch: {
      subResData: {
        handler(newVal, oldVal) {
          if(newVal && newVal.length) {
            this.resForm.expArr = [];
            newVal.forEach(item => {
              item.scale = +item.scale || undefined;
              item.customFields = this.$utils.deepClone(item.fieldMap) || {};
              if(item.endYear === 9999) {
                item.checkedNow = true;
              }
              this.resForm.expArr.push(item);
            });
          } else {
            !(this.resForm.expArr && this.resForm.expArr.length) && this.onAddItem();
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>
