<template>
  <div>
    <h3>基本信息</h3>
    <template v-for="(item, key) in filedsConfig">
      <van-field
        v-if="isFiledCode('name', item)"
        v-model.trim="resForm.name"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item)"
        :maxlength="30"
        placeholder="请输入姓名"
      />
      <div v-else-if="isFiledCode('picture', item)" class="form-item-upload">
        <van-field :label="getLabel(item)"
          name="picture"
          class="not-cell"
          :required="!!item.isRequired"
          :rules="getRules(item)">
          <template #input>
            <div v-if="resForm.picture" class="img-box">
              <img :src="resForm.picture" />
            </div>
            <van-uploader
              :class="{'re-upload-box': resForm.picture}"
              v-model="fileList"
              :max-count="2"
              :max-size="2 * 1024 * 1024"
              :deletable="false"
              :preview-image="false"
              accept=".png,.jpg,.jpeg"
              @oversize="onOversize"
              :after-read="afterReadRes">
              <div v-if="!resForm.picture" class="upload-btn"><i class="h5pp h5pp-icon-append" /></div>
              <a v-else href="javascript:;" class="re-upload">重新上传</a>
            </van-uploader>
          </template>
        </van-field>
        <p class="upload-tips">格式：png、jpg、jpeg；大小：≤2M</p>
      </div>
      <van-field
        v-else-if="isFiledCode('gender', item)"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item)"
        readonly
        clickable
        name="gender"
        :value="resForm.gender | GENDER"
        right-icon="arrow"
        placeholder="请选择"
        @click="showGender = true"
      />
      <van-field
        v-else-if="isFiledCode('birthYear', item)"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item)"
        readonly 
        clickable 
        name="birthYear" 
        :value="resForm.birthYear"
        placeholder="请选择"
        right-icon="arrow"
        @click="birthYearObj.isShow = true"
      />
      <van-field 
        v-else-if="isFiledCode('workCity', item)"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item)"
        readonly 
        clickable 
        name="workCity" 
        :value="resForm.workCity | CITYARR"
        placeholder="请选择"
        right-icon="arrow"
        @click="workCityObj.isShow = true"
      />
      <div v-else-if="isFiledCode('phone', item)" class="form-item-phone">
        <van-field
          :value="PhoneArea[resForm.area]"
          label="手机号" placeholder="+86"
          readonly
          clickable
          @click="showPhoneArea = true"
          class="phone-area"
          :class="{'is-required': !!item.isRequired }" />
        <van-popup v-model="showPhoneArea" position="bottom" round>
          <van-picker show-toolbar :columns="Object.values(PhoneArea)" @confirm="onConfirmPhoneArea" @cancel="showPhoneArea = false" />
        </van-popup>
        <van-field
          v-model.trim="resForm.phone"
          :required="!!item.isRequired"
          :disabled="isDisabledPhone"
          name="phone"
          placeholder="请输入手机号"
          :maxlength="resForm.area === 2 ? 20:11"
          :rules="getRules(item, [{ validator: validatePhone, message: '手机号格式不正确' }])"
          class="phone-content" />
      </div>
      <van-field 
        v-else-if="isFiledCode('email', item)"
        :label="getLabel(item)"
        :disabled="isDisabledEmail"
        :required="!!item.isRequired"
        :rules="getRules(item, [{ validator: emailValidator, message: '邮箱格式不正确' }])"
        v-model.trim="resForm.email"
        maxlength="30"
        placeholder="请输入"  
      />
      <van-field 
        v-else-if="isFiledCode('wechatCode', item)"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item, [{ validator: val => !val || val.length >= 6 , message: '微信号格式错误' }])"
        v-model.trim="resForm.wechatCode" 
        maxlength="20" 
        placeholder="请输入" 
      />
      <van-field 
        v-else-if="isFiledCode('hometownCity', item)"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item)"
        readonly 
        clickable 
        name="hometownCity" 
        :value="resForm.hometownCity | CITYARR"
        placeholder="请选择"
        right-icon="arrow"
        @click="hometownCityObj.isShow = true"
      />
      <van-field 
        v-else-if="isFiledCode('workYears', item)"
        :label="getLabel(item)"
        :required="!!item.isRequired"
        :rules="getRules(item)"
        readonly 
        clickable 
        name="workYears" 
        :value="WorkYears[resForm.workYears] || ''"
        placeholder="请选择"
        right-icon="arrow"
        @click="workYearsObj.isShow = true"
      />
      <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :fieldData="item" :itemData="resForm.customFields[item.fieldId] || ''" @update="updateFieldSingle" :key="key" />
    </template>
    <!-- 所在城市 -->
    <popup-area v-if="workCityObj.isShow" :paramsObj="workCityObj" @ok="onChangeWorkCity" />
    <!-- 出生年份 -->
    <popup-datetime-picker v-if="birthYearObj.isShow" :dialogData="birthYearObj" v-model="resForm.birthYear"/>
    <!-- 户籍地址 -->
    <popup-area v-if="hometownCityObj.isShow" :paramsObj="hometownCityObj" @ok="onChangeHometownCity" />
    <van-popup v-model="showGender" position="bottom" round>
      <van-picker class="datetime-picker-reset-bg" show-toolbar :columns="Object.values(GenderType)" @confirm="onConfirmGender" @cancel="showGender = false" />
    </van-popup>
    <!-- 工作年限 -->
    <van-popup v-model="workYearsObj.isShow" position="bottom" round>
      <van-picker class="datetime-picker-reset-bg" show-toolbar :columns="Object.values(WorkYears)" @confirm="onConfirmSelect" @cancel="workYearsObj.isShow = false" />
    </van-popup>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import mixin from '../../mixin'
  import { Field, Divider, Form, Picker, Popup, Toast, Uploader } from 'vant'
  import { PhoneArea, GenderType, WorkYears } from '@/enums/EnumBase'
  import popupArea from '@/components/h5/area'
  import popupDatetimePicker from '@/components/h5/dialog/datetimePicker'
  import popupPicker from '@/components/h5/dialog/picker'

  export default {
    components: {
      [Field.name]: Field,
      [Divider.name]: Divider,
      [Form.name]: Form,
      [Picker.name]: Picker,
      [Popup.name]: Popup,
      [Uploader.name]: Uploader,
      popupArea,
      popupDatetimePicker,
      popupPicker
    },
    props: {
      isDisabledPhone: {
        type: Boolean,
        default: false
      },
      isDisabledEmail: {
        type: Boolean,
        default: false
      },
      resForm: {
        type: Object,
        default: () => {
          return {}
        }
      },
      showRequired: {
        type: Boolean,
        default: false
      }
    },
    mixins: [mixin],
    data() {
      return {
        GenderType,
        PhoneArea,
        WorkYears,
        showGender: false,
        showPhoneArea: false,
        workCityObj: {
          isShow: false
        },
        hometownCityObj: {
          isShow: false
        },
        birthYearObj: {
          isShow: false,
          title: '选择年份',
          type: 'year',
          // 确认最小年份
          minDate: new Date(moment().year(moment().year() - 80)),
          maxDate: new Date()
        },
        fileList: [],
        workYearsObj: {
          isShow: false,
          title: '工作年限'
        }
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
    },
    methods: {
      onConfirmSelect(picker, index) {
        this.resForm.workYears = index;
        this.workYearsObj.isShow = false;
      },
      afterReadRes(data) {
        this.isLoading = true;
        const formData = new FormData();
        if(this.uccnInfo) {
          formData.append('companyId', this.uccnInfo.companyId);
        }
        formData.append('source', 21);
        formData.append('file', data.file);
        this.$http.post('/api/file/upload.json', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          isShowLoading: true
        }).then(res => {
          if(res) {
            this.fileList = [res];
            this.resForm.picture = res.filePath;
            Toast.success(`上传成功`);
            this.$forceUpdate();
          }
          this.isLoading = false;
        }).catch(res => {
          this.resForm.picture = '';
          this.fileList = [];
          this.isLoading = false;
        });
      },
      onOversize() {
        Toast('文件大小不能超过 2MB');
      },
      removeFile() {
        this.resForm.picture = '';
        this.fileList = [];
      },
      validatePhone(value) {
        if((this.resForm.area === 1 && !/^1\d{10}$/.test(value)) || !/^[\(\)\+\-\d]{11,20}$/.test(value)) {
          return false;
        }
        return true;
      },
      emailValidator(val) {
        return !val || /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(val);
      },
      onConfirmGender(picker, index) {
        this.resForm.gender = index;
        this.showGender = false;
      },
      onConfirmPhoneArea(picker, index) {
        this.resForm.area = index+1;
        this.showPhoneArea = false;
      },
      onChangeWorkCity(val) {
        this.resForm.workCity = val;
        Object.assign(this.workCityObj, {
          defaultVal: val
        });
      },
      onChangeHometownCity(val) {
        this.resForm.hometownCity = val;
        Object.assign(this.hometownCityObj, {
          defaultVal: val
        });
      }
    },
    watch: {
      resForm: {
        handler(val) {
          return val;
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>