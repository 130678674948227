<template>
  <div class="industry-container">
    <van-tree-select
      :items="data"
      :active-id.sync="activeIds"
      :main-active-index.sync="activeIndex"
      :max="max"
      @click-nav="onClickNav"
      @click-item="onClickItem"
    />
    <div class="popup-btns-box van-hairline--top">
      <van-button type="default" block round @click="onCancel">取消</van-button>
      <van-button type="primary" block round @click="onSave">确定</van-button>
    </div>
  </div>
</template>

<script>
  import { Button, TreeSelect, Toast } from 'vant'

  export default {
    components: {
      [Button.name]: Button,
      [TreeSelect.name]: TreeSelect
    },
    props: {
      paramsObj: Object
    },
    data() {
      return {
        activeIds: [],
        activeIndex: 0,
        data: [],
        dataRepeat: {},
        showAll: false,
        max: 1,
      }
    },
    created() {
      if(this.paramsObj) {
        this.showAll = this.paramsObj.showAll ? this.paramsObj.showAll : this.showAll;
        this.max = this.paramsObj.max || this.max;
        this.activeIds = this.paramsObj.defaultVal || [];
      }
      this.init();
    },
    methods: {
      init() {
        this.data = this.$utils.deepClone(this.$local.getObject('industrys'));
        this.dataRepeat = this.$utils.deepClone(this.$local.getObject('industrysObj'));
        if(this.showAll) {
          this.data.unshift({ id: '', text: '不限', children: [] });
        }
        this.data.forEach((item, index) => {
          item.text = item.name || item.text;
          item.id = item.code || item.id;
          let flag = false;
          item.children.forEach(child => {
            child.text = child.name;
            child.id = child.code;
            if(this.activeIds.includes(child.code)) {
              flag = true;
              this.selectCode(child.code, false);
            }
          });
          if(flag) {
            item.dot = true;
            if(!this.activeIndex) this.activeIndex = index;
          }
        });
      },
      onClickItem(data) {
        if(!this.activeIds.includes(data.id) && this.activeIds.length >= this.max) {
          if(this.max === 1) {
            this.unSelectCode(this.activeIds[0]);
            this.activeIds = [data.id];
            this.selectCode(data.id);
            return;
          } else {
            return Toast(`最多选择${this.max}个`);
          }
        }
        if(this.activeIds.includes(data.id)) {
          this.selectCode(data.id);
        } else {
          this.unSelectCode(data.id);
        }
      },
      onClickNav(index) {
        if(index === 0 && this.data[0].text === '不限') {
          this.activeIds.forEach(code => {
            this.unSelectCode(code);
          });
          this.activeIds = [];
        }
      },
      selectCode(code, changeDot = true) {
        let current = this.dataRepeat[code];
        let parent = this.dataRepeat[current.parentCode];
        current.selected = true;
        if(!parent.total) {
          parent.total = 1;
        } else {
          parent.total += 1;
        }
        if(changeDot) this.data[this.activeIndex].dot = true;
      },
      unSelectCode(code, changeDot = true) {
        let current = this.dataRepeat[code];
        let parent = this.dataRepeat[current.parentCode];
        current.selected = false;
        if(!parent.total) {
          parent.total = 0;
        } else {
          parent.total -= 1;
        }
        if(changeDot && parent) {
          const objItem = this.data.find(item => item.id === parent.code);
          objItem && (objItem.dot = parent.total > 0 ? true : false)
        }
      },
      onSave() {
        this.$emit('ok', this.activeIds);
      },
      onCancel() {
        this.$emit('cancel');
      }
    }
  }
</script>