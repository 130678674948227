<template>
   <van-popup class="functions-sheet-wrap" v-model="dialogData.isShow" round position="bottom">
    <van-cascader
      :value="defaultValue"
      :options="catagoryIdoptions"
      active-color="#3380FF"
      @close="dialogData.isShow = false"
      @finish="onFinish"/>
  </van-popup>
</template>

<script>
  import './index.scss'
  import { Cascader, Popup } from 'vant'
  import subRefreshList from '../refreshList'

  export default {
    components: {
      [Popup.name]: Popup,
      [Cascader.name]: Cascader,
      subRefreshList
    },
    model: {
      prop: 'defaultValue',
    },
    props: {
      dialogData: Object,
      defaultValue: [Number, String]
    },
    data() {
      return {
        catagoryIdoptions: []
      }
    },
    created() {
      let data = this.$local.getObject('functions') || [];
      let newData = this.filterData(data);
      let newArr = [];
      newData.forEach(item => {
        if(item.code < 100) {
          newArr.push(item);
        }
      });
      this.catagoryIdoptions = newArr;
    },
    methods: {
      filterData(data) {
        data.forEach((ele) => {
          ele.text = ele.name;
          ele.value = ele.code;
          if(ele.children && ele.children.length) {
            this.filterData(ele.children);
          }
        })
        return data;
      },
      onFinish({ selectedOptions }) {
        this.dialogData.isShow = false;
        this.$emit('success', selectedOptions);
      }
    }
  }
</script>

