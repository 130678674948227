import subCustomField from '@/views/submodule/h5/guide/submodule/customField'

export default {
  components: {
    subCustomField
  },
  props: {
    personId: [String, Number],
    configFields: {
      type: [Object],
      default: () => {}
    },
    filedsConfig: {
      type: Array,
      default() {
        return []
      }
    },
    moduleId: Number
  },
  data() {
    return {
      configList: [],
      fieldsList: [],
      moduleData: {
        moduleDto: {}
      }
    }
  },
  methods: {
    queryConfigField() {
      const pathName = this.$route.query.pathName;
      let apiUrl = '/api/manual/guide/regist/config.json',
        params = { guideRegistId: pathName };
      this.$guideHttp.get(apiUrl, { params }).then(data => {
        if(data) {
          this.configList = data.moduleConfigList || [];
          if(this.$route.query.moduleId) {
            const module = this.configList.find(item => item.moduleId === +this.$route.query.moduleId);
            this.moduleData = module;
            this.fieldsList = module && module.fields ? module.fields : [];
          }
        }
      });
    },
    getRules(fields, rules = []) {
      if(fields.isRequired) {
        return [{ required: true, message: `请${this.actionName(fields.fieldDto.type)}${fields.fieldDto.name}`, trigger: 'blur' }].concat(rules);
      }
      return rules;
    },
    actionName(type) {
      if([1, 2].includes(type)) {
        return '输入';
      } else if(type === 6) {
        return '上传';
      } else {
        return '选择';
      }
    },
    getLabel(fields) {
      return fields.fieldDto.name + '：';
    },
    isFiledCode(code, data) {
      // if(data.fieldDto && data.fieldDto.fieldCode === code) return true;
      if(data.isCheck && data.fieldDto && data.fieldDto.fieldCode === code) return true;
      return false;
    },
    getItemData(obj, id) {
      if(obj && obj.customFields && obj.customFields[id]) {
        return this.$utils.deepClone(obj.customFields[id]);
      }
      return '';
    },
    updateField(data) {
      const dto = data.data.fieldDto;
      if(!this.resumeForm.customFields) {
        this.resumeForm.customFields = {};
      }
      this.resumeForm.customFields[dto.id] = {
        fieldId: dto.id,
        fieldType: dto.type,
        value: dto.type > 3 ? JSON.stringify(data.value) : data.value
      }
    },
    updateFieldSingle(data) {
      const dto = data.data.fieldDto;
      if(!this.resForm.customFields) {
        this.resForm.customFields = {};
      }
      this.resForm.customFields[dto.id] = {
        fieldId: dto.id,
        fieldType: dto.type,
        value: dto.type > 3 ? JSON.stringify(data.value) : data.value
      }
    },
  }
}