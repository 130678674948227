<template>
  <el-form ref="customForm" :model="customForm" label-width="120px" class="custom-field-form" @submit.native.prevent>
    <!-- 长文本 -->
    <el-form-item v-if="isShowField(1)" :label="labelName" prop="content" :rules="rules" class="item-resume-textarea">
      <span slot="label">
        <el-tooltip effect="dark" :content="labelName" placement="top-start">
          <div class="lable-name">{{ labelName }}</div>
        </el-tooltip>
      </span>
      <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      <el-input type="textarea" :maxLength="2000" v-model="customForm.content" placeholder="请填写" @change="onUpdate"/>
      <div class="textarea-word-limit">{{ (customForm.content || '').length }}/2000</div>
    </el-form-item>
    <!-- 短文本 -->
    <el-form-item v-else-if="isShowField(2)" :label="labelName" prop="text" :rules="rules">
      <span slot="label">
        <el-tooltip effect="dark" :content="labelName" placement="top-start">
          <div class="lable-name">{{ labelName }}</div>
        </el-tooltip>
      </span>
      <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      <el-input v-model="customForm.text" :maxLength="50" placeholder="请填写" @change="onUpdate" />
    </el-form-item>
    <!-- 是否题 -->
    <el-form-item v-else-if="isShowField(3)" :label="labelName" prop="flag" :rules="rules">
      <span slot="label">
        <el-tooltip effect="dark" :content="labelName" placement="top-start">
          <div class="lable-name">{{ labelName }}</div>
        </el-tooltip>
      </span>
      <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      <el-radio-group v-model="customForm.flag" @change="onUpdate" class="radio-gender">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="0">否</el-radio>
      </el-radio-group>
    </el-form-item>
    <!-- 单选 -->
    <el-form-item v-else-if="isShowField(4)" :label="labelName" prop="singleSelect" :rules="rules">
      <span slot="label">
        <el-tooltip effect="dark" :content="labelName" placement="top-start">
          <div class="lable-name">{{ labelName }}</div>
        </el-tooltip>
      </span>
      <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      <el-select v-model="customForm.singleSelect" placeholder="请选择" @change="onUpdate" :getPopupContainer="triggerNode => {return triggerNode.parentNode || document.body}">
        <el-option v-for="(item, key) in singleList" :label="item.name" :value="item.id" :key="key"></el-option>
      </el-select>
      <el-input v-if="customForm.singleSelect && singleList.find(i => i.id === customForm.singleSelect) && singleList.find(i => i.id === customForm.singleSelect).otherFlag" class="other-input" v-model="customForm.otherContent" @change="onUpdate" :maxLength="100" placeholder="请填写"></el-input>
      <el-input v-else-if="customForm.singleSelect && singleList.find(i => i.id === customForm.singleSelect) && singleList.find(i => i.id === customForm.singleSelect).linkBlank" class="other-input" v-model="customForm.otherContent" @change="onUpdate" :maxLength="100" placeholder="请填写"></el-input>
    </el-form-item>
    <!-- 多选 -->
    <el-form-item v-else-if="isShowField(5)" :label="labelName" prop="multipleSelect" :rules="rules">
      <span slot="label">
        <el-tooltip effect="dark" :content="labelName" placement="top-start">
          <div class="lable-name">{{ labelName }}</div>
        </el-tooltip>
      </span>
      <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      <el-select multiple v-model="customForm.multipleSelect" @change="onUpdate" dropdownClassName="custom-multi-select" not-found-content="暂无数据" option-filter-prop="label" option-label-prop="label" placeholder="请选择" :getPopupContainer="triggerNode => {return triggerNode.parentNode || document.body}">
        <el-option v-for="(item, key) in singleList" :label="item.name" :value="item.id" :key="+key"></el-option>
      </el-select>
      <el-input v-if="customForm.multipleSelect && singleList.some(i => customForm.multipleSelect.includes(i.id) && i.otherFlag)" class="other-input" v-model="customForm.otherContent" @change="onUpdate" :maxLength="100" placeholder="请填写"></el-input>
    </el-form-item>
    <!-- 附件 -->
    <el-form-item v-else-if="isShowField(6)" :label="labelName" prop="fileId" :rules="rules">
      <span slot="label">
        <el-tooltip effect="dark" :content="labelName" placement="top-start">
          <div class="lable-name">{{ labelName }}</div>
        </el-tooltip>
      </span>
      <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      <el-upload
        v-if="!customForm.fileId"
        ref="uploadRef"
        class="upload-resume"
        action
        :show-file-list="false"
        :http-request="onUploadRequest"
        :before-upload="beforeUpload">
        <a href="javascript:;" class="upload-btn">上传附件</a>
        <span class="file-size">支持文档、图片、压缩包、视频、音频等格式文件，且单份文件小于50MB</span>
      </el-upload>
      <a v-else href="javascript:;" class="upload-resume-file">
        <span>{{ fileList[0].fileName }}</span>
        <i class="h5pp h5pp-icon-close" @click.stop.prevent="removeFile"></i>
      </a>
    </el-form-item>
    <!-- 时间选择 年 月 日 -->
    <el-form-item v-else-if="isShowField(7)" :label="labelName" prop="selectTimes" :rules="rules" class="custom-time-picker">
      <span slot="label">
        <el-tooltip effect="dark" :content="labelName" placement="top-start">
          <div class="lable-name">{{ labelName }}</div>
        </el-tooltip>
      </span>
      <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      <el-date-picker v-if="isTimeType(1)" v-model="customForm.selectTimes" type="year" :clearable="true" format="yyyy" value-format="yyyy" align="center" prefix-icon="_" placeholder="请选择" @change="onUpdate"></el-date-picker>
      <el-date-picker v-else-if="isTimeType(2)" v-model="customForm.selectTimes" type="month" :clearable="true" format="yyyy.MM" value-format="yyyyMM" align="center" prefix-icon="_" placeholder="请选择" @change="onUpdate"></el-date-picker>
      <el-date-picker v-else-if="isTimeType(3)" v-model="customForm.selectTimes" type="date" :clearable="true" format="yyyy.MM.dd" value-format="yyyyMMdd" align="center" prefix-icon="_" placeholder="请选择" @change="onUpdate"></el-date-picker>
      <i class="h5pp h5pp-icon-calendar"></i>
    </el-form-item>
    <!-- 省市区详细 -->
    <el-form-item v-else-if="isShowField(8)" :label="labelName" prop="addressForm" :rules="rules">
      <span slot="label">
        <el-tooltip effect="dark" :content="labelName" placement="top-start">
          <div class="lable-name">{{ labelName }}</div>
        </el-tooltip>
      </span>
      <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      <el-row :gutter="10" type="flex" justify="start" class="address-row">
        <el-col :span="addressSpan">
          <el-select placeholder="省" v-model="customForm.addressForm.province" @change="updateCity" :getPopupContainer="triggerNode => {return triggerNode.parentNode || document.body}">
            <el-option v-for="(item, key) in addressArr" :label="item.name" :value="item.code" :key="key"></el-option>
          </el-select>
        </el-col>
        <el-col v-if="!isCityType(1)" :span="addressSpan">
          <el-select placeholder="市" v-model="customForm.addressForm.city" @change="updateDistrict" :getPopupContainer="triggerNode => {return triggerNode.parentNode || document.body}">
            <el-option v-for="(item, key) in cityArr" :label="item.name" :value="item.code" :key="key"></el-option>
          </el-select>
        </el-col>
        <el-col v-if="isCityType(3) || isCityType(4)" :span="addressSpan">
          <el-select placeholder="区" v-model="customForm.addressForm.district" class="district" @change="updatedValue" :getPopupContainer="triggerNode => {return triggerNode.parentNode || document.body}">
            <el-option v-for="(item, key) in districtArr" :label="item.name" :value="item.code" :key="key"></el-option>
          </el-select>
        </el-col>
        <el-col v-if="isCityType(4)" :span="9">
          <el-input placeholder="请输入详细地址" :maxLength="100" v-model="customForm.addressForm.address" @change="updateAddress()" />
        </el-col>
      </el-row>
    </el-form-item>
    <!-- 时间段 -->
    <el-form-item v-else-if="isShowField(9)" :label="labelName" prop="dates" :rules="rules" class="half-item">
      <span slot="label">
        <el-tooltip effect="dark" :content="labelName" placement="top-start">
          <div class="lable-name">{{ labelName }}</div>
        </el-tooltip>
      </span>
      <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      <el-date-picker
        v-if="isTimeType(3)" 
        type="daterange"
        :clearable="true"
        v-model="customForm.dates"
        range-separator="-"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        format="yyyy.MM.dd"
        value-format="yyyyMMdd"
        align="center"
        prefix-icon="h5pp h5pp-icon-calendar"
        @change="onChangeDate" />
      <el-date-picker
        v-else
        type="monthrange"
        :clearable="true"
        v-model="customForm.dates"
        range-separator="-"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        format="yyyy.MM"
        value-format="yyyy.MM"
        prefix-icon="h5pp h5pp-icon-calendar"
        @change="onChangeDate" />
    </el-form-item>
  </el-form>
</template>

<script>
  import './index.scss'
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import { Form, FormItem, Input, Button, Select, Option, Upload, DatePicker, Radio, RadioGroup, Checkbox, Message, Row, Col, Tooltip } from 'element-ui'
  import popupBigImg from '@/components/pc/dialog/bigImg'

  export default {
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Button.name]: Button,
      [Select.name]: Select,
      [Option.name]: Option,
      [Upload.name]: Upload,
      [DatePicker.name]: DatePicker,
      [Radio.name]: Radio,
      [RadioGroup.name]: RadioGroup,
      [Checkbox.name]: Checkbox,
      [Row.name]: Row,
      [Col.name]: Col,
      [Tooltip.name]: Tooltip,
      [Message.name]: Message,
      popupBigImg
    },
    props: {
      fieldData: Object,
      itemData: [Object, String]
    },
    data() {
      return {
        isLoading: false,
        customForm: {
          text: '',
          content: '',
          singleSelect: undefined,
          flag: '',
          multipleSelect: [],
          dates: '',
          startTimes: '',
          endTimes: '',
          checkedNow: false,
          selectTimes: '',
          otherContent: '',
          addressForm: {
            province: undefined,
            city: undefined,
            district: undefined,
            address: '',
          },
          fileId: ''
        },
        addressArr: this.$store.state.locations,
        cityArr: [],
        districtArr: [],
        addressList: [],
        singleList: [],
        fileList: [],
        rules: [],
        currentFileObj: '',
        // exampleImg: 'https://hy-awa-ats.oss-cn-hangzhou.aliyuncs.com/BOffer/bf11eeaf7680b941be211dc2ca80ee28.jpeg',
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      labelName() {
        return this.fieldData.fieldDto.name + '：';
      },
      isShowField() {
        return (type) => {
          if(this.fieldData && this.fieldData.fieldDto && this.fieldData.fieldDto.type && this.fieldData.fieldDto.type === type) return true;
          return false;
        }
      },
      isTimeType() {
        return (val) => {
          if(this.fieldData && this.fieldData.fieldDto && this.fieldData.fieldDto.timeType && this.fieldData.fieldDto.timeType === val) return true;
          return false;
        }
      },
      addressSpan() {
        if(this.fieldData && this.fieldData.fieldDto && this.fieldData.fieldDto.cityType) {
          if(this.fieldData.fieldDto.cityType === 4) {
            return 5;
          } else {
            return Math.floor(24/this.fieldData.fieldDto.cityType);
          }
        } else {
          return 0;
        }
      },
      isCityType() {
        return (val) => {
          if(this.fieldData && this.fieldData.fieldDto && this.fieldData.fieldDto.cityType && this.fieldData.fieldDto.cityType === val) return true;
          return false;
        }
      },
      exampleImg() {
        return this.fieldData && this.fieldData.fieldDto && this.fieldData.fieldDto.fileUrls ? this.fieldData.fieldDto.fileUrls[0] || '' : '';
      }
    },
    created() {
      let triggerAction = ['blur', 'change'];
      if(this.fieldData.isRequired) {
        if([7, 9].includes(this.fieldData.fieldDto.type)) {
          triggerAction = 'change';
        }
        if(this.fieldData.fieldDto.type === 8) {
          this.rules = [{ required: true, message: `请${this.actionName(this.fieldData.fieldDto.type)}${this.fieldData.fieldDto.name}`, trigger: triggerAction }, { validator: this.validAddress, trigger: triggerAction }]
        } else {
          this.rules = [{ required: true, message: `请${this.actionName(this.fieldData.fieldDto.type)}${this.fieldData.fieldDto.name}`, trigger: triggerAction }]
        }
      }
      if(this.fieldData.fieldDto.type == 2 && this.fieldData.fieldDto.restrictionRules === 2) {
        this.rules.push({ validator: this.validNumber, trigger: 'blur' })
      }
      if(this.fieldData.fieldDto.type == 2 && this.fieldData.fieldDto.restrictionRules === 3) {
        this.rules.push({ validator: this.validWord, trigger: 'blur' })
      }
      if([4, 5].includes(this.fieldData.fieldDto.type)) {
        this.singleList = this.fieldData.fieldDto.itemDtos || [];
      }
    },
    methods: {
      onChangeDate(vals) {
        if(vals && vals.length > 0) {
          this.customForm.startTimes = vals[0];
          this.customForm.endTimes = vals[1];
        } else {
          this.customForm.startTimes = '';
          this.customForm.endTimes = '';
        }
        this.onUpdate();
      },
      updateCity(value) {
        this.customForm.addressForm.province = value;
        this.customForm.addressForm.city = undefined;
        this.customForm.addressForm.district = undefined;
        if(this.isCityType(1)) {
          this.$refs.customForm.validateField('addressForm');
        } else {
          this.getCity();
        }
        this.onUpdate();
      },
      updateDistrict(value) {
        this.customForm.addressForm.city = value;
        this.customForm.addressForm.district = undefined;
        if(this.isCityType(2)) {
          this.$refs.customForm.validateField('addressForm');
        } else {
          this.getDistrict();
        }
        this.onUpdate();
      },
      updatedValue(value) {
        this.customForm.addressForm.district = value;
        if(this.isCityType(3)) {
          this.$refs.customForm.validateField('addressForm');
        }
        this.onUpdate();
      },
      updateAddress() {
        this.$refs.customForm.validateField('addressForm');
        this.onUpdate();
      },
      queryAddress() {
        this.$http.get('/api/company/workspace/list.json').then(res => {
          if(res) {
            this.addressList = res.data || [];
          }
        })
      },
      getCity() {
        for (var i in this.addressArr) {
          var obj = this.addressArr[i];
          if (obj.code == this.customForm.addressForm.province) {
            this.cityArr = obj.children;
            break;
          }
        }
        this.districtArr = [];
      },
      getDistrict() {
        for (var i in this.cityArr) {
          var obj = this.cityArr[i];
          if (obj.code == this.customForm.addressForm.city) {
            this.districtArr = obj.children;
            break;
          }
        }
      },
      beforeUpload(file) {
        const isLt50M = file.size / 1024 / 1024 < 50;
        if(!isLt50M) {
          Message.error('上传失败，文件大小不能超过50M，请检查后重试');
          return false;
        }
        this.currentFileObj = file;
        return true;
      },
      onUploadRequest(data) {
        // if(!this.beforeUpload(data.file)) return;
        this.isLoading = true;
        const formData = new FormData();
        if(this.uccnInfo) {
          formData.append('companyId', this.uccnInfo.companyId);
        }
        formData.append('source', 21);
        formData.append('file', data.file);
        this.$http.post('/api/file/upload.json', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          isShowLoading: true
        }).then(res => {
          if(res) {
            this.fileList = [res];
            this.customForm.fileId = res.id;
            this.onUpdate();
            Message.success(`${data.file.name} 上传成功`);
          }
          this.isLoading = false;
        }).catch(res => {
          this.customForm.fileId = '';
          this.fileList = [];
          this.isLoading = false;
        });
      },
      removeFile() {
        this.customForm.fileId = '';
        this.fileList = [];
      },
      onUpdate() {
        switch(this.fieldData.fieldDto.type) {
          case 1:
            this.$emit('update', { data: this.fieldData, value: this.customForm.content });
            break;
          case 2:
            this.$emit('update', { data: this.fieldData, value: this.customForm.text });
            break;
          case 3:
            this.$emit('update', { data: this.fieldData, value: this.customForm.flag });
            break;
          case 4:
            this.$emit('update', { data: this.fieldData, value: {
              itemId: this.customForm.singleSelect, 
              name: this.singleList.find(item => item.id === this.customForm.singleSelect).name,
              other: this.customForm.otherContent 
            }});
            break;
          case 5:
            let result = [];
            this.customForm.multipleSelect.forEach(id => {
              const item = this.singleList.find(item => item.id === id);
              const resItem = {
                itemId: item.id,
                name: item.name,
                englishDesc: item.englishDesc,
              };
              if(item.otherFlag) {
                resItem.other = this.customForm.otherContent;
              }
              result.push(resItem);
            });
            this.$emit('update', { data: this.fieldData, value: result });
            break;
          case 6:
            this.$emit('update', { data: this.fieldData, value: this.fileList || [] });
            break;
          case 7:
            this.$emit('update', { data: this.fieldData, value: { timeValue: this.formatTime(this.customForm.selectTimes), timeType: this.fieldData.fieldDto.timeType }});
            break;
          case 8:
            let cityCode = '';
            switch(this.fieldData.fieldDto.cityType) {
              case 1:
                cityCode = this.customForm.addressForm.province;
                break;
              case 2:
                cityCode = this.customForm.addressForm.city;
                break;
              case 3:
              case 4:
                cityCode = this.customForm.addressForm.city;
                break;
            }
            this.$emit('update', { data: this.fieldData, value: {
              cityCode: cityCode,
              address: this.customForm.addressForm.address,
              province: this.customForm.addressForm.province,
              city: this.customForm.addressForm.city,
              district: this.customForm.addressForm.district,
              cityType: this.fieldData.fieldDto.cityType
            }});
            break;
          case 9:
            this.$emit('update', { data: this.fieldData, value: { startTime: this.formatTime(this.customForm.startTimes), endTime: this.formatTime(this.customForm.endTimes), checkedNow: this.customForm.checkedNow, timeType: this.fieldData.fieldDto.timeType} });
            break;
          default:
            break;
        }
      },
      formatTime(data) {
        const formatObj = {
          1: 'YYYY',
          2: 'YYYYMM',
          3: 'YYYYMMDD'
        };
        if(data) {
          return moment(data).format(formatObj[this.fieldData.fieldDto.timeType])        
        } else {
          return '';
        }
      },
      unFormatTime(data) {
        const formatObj = {
          1: 'YYYY',
          2: 'YYYY.MM',
          3: 'YYYYMMDD'
        };
        if(data) {
          return moment(data).format(formatObj[this.fieldData.fieldDto.timeType])        
        } else {
          return '';
        }
      },
      actionName(type) {
        if([1, 2].includes(type)) {
          return '输入';
        } else if(type === 6) {
          return '上传';
        } else {
          return '选择';
        }
      },
      validAddress(rule, value, callback) {
        if((this.isCityType(3) || this.isCityType(4)) && (!value.province || !value.city || !value.district)) {
          callback(new Error('请选择地址'));
        } else if(this.isCityType(4) && !value.address) {
          callback(new Error('请填写详细地址'));
        } else if(this.isCityType(2) && (!value.province || !value.city)) {
          callback(new Error('请选择地址'));
        } else if(this.isCityType(1) && !value.province) {
          callback(new Error('请选择地址'));
        }
        callback();
      },
      validNumber(rule, value, callback) {
        if(value && !value.match(/^[0-9.]+$/g)) {
          
          callback(new Error('请输入数字'));
        }
        callback();
      },
      validWord(rule, value, callback) {
        if(value && !value.match(/^[a-zA-Z]+$/g)) {
          callback(new Error('请输入字母'));
        }
        callback();
      },
      clearData() {
        this.customForm = {
          text: '',
          content: '',
          singleSelect: undefined,
          flag: '',
          multipleSelect: [],
          dates: [],
          startTimes: '',
          endTimes: '',
          checkedNow: false,
          selectTimes: '',
          otherContent: '',
          addressForm: {
            province: undefined,
            city: undefined,
            district: undefined,
            address: '',
          },
          fileId: ''
        };
      }
    },
    watch: {
      itemData: {
        handler(newVal, oldVal) {
          if(newVal && newVal.value) {
            if([4, 5, 6, 7, 8, 9].includes(newVal.fieldType)) {
              let value = JSON.parse(newVal.value);
              if(newVal.fieldType === 4 && value.itemId) {
                this.customForm.singleSelect = value.itemId;
                if(value.other) {
                  this.customForm.otherContent = value.other;
                }
              }
              if(newVal.fieldType === 5 && value.length) {
                let ids = [];
                value.forEach(item => {
                  ids.push(item.itemId);
                  if(item.other) {
                    this.customForm.otherContent = item.other;
                  }
                });
                this.customForm.multipleSelect = ids;
              }
              if(newVal.fieldType === 6) {
                this.fileList = value;
                if(value && value[0 && value[0].id]) {
                  this.customForm.fileId = value[0].id;
                }
              }
              if(newVal.fieldType === 7 && value.timeValue) {
                this.customForm.selectTimes = value.timeValue;
              }
              if(newVal.fieldType === 8) {
                Object.assign(this.customForm.addressForm, value);
                if(this.customForm.addressForm.province) {
                  this.getCity();
                }
                if(this.customForm.addressForm.city) {
                  this.getDistrict();
                }
              }
              if(newVal.fieldType === 9) {
                if(value.startTime) {
                  this.customForm.startTimes = this.unFormatTime(value.startTime);
                }
                if(value.endTime) {
                  this.customForm.endTimes = this.unFormatTime(value.endTime);
                }
                this.customForm.dates = [this.unFormatTime(value.startTime), this.unFormatTime(value.endTime)];
                this.customForm.checkedNow = value.checkedNow ? true : false;
              }
            } else {
              if(newVal.fieldType === 1) {
                this.customForm.content = newVal.value;
              } else if(newVal.fieldType === 2) {
                this.customForm.text = newVal.value;
              } else if(newVal.fieldType === 3) {
                this.customForm.flag = +newVal.value;
              }
            }
          } else {
            this.clearData();
            if(newVal && newVal.fieldType === 3 && newVal.value === 0) {
              this.customForm.flag = 0;
            }
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>