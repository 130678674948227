import subCustomField from './submodule/customField'

export default {
  components: {
    subCustomField
  },
  props: {
    personId: [String, Number],
    filedsConfig: {
      type: Array,
      default() {
        return []
      }
    },
    moduleId: Number
  },
  methods: {
    getRules(fields, rules = []) {
      if(fields.isRequired) {
        return [{ required: true, message: `请${this.actionName(fields.fieldDto.type)}${fields.fieldDto.name}`, trigger: 'blur' }].concat(rules);
      }
      return rules;
    },
    actionName(type) {
      if([1, 2].includes(type)) {
        return '输入';
      } else if(type === 6) {
        return '上传';
      } else {
        return '选择';
      }
    },
    getLabel(fields) {
      return fields.fieldDto.name + '：';
    },
    isFiledCode(code, data) {
      // if(data.fieldDto && data.fieldDto.fieldCode === code) return true;
      if(data.isCheck && data.fieldDto && data.fieldDto.fieldCode === code) return true;
      return false;
    },
    getItemData(obj, id) {
      if(obj && obj.customFields && obj.customFields[id]) {
        return this.$utils.deepClone(obj.customFields[id]);
      }
      return {};
    },
    updateField(index, data) {
      const dto = data.data.fieldDto;
      if(!this.resForm.expArr[index].customFields) {
        this.resForm.expArr[index].customFields = {};
      }
      this.resForm.expArr[index].customFields[dto.id] = {
        fieldId: dto.id,
        fieldType: dto.type,
        value: dto.type > 3 ? JSON.stringify(data.value) : data.value
      }
    },
    validateCustom() {
      const validateArr = Object.keys(this.$refs).filter(key => key.includes('custom')).map(item => this.validateSubItem(item));
      if(validateArr.length) {
        return Promise.all(validateArr).then(res => {
          if(res.includes(false)) {
            return false;
          }
          return true;
        });
      } else {
        return Promise.resolve();
      }
    },
    validateSubItem(formName) {
      return new Promise(resolve => {
        if(this.$refs[formName] && this.$refs[formName][0]) {
          this.$refs[formName][0].$refs.customForm.validate(valid => {
            resolve(valid);
          });
        } else {
          resolve();
        }
      });
    }
  }
}