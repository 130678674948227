<template>
  <div>
    <template v-for="(item, key) in fields">
      <div v-if="item.isCheck && item.fieldDto.resFlag && getValByKey(item.fieldDto.fieldCode, item.fieldId) && showIds.includes(item.fieldId)" :key="key" :class="{ 'exp-title': showIds.indexOf(item.fieldId) === 0, 'job el-sl': showIds.indexOf(item.fieldId) === 1, 'date': showIds.indexOf(item.fieldId) === 2 }">
        <template v-if="key === 0">
          <span class="el-sl">{{ getValByKey(item.fieldDto.fieldCode, item.fieldId) }}</span><i class="h5pp h5pp-icon-arrows-right"></i>
        </template>
        <template v-else>{{ getValByKey(item.fieldDto.fieldCode, item.fieldId) }}</template>
      </div>
      <div v-else-if="item.isCheck && !item.fieldDto.resFlag && getVal(item.fieldId) && showIds.includes(item.fieldId)" :key="key" :class="{ 'exp-title': showIds.indexOf(item.fieldId) === 0, 'job el-sl': showIds.indexOf(item.fieldId) === 1, 'date': showIds.indexOf(item.fieldId) === 2 }">
        <template v-if="key === 0">
          <span class="el-sl">{{ getVal(item.fieldId) }}</span><i class="h5pp h5pp-icon-arrows-right"></i>
        </template>
        <template v-else>{{ getVal(item.fieldId) }}</template>
      </div>
    </template>
  </div>
</template>

<script>
  import { FULLCITY, INDUSTRY } from '@/utils/filters'
  import { Scale, Degree, Proficiency, Language } from '@/enums/EnumResume'
  import { NationalUnified } from '@/enums/EnumBase'

  export default {
    props: {
      fields: Array,
      dataObj: Object,
      moduleCode: {
        type: [Number, String],
        default: 3
      }
    },
    data() {
      return {
        codeMap: {
          3: {
            companyName: 'company',
            positionName: 'jobName',
            time: 'dates',
            companySize: 'scale',
            demand: 'reportPerson',
            underNumber: 'underPerson',
            performance: 'workAchievement',
            leaveReason: 'dimissionReason'
          },
          4: {
            time: 'dates',
            graduateSchool: 'graduationSchool',
            type: 'unifiedExamFlag',
            professional: 'major'
          },
          5: {
            time: 'dates',
            name: 'projectName',
            description: 'projectDesc',
            responsibilities: 'projectDuty'
          },
          6: {
            proficiency: 'languageProficiency'
          },
          7: {},
          8: {
            time: 'dates',
          }
        },
        showIds: [],
        max: 2,
      }
    },
    computed: {
      getValByKey() {
        return (code, id) => {
          const mapKey = this.codeMap[this.moduleCode][code] || code;
          if(mapKey && this.dataObj[mapKey] && !['productId'].includes(mapKey)) {
            if(!this.showIds.includes(id) && this.showIds.length <= this.max) {
              this.showIds.push(id);
            }
            if(mapKey === 'companyIndustry') {
              return INDUSTRY(this.dataObj[mapKey]);
            }
            if(mapKey === 'scale') {
              return Scale[this.dataObj[mapKey]];
            }
            if(mapKey === 'internshipFlag') {
              return this.dataObj[mapKey] ? '开' : '关';
            }
            if(mapKey === 'degree') {
              return Degree[this.dataObj[mapKey]];
            }
            if(mapKey === 'unifiedExamFlag') {
              return NationalUnified[this.dataObj[mapKey]];
            }
            if(mapKey === 'language') {
              return Language[this.dataObj[mapKey]];
            }
            if(mapKey === 'languageProficiency') {
              return Proficiency[this.dataObj[mapKey]];
            }
            return this.dataObj[mapKey];
          }
          return '';
        }
      },
      getVal() {
        return (id) => {
          if(this.dataObj.customFields && this.dataObj.customFields[id] && this.dataObj.customFields[id].value && this.dataObj.customFields[id].fieldType !== 6) {
            if(this.getFieldData(this.dataObj.customFields[id]) && !this.showIds.includes(id) && this.showIds.length <= this.max) {
              this.showIds.push(id);
            }
            return this.getFieldData(this.dataObj.customFields[id]);
          }
          return '';
        }
      }
    },
    methods: {
      getFieldData(newVal) {
        let resultVal = '';
        if(newVal && newVal.value) {
          if([4, 5, 6, 7, 8, 9].includes(newVal.fieldType)) {
            let value = JSON.parse(newVal.value);
            if(newVal.fieldType === 4 && value.itemId) {
              resultVal = value.name;
              if(value.other) {
                resultVal = value.other;
              }
            }
            if(newVal.fieldType === 5 && value.length) {
              let names= [];
              value.forEach(item => {
                let name = item.name;
                if(item.other) {
                  name = item.other;
                }
                names.push(name);
              });
              resultVal = names.join(',');
            }
            if(newVal.fieldType === 7 && value.timeValue) {
              resultVal = value.timeValue;
            }
            if(newVal.fieldType === 8) {
              resultVal = FULLCITY(value.cityCode) + value.address;
            }
            if(newVal.fieldType === 9) {
              resultVal = [value.startTime, value.endTime].join('~');
            }
          } else {
            if(newVal.fieldType === 1) {
              resultVal = newVal.value;
            } else if(newVal.fieldType === 2) {
              resultVal = newVal.value;
            } else if(newVal.fieldType === 3) {
              resultVal = +newVal.value > 0 ? '是' : '否';
            }
          }
        }
        return resultVal;
      }
    },
    watch: {
      dataObj: {
        handler(val) {
          this.showIds = [];
          return val;
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>