<template>
  <div class="item-resume-form-box">
    <el-form ref="resForm" :model="resForm"  label-width="120px" class="item-resume-form resume-self-form" @submit.native.prevent>
      <div class="item-resume-form-title">
        <h4>自我描述</h4>
      </div>
      <template v-for="(item, key) in filedsConfig">
        <el-form-item v-if="isFiledCode('selfIntroduce', item)" :key="key" :label="getLabel(item)" :rules="getRules(item)" prop="selfIntroduce" class="item-resume-textarea">
          <el-input type="textarea" v-model.trim="resForm.selfIntroduce" :maxLength="1000" placeholder="请填写自我描述" />
          <div class="textarea-word-limit">{{ (resForm.selfIntroduce || '').length }}/1000</div>
        </el-form-item>
        <!-- 其他 -->
        <sub-custom-field v-else-if="item.isCheck" :ref="`custom-${item.fieldId}`" :key="key" :fieldData="item" :itemData="resForm.customFields[item.fieldId] || ''" @update="updateField" />
      </template>
    </el-form>
  </div>
</template>

<script>
  import { Form, FormItem, Input, Button } from 'element-ui'
  import mixin from '../../mixin'

  export default {
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Button.name]: Button
    },
    props: {
      subResData: {
        type: Object,
        default() {
          return {}
        }
      },
    },
    mixins: [mixin],
    data() {
      return {
        resForm: {
          selfIntroduce: '',
          customFields: {}
        }
      }
    },
    methods: {
      initData(data) {
        this.resForm.selfIntroduce = data.selfIntroduce;
        this.resForm.customFields = this.$utils.deepClone(data.fieldMap) || {};
      },
      updateField(data) {
        const dto = data.data.fieldDto;
        this.resForm.customFields[dto.id] = {
          fieldId: dto.id,
          fieldType: dto.type,
          value: dto.type > 3 ? JSON.stringify(data.value) : data.value
        }
      }
    },
    watch: {
      subResData: {
        handler(newVal, oldVal) {
          if(newVal && Object.keys(newVal).length) {
            this.initData(newVal);
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>