<template>
  <van-action-sheet class="add-pc-resume" title="去电脑上填写" v-model="dialogData.isShow" close-on-click-action>
    <van-form ref="resForm">
      <van-field
        v-model="email"
        name="email"
        :rules="[{ required: true, message: '请输入邮箱'},{ validator, message: '请输入正确的邮箱', trigger: 'blur'}]" placeholder="请输入邮箱" />
    </van-form>
    <a href="javascript:;" class="email-btn" @click="onSendEmail">发送链接至邮箱</a>
    <a href="javascript:;" class="copy-btn" @click="onCopyUrl">复制链接在电脑上打开</a>
  </van-action-sheet>
</template>

<script>
  import './index.scss'
  import { ActionSheet, Field, Form, Toast } from 'vant'
  import { local } from '@/utils'

  export default {
    components: {
      [ActionSheet.name]: ActionSheet,
      [Form.name]: Form,
      [Field.name]: Field
    },
    props: {
      dialogData: Object
    },
    data() {
      return {
        email: '',
        url: ''
      }
    },
    created() {
      this.url = `${window.location.origin}/job/detail/${this.$route.query.jobId}?websiteId=${this.$route.query.websiteId}&personId=${this.$route.query.personId || (JSON.parse(local.get('user')|| '{}')).personId || local.get('oldPersonId') || ''}`;
    },
    methods: {
      validator(val, rule, callback) {
        if(!val) {
          callback('请输入邮箱')
        }
        return !val || /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(val);
      },
      onSendEmail() {
        this.$refs.resForm.validate().then((valid) => {
          this.$http.get('/api/website/send/delivery/email.json', {params: {
            email: this.email,
            websiteId: this.$route.query.websiteId,
            jobId: this.dialogData.jobId,
            internalPersonId: this.dialogData.personId
          }}).then(data => {
            if(data) {
              Toast.success('已发送成功');
            }
          });
        });
      },
      onCopyUrl() {
        this.$copyText(this.url).then(() => {
            Toast.success({
            message: '复制成功'
          });
        }, () => {
          Toast.success({
            message: '浏览器版本过低，请升级浏览器'
          });
        });
      }
    }
  }
</script>
